define("label_provider_app/initializers/component-initializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    Ember.Component.reopen({
      isAlive: function isAlive() {
        return !(this.get('isDestroying') || this.get('isDestroyed'));
      }
    });
  }
  var _default = {
    name: 'component-initializer',
    initialize: initialize
  };
  _exports.default = _default;
});