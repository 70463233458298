define("label_provider_app/templates/components/lemon-bank-account-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E922uzNN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"panel-body-inner\",null,null,{\"statements\":[[4,\"unless\",[[23,[\"model\",\"lemonRef\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"message-callout\",null,[[\"messageType\",\"messages\"],[\"info\",[27,\"t\",[\"lemonBankAccount.waitingForLemonRegistration\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"label-and-thing\",null,[[\"label\"],[[27,\"t\",[\"lemonBankAccount.iban\"],null]]],{\"statements\":[[4,\"thing-of-label\",null,null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"js-lemon-bank-account-iban\"],[9],[0,\"\\n        \"],[1,[23,[\"model\",\"iban\"]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[23,[\"model\",\"lemonRef\"]]],null,{\"statements\":[[4,\"label-and-thing\",null,[[\"label\"],[[27,\"t\",[\"lemonBankAccount.lemonRef\"],null]]],{\"statements\":[[4,\"thing-of-label\",null,null,{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"js-lemon-bank-account-lemon-ref\"],[9],[0,\"\\n          \"],[1,[23,[\"model\",\"lemonRef\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/lemon-bank-account-editor.hbs"
    }
  });
  _exports.default = _default;
});