define("label_provider_app/services/format-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // const defaultLanguage = 'fr';
  // See http://momentjs.com/ for docs on the possible formats
  // const defaultMomentDisplayFormat = 'llll';
  var _default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    format: function format(rawDate, desiredFormat) {
      if (!rawDate) {
        return '';
      }
      var language = this.get('language');
      var usedFormat = desiredFormat;
      // if (!usedFormat) {
      //   usedFormat = defaultMomentDisplayFormat;
      // }
      return (0, _moment.default)(rawDate).locale(language).tz(_moment.default.tz.guess()).format(usedFormat);
    },
    language: function () {
      var locale = this.get('i18n.locale');
      // if (!locale) {
      //   return defaultLanguage;
      // }
      return locale.slice(0, 2);
    }.property('i18n.locale')
  });
  _exports.default = _default;
});