define("label_provider_app/models/provided-client", ["exports", "ember-data", "label_provider_app/window-location", "label_provider_app/config/environment"], function (_exports, _emberData, _windowLocation, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var detailsFields = [{
    name: 'id',
    type: 'string',
    isEditable: false
  }, {
    name: 'sourceRef',
    type: 'string',
    isEditable: false
  }, {
    name: 'companyName',
    type: 'string',
    isEditable: false
  }, {
    name: 'state',
    type: 'string',
    isEditable: false
  }, {
    name: 'email',
    type: 'string',
    isEditable: false
  }, {
    name: 'country',
    type: 'string',
    isEditable: false
  }, {
    name: 'zip',
    type: 'string',
    isEditable: false
  }, {
    name: 'city',
    type: 'string',
    isEditable: false
  }, {
    name: 'street',
    type: 'string',
    isEditable: false
  }, {
    name: 'phoneNumber',
    type: 'string',
    isEditable: false
  }, {
    name: 'mobilePhone',
    type: 'string',
    isEditable: false
  }, {
    name: 'firstname',
    type: 'string',
    isEditable: false
  }, {
    name: 'lastname',
    type: 'string',
    isEditable: false
  }];
  var _default = _emberData.default.Model.extend({
    detailsFields: detailsFields,
    city: _emberData.default.attr('string'),
    companyName: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    frontEmail: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    mobilePhone: _emberData.default.attr('string'),
    phoneNumber: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    street: _emberData.default.attr('string'),
    sourceRef: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    impersonate: function impersonate() {
      var clientId = this.get('id');
      var url = [_environment.default.api.oms, 'provided_clients', clientId, 'impersonate'].join('/');
      Ember.$.ajax({
        url: url,
        dataType: 'json',
        method: 'POST'
      }).done(function (payload) {
        if (payload.impersonatedClientId === clientId) {
          _windowLocation.default.replace('/');
        }
      });
    }
  });
  _exports.default = _default;
});