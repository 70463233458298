define("label_provider_app/tests/factories/report-configs", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('reportConfig', {
    default: {
      type: 'ReportConfig::ProvidedAddressLabel',
      name: 'provided labels',
      allowTrigger: false
    },
    traits: {
      csv: {
        csv: true
      },
      dateBordered: {
        dateBordered: true
      },
      labelSale: {
        type: 'ReportConfig::LabelSale',
        name: 'label sales',
        allowTrigger: true,
        datable: false,
        dateBordered: true,
        csv: true
      }
    }
  });
});