define("label_provider_app/components/fcv-uploader", ["exports", "ember-uploader"], function (_exports, _emberUploader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberUploader.default.FileField.extend({
    executeAction: function executeAction(uploadedFileData) {
      this.sendAction('action', uploadedFileData);
    },
    filesDidChange: function filesDidChange(files) {
      var _this = this;
      var uploader = _emberUploader.default.Uploader.create({
        url: '/uploaded_files',
        paramNamespace: 'uploaded_file'
      });
      if (!Ember.isEmpty(files)) {
        uploader.upload(files[0]).then(function (data) {
          _this.executeAction(data['uploaded_file']);
        });
      }
    }
  });
  _exports.default = _default;
});