define("label_provider_app/templates/components/label-offer-option-dearchiver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LL7A3/Kt",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"button-icon\",null,[[\"jsClass\",\"action\",\"btnType\"],[[23,[\"jsClass\"]],[27,\"action\",[[22,0,[]],\"dearchiveLabelOfferOption\"],null],\"activate\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/label-offer-option-dearchiver.hbs"
    }
  });
  _exports.default = _default;
});