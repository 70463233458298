define("label_provider_app/templates/components/confirm-dangerous-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fkIDosG+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[27,\"button-icon\",null,[[\"jsClass\",\"action\",\"title\",\"btnType\",\"faIcon\"],[[23,[\"jsClass\"]],[27,\"action\",[[22,0,[]],\"open\"],null],[23,[\"btnTitle\"]],\"delete\",[23,[\"destroyIcon\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"isOpen\"]]],null,{\"statements\":[[4,\"dangerous-modal-popin\",null,[[\"dangerousAction\",\"cancelDangerousAction\",\"destroyIcon\",\"consequencesKey\"],[[27,\"action\",[[22,0,[]],\"performDangerousAction\"],null],[27,\"action\",[[22,0,[]],\"cancelDangerousAction\"],null],[23,[\"destroyIcon\"]],[23,[\"consequencesKey\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/confirm-dangerous-button.hbs"
    }
  });
  _exports.default = _default;
});