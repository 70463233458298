define("label_provider_app/templates/components/date-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "J9ZbLyTV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"inputLike\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\"],[11,\"name\",\"js-name-date-display-input\"],[12,\"class\",[28,[\"form-control \",[21,\"jsClass\"]]]],[12,\"value\",[21,\"formattedDate\"]],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[28,[[21,\"jsClass\"]]]],[9],[1,[21,\"formattedDate\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/date-display.hbs"
    }
  });
  _exports.default = _default;
});