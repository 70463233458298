define("label_provider_app/constants", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function toOptions(list) {
    return _lodash.default.map(list, function (e) {
      return {
        label: e,
        value: e
      };
    });
  }

  // MKP Enabling
  // Add carrier here
  var _default = {
    carriers: ['AmazonShipping', 'B2cEurope', 'Boxstop', 'Chronopost', 'ColisPrive', 'ColisPriveC2c', 'Correos', 'CourrierSuivi', 'DbSchenker', 'Delivengo', 'DelivengoConso', 'Dhl', 'DpdByApi', 'EnvoiDuNet', 'Fedex', 'Frequenceo', 'Geodis', 'GeodisByApi', 'Gls', 'GlsByApi', 'GlsShipIt', 'Hermes', 'Heppner', 'IntraLogistique', 'Kuehne', 'Laposte', 'LettreSimple', 'MondialRelay', 'MondialRelayDualCarrier', 'RelaisColis', 'SboLabel', 'Sda', 'TempoOne', 'Tnt', 'Ups'],
    frenchDomTomIsoCodes: ['GP', 'GF', 'MQ', 'RE', 'YT', 'MF', 'BL', 'PM', 'PF', 'NC', 'WF', 'TF'],
    // Greece is GR
    // United Kindgom is GB
    euCountriesCodes: ['BE', 'BG', 'CZ', 'DK', 'DE', 'EE', 'GB', 'IE', 'ES', 'FR', 'GR', 'HR', 'IT', 'CY', 'LV', 'LT', 'LU', 'HU', 'MT', 'NL', 'AT', 'PL', 'PT', 'RO', 'SI', 'SK', 'FI', 'SE'],
    advancedInsurance: [{
      name: 'wrap',
      options: toOptions(['Boîte', 'Caisse', 'Bac', 'Emballage isotherme', 'Etui', 'Malle', 'Sac', 'Tube'])
    }, {
      name: 'closing',
      options: toOptions(['Fermeture autocollante', 'Ruban adhésif', 'Agrafes', 'Clous', 'Collage', 'Ruban de cerclage', 'Sangle ou feuillard', 'Agraphes et cerclage', 'Clous et cerclage'])
    }, {
      name: 'fixing',
      options: toOptions(['Sans système de stabilisation', 'Film transparent', 'Film opaque', 'Film opaque et sangles', 'Film transparent et sangles', 'Sangle ou feuillard uniquement'])
    }, {
      name: 'material',
      options: toOptions(['Carton', 'Bois', 'Carton blindé', 'Film opaque', 'Film transparent', 'Métal', 'Papier', 'Papier armé', 'Plastique et carton', 'Plastique', 'Plastique opaque', 'Plastique transparent', 'Polystyrène'])
    }, {
      name: 'protection',
      options: toOptions(['Sachet bulles', 'Sans protection particulière', 'Calage papier', 'Bulles plastiques', 'Carton antichoc', 'Coussin air', 'Coussin mousse', 'Manchon carton (bouteille)', 'Manchon mousse (bouteille)', 'Matelassage', 'Plaque mousse', 'Polystyrène', 'Coussin de calage'])
    }],
    openServices: [{
      value: 'Amazon',
      label: 'Amazon'
    }, {
      value: 'AtlasForMen',
      label: 'AtlasForMen'
    }, {
      value: 'AuchanMkp',
      label: 'Auchan'
    }, {
      value: 'BleuBonheur',
      label: 'Bleu Bonheur'
    }, {
      value: 'Boulanger',
      label: 'Boulanger'
    }, {
      value: 'Cdiscount',
      label: 'CDiscount'
    }, {
      value: 'Conforama',
      label: 'Conforama'
    }, {
      value: 'Creavea',
      label: 'Creavea'
    }, {
      value: 'Darty',
      label: 'Darty'
    }, {
      value: 'Ebay',
      label: 'eBay'
    }, {
      value: 'Fnac',
      label: 'FNAC'
    }, {
      value: 'GosportMirakl',
      label: 'Go Sport'
    }, {
      value: 'LaRedoute',
      label: 'La Redoute'
    }, {
      value: 'Manomano',
      label: 'Manomano'
    }, {
      value: 'Magento::V1',
      label: 'Magento Version 1.9.*'
    }, {
      value: 'Magento::V2',
      label: 'Magento Version 2.*'
    }, {
      value: 'Outiz',
      label: 'Outiz'
    }, {
      value: 'Prestashop',
      label: 'Prestashop'
    }, {
      value: 'Priceminister',
      label: 'Priceminister'
    }, {
      value: 'Privalia',
      label: 'Privalia'
    }, {
      value: 'Shopify',
      label: 'Shopify'
    }, {
      value: 'Spartoo',
      label: 'Spartoo'
    }, {
      value: 'Vidaxl',
      label: 'Vidaxl'
    }, {
      value: 'Wizishop',
      label: 'Wizishop'
    }, {
      value: 'Woocommerce',
      label: 'Woocommerce'
    }],
    search: {
      modifiers: {
        string: ['eq', 'contains', 'starts_with', 'ends_with', 'blank', 'neq'],
        number: ['eq', 'lt', 'lteq', 'gt', 'gteq', 'nil', 'neq'],
        boolean: ['true'],
        date: ['eq', 'lt', 'lteq', 'gt', 'gteq', 'nil', 'neq'],
        select: ['eq', 'neq', 'nil']
      },
      defaultModifier: {
        string: 'contains',
        number: 'eq',
        boolean: 'true',
        date: 'gteq',
        select: 'eq'
      },
      nameForMod: function nameForMod(i18n, mod) {
        return i18n.t('search.modifiers.' + mod);
      }
    },
    waitTime: 1000,
    propertyEditor: {
      statusTimeout: 3000
    },
    orderCreator: {
      modalTimeout: 500
    },
    autoPrint: {
      dismissFramesAfterTimeout: true
    }
  };
  _exports.default = _default;
});