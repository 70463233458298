define("label_provider_app/models/wallet", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var centsInAUnit = 100;
  var _default = _emberData.default.Model.extend({
    balanceCents: _emberData.default.attr('number'),
    balanceCurrency: _emberData.default.attr('string'),
    potentialBalanceCents: _emberData.default.attr('number'),
    potentialBalanceCurrency: _emberData.default.attr('string'),
    lemonPkey: _emberData.default.attr('string'),
    lemonStatus: _emberData.default.attr('string'),
    kycReady: _emberData.default.attr('boolean'),
    lemonBankAccount: _emberData.default.belongsTo('lemonBankAccount'),
    lemonBankAccountId: _emberData.default.attr('number'),
    balanceInUnits: function () {
      if (this.get('balanceCents') === 0) {
        return 0;
      }
      if (!this.get('balanceCents')) {
        return;
      }
      return this.get('balanceCents') / centsInAUnit;
    }.property('balanceCents'),
    displayableBalanceUnits: function () {
      if (this.get('balanceInUnits') === 0) {
        return this.formatUnits(0);
      }
      if (!this.get('balanceInUnits')) {
        return '';
      }
      return this.formatUnits(this.get('balanceInUnits'));
    }.property('balanceInUnits'),
    potentialBalanceInUnits: function () {
      if (this.get('potentialBalanceCents') === 0) {
        return 0;
      }
      if (!this.get('potentialBalanceCents')) {
        return;
      }
      return this.get('potentialBalanceCents') / centsInAUnit;
    }.property('potentialBalanceCents'),
    displayableVirtualBalanceUnits: function () {
      if (this.get('potentialBalanceInUnits') === 0) {
        return this.formatUnits(0);
      }
      if (!this.get('potentialBalanceInUnits')) {
        return '';
      }
      return this.formatUnits(this.get('potentialBalanceInUnits'));
    }.property('potentialBalanceInUnits'),
    formatUnits: function formatUnits(number) {
      return number.toFixed(2);
    }
  });
  _exports.default = _default;
});