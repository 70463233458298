define("label_provider_app/templates/components/my-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0D7/YBRk",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"select\"],[12,\"class\",[28,[\"form-control js-my-select \",[21,\"jsClass\"]]]],[12,\"name\",[28,[\"js-selectName-\",[21,\"jsSelectName\"]]]],[3,\"action\",[[22,0,[]],\"change\"],[[\"on\"],[\"change\"]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"content\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[28,[[22,1,[\"value\"]]]]],[12,\"selected\",[27,\"is-equal\",[[27,\"to-string\",[[22,1,[\"value\"]]],null],[23,[\"selectedValue\"]]],null]],[12,\"class\",[28,[\"js-option-\",[22,1,[\"value\"]]]]],[9],[0,\"\\n      \"],[1,[22,1,[\"label\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/my-select.hbs"
    }
  });
  _exports.default = _default;
});