define("label_provider_app/transforms/moment", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (!serialized) {
        return null;
      }
      return (0, _moment.default)(serialized, 'YYYY-MM-DDTHH:mm:ss.SSSZ').utc();
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return null;
      }
      return (0, _moment.default)(deserialized).utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    }
  });
  _exports.default = _default;
});