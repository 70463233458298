define("label_provider_app/components/search-display-for-field", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    removeAction: null,
    searchObject: null,
    field: null,
    table: null,
    // translationFct: null,

    appliedSearches: function () {
      var _this = this;
      return _lodash.default.reduce(this.takenOptions(), function (previousValue, mod) {
        return previousValue.concat(_this.valueForMod(mod).map(function (value) {
          var displayValue = value;
          var isBoolean = typeof value === 'boolean';
          if (isBoolean) {
            displayValue = _this.get('i18n').t('search.values.' + value);
          }
          // const translationFct = this.get('translationFct');
          // if (translationFct && !isBoolean) {
          //   debugger;
          //   displayValue = translationFct(
          //     this.get('table'),
          //     this.get('field'),
          //     value
          //   );
          // }
          var displayMod = _this.get('i18n').t('search.modifiers.' + mod);
          return {
            mod: mod,
            displayMod: displayMod,
            value: value,
            displayValue: displayValue
          };
        }));
      }, []);
    }.property('searchObject.values', 'searchObject.joins', 'table', 'field', 'translationFct'),
    takenOptions: function takenOptions() {
      var obj = this.get('searchObject');
      // let table = this.get('table');
      var field = this.get('field');
      // if (table) {
      //   return obj.joinTakenOptions(table, field);
      // }
      return obj.takenOptions(field);
    },
    valueForMod: function valueForMod(mod) {
      var obj = this.get('searchObject');
      // let table = this.get('table');
      var field = this.get('field');
      // if (table) {
      //   return obj.getJoinSearchValues(table, field, mod);
      // }
      return obj.getSearchValues(field, mod);
    },
    actions: {
      remove: function remove(mod, value) {
        if (this.get('removeAction')) {
          this.get('removeAction')(mod, value);
        }
      }
    }
  });
  _exports.default = _default;
});