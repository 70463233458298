define("label_provider_app/controllers/label-offers", ["exports", "label_provider_app/controllers/pagination-base", "label_provider_app/mixins/model-fields", "lodash"], function (_exports, _paginationBase, _modelFields, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var labelOfferTypes = ['Basic', 'CredentialResale', 'FirstMile'];
  var _default = _paginationBase.default.extend(_modelFields.default, {
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    providedLabelOfferOptions: [],
    credentialSelected: null,
    labelOfferTypesOptions: function labelOfferTypesOptions() {
      var _this = this;
      return labelOfferTypes.map(function (type) {
        return {
          label: _this.get('i18n').t("labelOffer.types.".concat(type.camelize())),
          value: "LabelOffer::".concat(type)
        };
      });
    },
    tableFieldsReady: function () {
      return !_lodash.default.isEmpty(this.get('tableFields'));
    }.property('tableFields.[]'),
    tableFields: function () {
      var result = [];
      if (!this.get('credentialOptions')) {
        return result;
      }
      result = result.concat([{
        name: 'details',
        notSearchable: true,
        notSortable: true,
        component: 'link-to-details',
        sticky: true,
        narrow: true
      }, {
        name: 'id',
        type: 'number',
        sticky: true
      }, {
        name: 'type',
        type: 'select',
        options: this.labelOfferTypesOptions(),
        isEditable: true,
        necessary: true
      }, {
        name: 'name',
        type: 'string',
        isEditable: true,
        sticky: true
      }, {
        name: 'labelCredential',
        type: 'select',
        options: this.get('credentialOptions'),
        optionSelected: this.get('credentialSelected'),
        isEditable: true,
        necessary: true
      }, {
        name: 'labelCredentialName',
        type: 'number',
        sticky: true,
        notSearchable: true
      }, {
        name: 'senderCountry',
        type: 'string',
        isEditable: true,
        editCondition: function editCondition(model) {
          return !model.get('isProvided');
        },
        sticky: true
      }, {
        name: 'providedLabelOfferId',
        type: 'select',
        options: this.get('providedLabelOfferOptions'),
        isEditable: true,
        editCondition: function editCondition(model) {
          return model.get('isProvided');
        },
        necessary: true
      }, {
        name: 'providedLabelOfferName',
        type: 'string',
        notSearchable: true,
        notSortable: true,
        sticky: true
      }, {
        name: 'createdAt',
        type: 'date',
        isEditable: false,
        sticky: true
      }]);
      return result;
    }.property('credentialOptions', 'credentialOptions.[]', 'providedLabelOfferOptions', 'credentialSelected'),
    credentialOptions: function () {
      var usableCredentials = this.get('usableCredentials');
      if (!usableCredentials) {
        return;
      }
      return usableCredentials.map(function (cred) {
        return {
          value: cred.get('id'),
          label: cred.get('name')
        };
      });
    }.property('usableCredentials', 'usableCredentials.[]', 'usableCredentials.@each.id', 'usableCredentials.@each.name'),
    usableCredentials: function () {
      var _this2 = this;
      var userCreds = null;
      this.get('store').findAll('labelCredential').then(function (creds) {
        _this2.set('usableCredentials', creds);
        userCreds = creds;
      });
      return userCreds;
    }.property('store'),
    initialData: function () {
      return {
        type: this.labelOfferTypesOptions[0],
        labelCredential: this.get('credentialOptions')[0] && this.get('credentialOptions')[0].value
      };
    }.property('model'),
    actions: {
      updateAvailableProvidedOffers: function updateAvailableProvidedOffers(field, value, model) {
        if (field === 'labelCredential' && value && model && value !== model.labelCredentialId) {
          var labelCred = this.store.peekRecord('labelCredential', value);
          var newOptions = [];
          if (labelCred.availableProvidedLabelOffers) {
            newOptions = labelCred.availableProvidedLabelOffers.map(function (offer) {
              return {
                value: offer.id,
                label: offer.name
              };
            });
          }
          model.set('labelCredential', labelCred);
          model.set('labelCredentialId', value);
          this.set('providedLabelOfferOptions', newOptions);
          this.set('credentialSelected', value);
          model.set('senderCountry', labelCred.providedLabelCredentialId ? undefined : model.senderCountry);
          model.set('providedLabelOfferId', labelCred.providedLabelCredentialId && newOptions.length > 1 ? newOptions[0].value : null);
        }
      }
    }
  });
  _exports.default = _default;
});