define("label_provider_app/templates/components/label-offer-options-block", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "W5M7guOr",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"panel-bordered\",null,[[\"jsClass\"],[\"js-label-offer-options-block\"]],{\"statements\":[[4,\"panel-heading\",null,[[\"title\"],[[27,\"t\",[\"labelOffer.labelOfferOptions\"],null]]],{\"statements\":[[0,\"    \"],[1,[27,\"label-offer-option-creator\",null,[[\"labelOffer\"],[[23,[\"labelOffer\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"panel-body-inner\",null,[[\"jsClass\"],[\"cuke-label-offer-options\"]],{\"statements\":[[4,\"panel-row\",null,null,{\"statements\":[[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"label-offer-option-display\",null,[[\"model\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/label-offer-options-block.hbs"
    }
  });
  _exports.default = _default;
});