define("label_provider_app/components/label-offer-option-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var mainJsClassBase = 'js-label-offer-option-display';
  var _default = Ember.Component.extend({
    mainJsClass: function () {
      return "".concat(mainJsClassBase, " ").concat(mainJsClassBase, "-").concat(this.get('model.id'));
    }.property('model.id'),
    modelFields: function () {
      var _this = this;
      return this.get('model.configFields').filter(function (field) {
        if (!_this.get('model.labelOffer.carrierSupportsInsurance') && field.name === 'insuranceEnabled') {
          return false;
        }
        if (!_this.get('model.labelOffer.carrierSupportsOfferOnZipCodes') && field.name === 'onlyUseIfZipCodeAvailable') {
          return false;
        }
        if (field.name === 'providedLabelOfferOptionId') {
          return false;
        }
        return true;
      });
    }.property('model.configFields', 'model.labelOffer.carrierSupportsInsurance', 'model.labelOffer.carrierSupportsOfferOnZipCodes')
  });
  _exports.default = _default;
});