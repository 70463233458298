define("label_provider_app/templates/components/button-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Rc8NOX2m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[28,[[21,\"jsClass\"],\" btn btn-\",[21,\"btnZizer\"],\" btn-\",[21,\"btnColor\"],\" \",[21,\"btnPosition\"],\" \",[27,\"if\",[[23,[\"noTitle\"]],\"no-title\"],null]]]],[12,\"disabled\",[28,[[21,\"disabledStatus\"]]]],[12,\"data-dismiss\",[21,\"dataDismiss\"]],[12,\"title\",[21,\"actualTitle\"]],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"btnAction\"]],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[28,[\"icon fa fa-\",[21,\"actualFaIcon\"]]]],[9],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"noTitle\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"or\",[[23,[\"displayTitle\"]],[23,[\"actualTitle\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/button-icon.hbs"
    }
  });
  _exports.default = _default;
});