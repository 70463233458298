define("label_provider_app/templates/components/site-menubar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qj8ufuVn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"site-menubar-body cuke-nav-sidebar\"],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"site-menu\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"clientProvider\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"site-menu-item\",null,[[\"item\",\"route\"],[\"providedClients\",[23,[\"currentRouteName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[27,\"site-menu-item\",null,[[\"item\",\"route\"],[\"labelOffers\",[23,[\"currentRouteName\"]]]]],false],[0,\"\\n        \"],[1,[27,\"site-menu-item\",null,[[\"item\",\"route\"],[\"addressLabelSummaries\",[23,[\"currentRouteName\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"currentUser\",\"walletId\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"site-menu-item\",null,[[\"item\",\"itemId\",\"route\"],[\"wallet\",[23,[\"currentUser\",\"walletId\"]],[23,[\"currentRouteName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[27,\"site-menu-item\",null,[[\"item\",\"route\"],[\"reportConfigs\",[23,[\"currentRouteName\"]]]]],false],[0,\"\\n        \"],[1,[27,\"site-menu-item\",null,[[\"item\",\"route\"],[\"labelCredentials\",[23,[\"currentRouteName\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/site-menubar.hbs"
    }
  });
  _exports.default = _default;
});