define("label_provider_app/components/label-pricing-table-display", ["exports", "label_provider_app/mixins/status-message"], function (_exports, _statusMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var examplePricingTableDirPath = ['example_csvs', 'label_offers'].join('/');
  var exampleFileNames = ['pricing_table_example_001.csv'];
  var exampleFilesPaths = exampleFileNames.map(function (name) {
    return ["/".concat(examplePricingTableDirPath), name].join('/');
  });
  var _default = Ember.Component.extend(_statusMessage.default, {
    store: Ember.inject.service(),
    exampleFilesPaths: exampleFilesPaths,
    _executeOnUpload: function _executeOnUpload(uploadedFile) {
      var newPricingTable = this.makeNewPricingTable(uploadedFile);
      this._performSave(newPricingTable);
    },
    makeNewPricingTable: function makeNewPricingTable(uploadedFile) {
      return this.get('store').createRecord('labelPricingTable', {
        labelOfferOption: this.get('labelOfferOption'),
        uploadedFileId: uploadedFile['id']
      });
    },
    _performSave: function _performSave(pricingTable) {
      var _this = this;
      this.saveAndStatus(pricingTable, function () {
        _this.reloadOfferOption();
      });
    },
    reloadOfferOption: function reloadOfferOption() {
      this.get('labelOfferOption').reload();
    },
    actions: {
      onFileUpload: function onFileUpload(uploadedFile) {
        this._executeOnUpload(uploadedFile);
      },
      onPricingTableDestroy: function onPricingTableDestroy() {
        this.reloadOfferOption();
      }
    }
  });
  _exports.default = _default;
});