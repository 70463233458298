define("label_provider_app/templates/components/message-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YwA3OXEF",
    "block": "{\"symbols\":[\"msg\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"somethingToShow\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"alert alert-\",[21,\"messageType\"],\" alert-dismissible callout callout-\",[21,\"messageType\"],\" \",[21,\"jsClass\"],\" js-message-callout-div\"]]],[9],[0,\"\\n    \"],[1,[21,\"messageHeader\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"hasMessages\"]]],null,{\"statements\":[[0,\"      \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"messageArray\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"js-message-callout-item\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[14,2],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/message-callout.hbs"
    }
  });
  _exports.default = _default;
});