define("label_provider_app/controllers/provided-clients", ["exports", "label_provider_app/controllers/pagination-base", "label_provider_app/mixins/model-fields"], function (_exports, _paginationBase, _modelFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var tableFields = [{
    name: 'details',
    notSearchable: true,
    notSortable: true,
    component: 'link-to-details',
    sticky: true,
    narrow: true
  }, {
    name: 'sourceRef',
    type: 'string',
    sticky: true,
    presentForCreation: true,
    necessary: true
  }, {
    name: 'id',
    type: 'number',
    sticky: true
  }, {
    name: 'companyName',
    type: 'string',
    isEditable: false,
    sticky: true,
    presentForCreation: true,
    necessary: true
  }, {
    name: 'state',
    type: 'string',
    isEditable: false,
    sticky: true
  }, {
    name: 'email',
    type: 'string',
    isEditable: false,
    sticky: true,
    presentForCreation: true,
    necessary: true
  }, {
    name: 'phoneNumber',
    type: 'string',
    isEditable: false,
    sticky: true
  }, {
    name: 'zip',
    type: 'string',
    isEditable: false,
    sticky: true
  }, {
    name: 'city',
    type: 'string',
    isEditable: false,
    sticky: true
  }, {
    name: 'country',
    type: 'string',
    isEditable: false,
    sticky: true,
    presentForCreation: true,
    necessary: true
  }, {
    name: 'zip',
    type: 'string',
    isEditable: false,
    sticky: false,
    presentForCreation: true,
    necessary: true
  }, {
    name: 'city',
    type: 'string',
    isEditable: false,
    sticky: false,
    presentForCreation: true,
    necessary: true
  }, {
    name: 'street',
    type: 'string',
    isEditable: false,
    sticky: false,
    presentForCreation: true,
    necessary: true
  }, {
    name: 'phoneNumber',
    type: 'string',
    isEditable: false,
    sticky: false,
    presentForCreation: true,
    necessary: true
  }, {
    name: 'mobilePhone',
    type: 'string',
    isEditable: false,
    sticky: false,
    presentForCreation: true,
    necessary: false
  }, {
    name: 'firstname',
    type: 'string',
    isEditable: false,
    sticky: false,
    presentForCreation: true,
    necessary: true
  }, {
    name: 'lastname',
    type: 'string',
    isEditable: false,
    sticky: false,
    presentForCreation: true,
    necessary: true
  }];
  var _default = _paginationBase.default.extend(_modelFields.default, {
    currentUser: Ember.inject.service(),
    i18n: Ember.inject.service(),
    tableFields: tableFields,
    creatorExplanation: function () {
      var key = 'creation.explanation';
      return this.get('i18n').t('providedClient.' + key);
    }.property('i18n'),
    clientCreator: function () {
      return this.get('currentUser.labelProvider.clientCreating');
    }.property('currentUser.labelProvider.clientCreating')
  });
  _exports.default = _default;
});