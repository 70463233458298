define("label_provider_app/components/site-menubar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['site-menubar'],
    currentUser: Ember.inject.service(),
    clientProvider: function () {
      return this.get('currentUser.labelProvider.clientProviding');
    }.property('currentUser.labelProvider.clientProviding')

    // isCurrentRouteOrders: function() {
    //   const routeName = this.get('currentRouteName');
    //   return ['orders', 'createOrder', 'order'].includes(routeName);
    // }.property('currentRouteName'),
  });
  _exports.default = _default;
});