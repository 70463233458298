define("label_provider_app/components/reload-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    model: null,
    didRender: function didRender() {
      var _this = this;
      var model = this.get('model');
      if (!model) {
        this.set('actualObject', null);
        return;
      }
      if (model.then) {
        model.then(function (object) {
          _this.set('actualObject', object);
        });
      } else {
        this.set('actualObject', model);
      }
    },
    actions: {
      reloadObject: function reloadObject() {
        var actualObject = this.get('actualObject');
        if (actualObject) {
          actualObject.reload().then(function (reloadedRecord) {
            console.log("Record ".concat(reloadedRecord.id, " was reloaded."));
            // const onReload = this.get('onReload');
            // if (onReload) {
            //   onReload();
            // }
          });
        }
      }
    }
  });
  _exports.default = _default;
});