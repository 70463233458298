define("label_provider_app/components/search-field", ["exports", "lodash", "label_provider_app/constants"], function (_exports, _lodash, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // const booleans = [true, false];
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    field: null,
    translationFct: null,
    possibleValueOptions: null,
    searchAction: null,
    searchObject: null,
    type: null,
    isOpen: false,
    baseValue: null,
    // formatDate: Ember.inject.service(),
    didRender: function didRender() {
      if (!this.get('searchAction')) {
        console.log('You did not inject any searchAction into the search-field.');
      }
    },
    // booleans: function() {
    //   const optionsWithNull = [null].concat(booleans);
    //   return optionsWithNull.map(bool => {
    //     return {
    //       value: bool,
    //       label: this.get('i18n').t('main' + '.' + (bool + '').camelize()),
    //     };
    //   });
    // }.property('i18n'),

    baseModifier: function () {
      return _constants.default.search.defaultModifier[this.get('type')];
    }.property('type'),
    shouldBeDisabled: function () {
      return _lodash.default.includes(['boolean', 'date'], this.get('type'));
    }.property('type'),
    startingOptions: function () {
      return _constants.default.search.modifiers[this.get('type')];
    }.property('type'),
    remainingOptions: function () {
      var _this = this;
      return _lodash.default.map(this.get('startingOptions'), function (mod) {
        return {
          value: mod,
          label: _constants.default.search.nameForMod(_this.get('i18n'), mod)
        };
      });
    }.property('startingOptions'),
    close: function close() {
      this.set('isOpen', false);
    },
    updateSearch: function updateSearch(modifier, value) {
      this.get('searchObject').updateSearch(this.get('field'), modifier, value);
    },
    removeSearch: function removeSearch(modifier, value) {
      this.get('searchObject').removeSearch(this.get('field'), modifier, value);
    },
    actions: {
      searchByBase: function searchByBase(value) {
        if (value === '') {
          value = null;
        }
        this.get('searchObject').updateSearch(this.get('field'), this.get('baseModifier'), value);
        this.set('baseValue', null);
        if (this.get('searchAction')) {
          this.get('searchAction')();
        }
      },
      add: function add(modifier, value) {
        this.updateSearch(modifier, value);
        this.get('searchAction')();
        this.close();
      },
      remove: function remove(modifier, value) {
        this.removeSearch(modifier, value);
        this.get('searchAction')();
      },
      open: function open() {
        this.set('isOpen', true);
      },
      close: function close() {
        this.close();
      }

      // toggleBoolean: function(booleanValue) {
      //   this.get('searchObject').removeSearch(
      //     this.get('field'),
      //     this.get('baseModifier'),
      //     !booleanValue
      //   );
      //   this.get('searchObject').updateSearch(
      //     this.get('field'),
      //     this.get('baseModifier'),
      //     booleanValue
      //   );
      //   this.get('searchAction')();
      // },
    }
  });
  _exports.default = _default;
});