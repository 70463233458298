define("label_provider_app/tests/factories/lemon-bank-accounts", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('lemonBankAccount', {
    default: {
      wallet: _emberDataFactoryGuy.default.belongsTo('wallet'),
      iban: 'FR7610206000870473747874024',
      bic: 'NORDFRPP'
    },
    traits: {
      registered: {
        lemonRef: '123'
      }
    }
  });
});