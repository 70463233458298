define("label_provider_app/modules/languages", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.locale = locale;
  _exports.navigatorLanguages = navigatorLanguages;
  _exports.supported = supported;
  _exports.userLanguage = userLanguage;
  // app/modules/languages.js

  function supported(lng) {
    if (lng) {
      return _lodash.default.filter(['en', 'fr', 'es'], function (val) {
        return lng.substr(0, val.length) === val;
      })[0];
    }
  }
  function navigatorLanguages() {
    return navigator.languages;
  }

  // Locally navigator.languages is null when running test in phantom
  // But could it be the case that coverage is decided with respect
  // to Chrome?
  function userLanguage() {
    var navLanguages = navigatorLanguages();
    if (!navLanguages) {
      return undefined;
    }
    return _lodash.default.filter(navLanguages, function (lng) {
      return supported(lng);
    })[0];
  }
  var usEnglish = 'en-US';
  function locale() {
    return userLanguage() || supported(navigator.language) || usEnglish;
  }
});