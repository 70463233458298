define("label_provider_app/templates/components/site-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KoHDMpzS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[12,\"class\",[28,[\"site-menu-item cuke-site-menu-item-\",[21,\"item\"],\" js-site-menu-item-\",[21,\"item\"],\"\\n          \",[27,\"if\",[[27,\"is-equal\",[[23,[\"item\"]],[23,[\"route\"]]],null],\"open\"],null],\"\\n          \",[27,\"if\",[[23,[\"type\"]],[27,\"concat\",[\"site-menu-item-\",[23,[\"type\"]]],null]],null],\" \",[27,\"if\",[[23,[\"visible\"]],\"visible\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"itemId\"]]],null,{\"statements\":[[4,\"link-to\",[[23,[\"item\"]],[23,[\"itemId\"]]],[[\"disabled\"],[[23,[\"disabled\"]]]],{\"statements\":[[0,\"      \"],[7,\"i\"],[12,\"class\",[28,[\"site-menu-icon wb-\",[27,\"or\",[[23,[\"icon\"]],[23,[\"item\"]]],null]]]],[9],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"site-menu-title\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"main.\",[23,[\"item\"]]],null]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-to\",[[23,[\"item\"]]],[[\"disabled\"],[[23,[\"disabled\"]]]],{\"statements\":[[0,\"      \"],[7,\"i\"],[12,\"class\",[28,[\"site-menu-icon wb-\",[27,\"or\",[[23,[\"icon\"]],[23,[\"item\"]]],null]]]],[9],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"site-menu-title\"],[9],[1,[27,\"t\",[[27,\"concat\",[\"main.\",[23,[\"item\"]]],null]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/site-menu-item.hbs"
    }
  });
  _exports.default = _default;
});