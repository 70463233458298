define("label_provider_app/templates/provided-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "m1u0YT+1",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"panel-bordered\",null,null,{\"statements\":[[4,\"panel-heading\",null,[[\"title\"],[[27,\"t\",[\"providedClient.details.main\"],[[\"id\",\"ref\"],[[23,[\"model\",\"id\"]],[23,[\"model\",\"sourceRef\"]]]]]]],{\"statements\":[[4,\"panel-actions\",null,null,{\"statements\":[[4,\"if\",[[23,[\"showImpersonator\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"provident-client-impersonator\",null,[[\"model\",\"jsClass\"],[[23,[\"model\"]],\"js-provided-client-impersonator\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"panel-body-inner\",null,[[\"jsClass\"],[\"cuke-provided-client-account-panel\"]],{\"statements\":[[4,\"panel-row\",null,null,{\"statements\":[[4,\"each\",[[23,[\"model\",\"detailsFields\"]]],null,{\"statements\":[[4,\"label-and-thing\",null,[[\"label\"],[[27,\"t\",[[27,\"concat\",[\"apiClient.fields.\",[22,1,[\"name\"]]],null]],null]]],{\"statements\":[[0,\"          \"],[1,[27,\"property-editor\",null,[[\"model\",\"field\",\"isEditable\",\"jsClass\"],[[23,[\"model\"]],[22,1,[\"name\"]],false,\"js-provided-client-editor\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/provided-client.hbs"
    }
  });
  _exports.default = _default;
});