define("label_provider_app/components/collection-table-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import _ from 'lodash';
  var _default = Ember.Component.extend({
    params: {},
    model: null

    // init() {
    //   this._super(...arguments);
    //   _.forOwn(this.get('params'), (value, key) => {
    //     debugger;
    //     this.set(key, value);
    //   });
    // },
  });
  _exports.default = _default;
});