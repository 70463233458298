define("label_provider_app/initializers/model-initializer", ["exports", "ember-data", "label_provider_app/config/environment", "jquery"], function (_exports, _emberData, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    _emberData.default.Model.reopen({
      updateUnique: function updateUnique(field, value) {
        var _this = this;
        this.set(field, value);
        field = Ember.String.underscore(field);
        var modelName = Ember.String.underscore(this.constructor.modelName);
        var endpoint = Ember.String.pluralize(modelName);
        var limitedData = {};
        limitedData[modelName] = {};
        limitedData[modelName][field] = this.serialize()[field];
        return new Ember.RSVP.Promise(function (resolve, reject) {
          _jquery.default.ajax({
            url: "".concat(_environment.default.api.oms, "/").concat(endpoint, "/").concat(_this.get('id')),
            method: 'PUT',
            dataType: 'json',
            contentType: 'application/json; charset=UTF-8',
            data: JSON.stringify(limitedData)
          }).done(function (payload) {
            // Discard model changes then reload to use values from backend
            //
            // Currently model.rollbackAttribute is a canary only feature.
            // Replace the next line with `model.rollbackAttribute(field)` when it
            // becomes available.
            // See https://www.emberjs.com/api/data/classes/DS.Model.html#method_rollbackAttribute
            Ember.run(function () {
              _this.rollbackAttributes();
              _this.get('store').pushPayload(payload);
              resolve(payload);
            });
          }).fail(function (reason) {
            Ember.run(function () {
              reject(reason);
            });
          });
        });
      }
    });
  }
  var _default = {
    name: 'model-initializer',
    initialize: initialize
  };
  _exports.default = _default;
});