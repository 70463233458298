define("label_provider_app/instance-initializers/i18n", ["exports", "label_provider_app/modules/languages"], function (_exports, _languages) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'i18n',
    initialize: function initialize(container) {
      var i18n = container.lookup('service:i18n');
      i18n.set('locale', (0, _languages.locale)());
    }
  };
  _exports.default = _default;
});