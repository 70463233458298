define("label_provider_app/tests/factories/label-offer-options", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('labelOfferOption', {
    polymorphic: false,
    default: {
      service: 'some service',
      labelOffer: _emberDataFactoryGuy.default.belongsTo('labelOffer')
    },
    chronoLabelOfferOption: {
      service: 'Chrono 18',
      labelOffer: _emberDataFactoryGuy.default.belongsTo('chronoLabelOffer')
    },
    pricedLabelOfferOption: {
      service: 'Chrono 13',
      labelOffer: _emberDataFactoryGuy.default.belongsTo('chronoLabelOffer'),
      labelPricingTable: _emberDataFactoryGuy.default.belongsTo('labelPricingTable')
    }
  });
});