define("label_provider_app/tests/factories/address-label-summaries", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('addressLabelSummary', {
    default: {
      merchantCountry: 'CH',
      country: 'FR',
      zip: '75012'
    },
    traits: {
      germany: {
        country: 'DE',
        zip: '10243'
      }
    }
  });
});