define("label_provider_app/templates/components/label-pricing-table-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "hPME1VJ0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"panel-bordered\",null,[[\"jsClass\"],[\"js-pricing-table-display\"]],{\"statements\":[[4,\"panel-heading\",null,[[\"title\",\"jsClass\"],[[27,\"t\",[\"labelPricingTable.heading\"],[[\"optionId\"],[[23,[\"labelOfferOption\",\"id\"]]]]],\"js-pricing-table-display-heading\"]],{\"statements\":[[4,\"panel-actions\",null,null,{\"statements\":[[4,\"if\",[[23,[\"labelOfferOption\",\"labelPricingTableId\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"reload-object\",null,[[\"model\",\"jsClass\"],[[23,[\"labelOfferOption\",\"labelPricingTable\"]],\"js-reload-pricing-table\"]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"labelOfferOption\",\"labelPricingTable\",\"potentiallyDestroyable\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"destroy-object\",null,[[\"model\",\"onDestroy\"],[[23,[\"labelOfferOption\",\"labelPricingTable\"]],[27,\"action\",[[22,0,[]],\"onPricingTableDestroy\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"panel-body-inner\",null,[[\"jsClass\"],[\"js-pricing-table-display-inner\"]],{\"statements\":[[4,\"if\",[[23,[\"labelOfferOption\",\"labelPricingTableId\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"pricing-table-details\",null,[[\"model\"],[[23,[\"labelOfferOption\",\"labelPricingTable\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"downloadables-list\",null,[[\"filePaths\"],[[23,[\"exampleFilesPaths\"]]]]],false],[0,\"\\n      \"],[1,[27,\"fcv-uploader\",null,[[\"action\",\"name\"],[[27,\"action\",[[22,0,[]],\"onFileUpload\"],null],\"js-pricing-table-uploader-name\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/label-pricing-table-display.hbs"
    }
  });
  _exports.default = _default;
});