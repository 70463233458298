define("label_provider_app/templates/label-provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7Mpy1Suv",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"panel-bordered\",null,null,{\"statements\":[[0,\"  \"],[4,\"panel-heading\",null,[[\"title\"],[[27,\"t\",[\"configs.labelProviderInformation.necessaryFields\"],null]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"],[4,\"panel-body-inner\",null,[[\"jsClass\"],[\"cuke-label-provider-account-panel\"]],{\"statements\":[[4,\"panel-row\",null,null,{\"statements\":[[4,\"each\",[[23,[\"necessaryFields\"]]],null,{\"statements\":[[4,\"label-and-thing\",null,[[\"label\"],[[27,\"t\",[[27,\"concat\",[\"labelProvider.fields.\",[22,1,[\"name\"]]],null]],null]]],{\"statements\":[[0,\"          \"],[1,[27,\"property-editor\",null,[[\"model\",\"field\",\"isEditable\",\"jsClass\"],[[23,[\"model\"]],[22,1,[\"name\"]],true,\"js-label-provider-editor\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/label-provider.hbs"
    }
  });
  _exports.default = _default;
});