define("label_provider_app/routes/application", ["exports", "moment", "label_provider_app/adapters/timeout-unauthorized-error"], function (_exports, _moment, _timeoutUnauthorizedError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    i18n: Ember.inject.service(),
    momentService: Ember.inject.service('moment'),
    beforeModel: function beforeModel() {
      var momentService = this.get('momentService');
      var i18n = this.get('i18n');
      var locale = i18n.get('locale');
      momentService.setLocale(locale);
      momentService.setTimeZone(_moment.default.tz.guess());
    },
    actions: {
      error: function error(_error) {
        if (_error instanceof _timeoutUnauthorizedError.default) {
          this._reloadLocation(window.location);
          return;
        }
      }
    },
    _reloadLocation: function _reloadLocation(windowLocation) {
      windowLocation.reload(true);
    }
  });
  _exports.default = _default;
});