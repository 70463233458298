define("label_provider_app/components/wallet-money-out-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultCurrency = 'EUR';
  var _default = Ember.Component.extend({
    classNames: ['js-wallet-money-out-creator'],
    additionalFields: ['lemonFeeCents', 'amountCredited'],
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    modelFields: function () {
      return [{
        name: 'amountInUnits',
        type: 'text',
        necessary: true
      }, {
        name: 'amountCurrency',
        type: 'text',
        readOnly: true,
        necessary: true
      }];
    }.property('memberTypesOptions'),
    initialData: function () {
      var initialData = {
        wallet: this.get('wallet'),
        amountCents: 0,
        amountCurrency: defaultCurrency
      };
      return initialData;
    }.property('wallet'),
    actions: {
      afterCreate: function afterCreate(createdMoneyOut, cb) {
        if (cb) {
          cb();
        }
        this.set('createdMoneyOut', createdMoneyOut);
        // FIXME
        // Remove log
        console.log(createdMoneyOut.toJSON());
        this.get('createdMoneyOut').reload();
      }
    }
  });
  _exports.default = _default;
});