define("label_provider_app/components/modal-popin", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    title: null,
    modalType: 'primary',
    // Can be primary = blue, success = green, warning = orange, danger = red
    cancelAction: null,
    createAction: null,
    createActionDisabled: null,
    created: false,
    createButtonText: null,
    cancelText: null,
    renderedAction: null,
    forceWidth: null,
    backdropFunction: function () {
      // if (!this.get('cancelAction')) {
      //   return 'static';
      // }
    }.property('cancelAction'),
    keyboardFunction: function () {
      return !!this.get('cancelAction');
    }.property('cancelAction'),
    show: function () {
      var _this = this;
      this.set('created', false);
      this.$('.modal').modal({
        backdrop: this.get('backdropFunction'),
        keyboard: this.get('keyboardFunction')
      }).on('hidden.bs.modal', function () {
        if (!_this.get('created') && _this.get('cancelAction')) {
          _this.get('cancelAction')();
        }
      });
    }.on('didRender'),
    _modalInDOM: function _modalInDOM() {
      return this.$('.modal');
    },
    hideEverything: function hideEverything() {
      var modalInDOM = this._modalInDOM();
      if (modalInDOM) {
        modalInDOM.modal('hide');
      }
      (0, _jquery.default)('.modal-backdrop').hide();
    },
    fullCreateButtonText: function () {
      // if (this.get('createButtonText')) {
      //   return this.get('createButtonText');
      // }
      return this.get('i18n').t('main.validate');
    }.property('i18n', 'createButtonText'),
    fullCancelText: function () {
      // if (this.get('cancelText')) {
      //   return this.get('cancelText');
      // }
      return this.get('i18n').t('main.cancel');
    }.property('i18n', 'cancelText'),
    _performCancel: function _performCancel() {
      this._performAction('cancel');
    },
    _performCreate: function _performCreate() {
      this._performAction('create');
    },
    _performAction: function _performAction(actionType) {
      var _this2 = this;
      var action = "".concat(actionType, "Action");
      this.set('created', true);
      if (this.get(action)) {
        this.get(action)(function () {
          _this2.hideEverything();
        });
      }
    },
    actions: {
      cancel: function cancel() {
        this._performCancel();
      },
      create: function create() {
        this._performCreate();
      }
    }
  });
  _exports.default = _default;
});