define("label_provider_app/templates/label-config-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ki+oxXgN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"panel-bordered\",null,null,{\"statements\":[[4,\"panel-heading\",null,[[\"title\"],[[23,[\"model\",\"name\"]]]],{\"statements\":[[4,\"panel-actions\",null,null,{\"statements\":[[0,\"      \"],[1,[27,\"button-icon\",null,[[\"jsClass\",\"btnType\",\"action\"],[\"js-save-label-config-template\",\"save\",[27,\"action\",[[22,0,[]],\"save\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"panel-body-inner\",null,null,{\"statements\":[[0,\"    \"],[1,[27,\"message-callout\",null,[[\"messageType\",\"messageHeader\",\"messages\"],[[23,[\"messageType\"]],[23,[\"messageHeader\"]],[23,[\"messages\"]]]]],false],[0,\"\\n\"],[4,\"label-and-thing\",null,[[\"jsClass\",\"label\"],[\"js-label-config-template-name\",[27,\"t\",[\"labelConfigTemplate.fields.name\"],null]]],{\"statements\":[[0,\"      \"],[1,[27,\"input\",null,[[\"class\",\"value\"],[\"form-control\",[27,\"mut\",[[23,[\"model\",\"name\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[1,[27,\"config-editor\",null,[[\"model\",\"jsClass\"],[[23,[\"model\"]],\"js-label-config-template-configs\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/label-config-template.hbs"
    }
  });
  _exports.default = _default;
});