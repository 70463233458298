define("label_provider_app/templates/label-credentials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aTJzeRIT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"cuke-label-credentials-page\"],[9],[0,\"\\n\"],[4,\"collection-table\",null,[[\"originRoute\",\"domain\",\"model\",\"tableFields\",\"sortOrder\",\"sortField\",\"sortAscending\",\"searchObject\",\"page\",\"transition\",\"jsClass\"],[\"labelCredentials\",[23,[\"domain\"]],[23,[\"model\"]],[23,[\"tableFields\"]],[27,\"mut\",[[23,[\"sortOrder\"]]],null],[27,\"mut\",[[23,[\"sortField\"]]],null],[27,\"mut\",[[23,[\"sortAscending\"]]],null],[23,[\"searchObject\"]],[27,\"mut\",[[23,[\"page\"]]],null],[27,\"action\",[[22,0,[]],\"updateRoute\"],null],\"js-label-credentials-collection-table\"]],{\"statements\":[[4,\"if\",[[23,[\"tableFieldsReady\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"js-labelCredential-creator\"],[9],[0,\"\\n        \"],[1,[27,\"object-creator\",null,[[\"table\",\"action\",\"modelFields\",\"explanation\"],[\"labelCredential\",[27,\"action\",[[22,0,[]],\"objectCreated\"],null],[23,[\"creationFields\"]],[23,[\"creatorExplanation\"]]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/label-credentials.hbs"
    }
  });
  _exports.default = _default;
});