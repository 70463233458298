define("label_provider_app/models/wallet-money-out", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    wallet: _emberData.default.belongsTo('wallet'),
    amountCents: _emberData.default.attr('number'),
    amountInUnits: _emberData.default.attr('number'),
    amountCurrency: _emberData.default.attr('string')
  });
  _exports.default = _default;
});