define("label_provider_app/mixins/status-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import Constants from '../constants';
  var _default = Ember.Mixin.create({
    i18n: Ember.inject.service(),
    messageHeader: null,
    messages: null,
    messageType: null,
    successMessageKey: 'save.success',
    failureMessageKey: 'save.errors',
    resetMessageAfterwards: false,
    resetMessage: function resetMessage() {
      this.set('messageType', null);
      this.set('messageHeader', null);
      this.set('messages', null);
    },
    showSuccess: function showSuccess(successKey) {
      this.set('messageHeader', this.get('i18n').t(successKey || this.successMessageKey));
      this.set('messages', null);
      this.set('messageType', 'success');
      // if (this.get('resetMessageAfterwards')) {
      //   Ember.run.later(() => {
      //     this.resetMessage();
      //   }, Constants.waitTime);
      // }
    },

    showFailure: function showFailure(response, failureKey) {
      var message = null;
      if (response && response.errors && response.errors.message) {
        message = response.errors.message;
      }
      this.set('messageHeader', this.get('i18n').t(failureKey || this.failureMessageKey));
      this.set('messages', message);
      this.set('messageType', 'danger');
      // if (this.get('resetMessageAfterwards')) {
      //   Ember.run.later(() => {
      //     this.resetMessage();
      //   }, Constants.waitTime);
      // }
    },

    saveAndStatus: function saveAndStatus(object, callback) {
      var _this = this;
      object.save().then(function (obj) {
        _this._onSuccessfulSave(obj, callback);
      }, function (response) {
        _this.showFailure(response.responseJSON || response);
      });
    },
    _onSuccessfulSave: function _onSuccessfulSave(savedObject, callback) {
      this.showSuccess();
      if (callback) {
        callback(savedObject);
      }
    } // updateUniqueAndStatus: function(object, field, value, callback) {
    //   object.updateUnique(field, value).then(
    //     obj => {
    //       this.showSuccess();
    //       if (callback) {
    //         callback(obj);
    //       }
    //     },
    //     response => {
    //       this.showFailure(response.responseJSON || response);
    //     }
    //   );
    // },
  });
  _exports.default = _default;
});