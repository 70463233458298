define("label_provider_app/adapters/application", ["exports", "active-model-adapter", "label_provider_app/adapters/timeout-unauthorized-error", "label_provider_app/config/environment"], function (_exports, _activeModelAdapter, _timeoutUnauthorizedError, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _activeModelAdapter.default.extend({
    host: _environment.default.api.oms,
    currentUser: Ember.inject.service(),
    headers: Ember.computed('currentUser.csrfToken', function () {
      return {
        'X-CSRF-Token': this.currentUser.csrfToken,
        'X-ActiveModelSerializers': '1'
      };
    }),
    /*eslint no-unused-vars: ["error", { "args": "none" }]*/
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 401 && payload.error.includes('Your session expired')) {
        return new _timeoutUnauthorizedError.default();
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
  _exports.default = _default;
});