define("label_provider_app/templates/components/label-offer-option-archiver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6LBEiE/5",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"button-icon\",null,[[\"jsClass\",\"action\",\"btnType\"],[[23,[\"jsClass\"]],[27,\"action\",[[22,0,[]],\"archiveLabelOfferOption\"],null],\"deactivate\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/label-offer-option-archiver.hbs"
    }
  });
  _exports.default = _default;
});