define("label_provider_app/templates/components/reload-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "76ZZF90g",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"actualObject\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"button-icon\",null,[[\"jsClass\",\"action\",\"title\",\"btnType\"],[[23,[\"jsClass\"]],[27,\"action\",[[22,0,[]],\"reloadObject\"],null],[23,[\"btnTitle\"]],\"refresh\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/reload-object.hbs"
    }
  });
  _exports.default = _default;
});