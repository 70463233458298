define("label_provider_app/components/weight-to-price-mapping-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['js-weight-to-price-mapping-editor', 'style-pricing-table-row'],
    tagName: 'tr'
  });
  _exports.default = _default;
});