define("label_provider_app/components/label-offer-activator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    model: null,
    actions: {
      activateOffer: function activateOffer() {
        this.get('model').updateUnique('active', true);
      }
    }
  });
  _exports.default = _default;
});