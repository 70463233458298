define("label_provider_app/routes/label-provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(params) {
      // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
      return this.store.find('labelProvider', params.label_provider_id);
      // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
    }
  });
  _exports.default = _default;
});