define("label_provider_app/components/pricing-table-mappings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var fields = ['minWeight', 'maxWeight', 'priceCents'];
  var pricingTableStyleClasses = ['style-label-pricing-table'];
  var _default = Ember.Component.extend({
    fields: fields,
    pricingTableClasses: function () {
      return pricingTableStyleClasses.concat([this.get('pricingJsClass')]).join(' ');
    }.property('pricingJsClass'),
    pricingJsClass: function () {
      return "js-pricing-table-".concat(this.get('pricingTable.id'), "-mappings-table");
    }.property('pricingTable.id')
  });
  _exports.default = _default;
});