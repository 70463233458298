define("label_provider_app/controllers/label-config-template", ["exports", "label_provider_app/mixins/status-message"], function (_exports, _statusMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend(_statusMessage.default, {
    actions: {
      save: function save() {
        this.saveAndStatus(this.get('model'));
      }
    }
  });
  _exports.default = _default;
});