define("label_provider_app/templates/components/panel-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C5bcaUoI",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"panel-bordered\",null,[[\"title\",\"jsClass\"],[[23,[\"title\"]],[23,[\"jsClass\"]]]],{\"statements\":[[4,\"panel-table-inner\",null,[[\"tableClass\"],[[23,[\"tableClass\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/panel-table.hbs"
    }
  });
  _exports.default = _default;
});