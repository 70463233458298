define("label_provider_app/tests/factories/wallets", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('wallet', {
    default: {
      kycReady: true,
      balanceCents: 2000,
      balanceCurrency: 'EUR',
      lemonPkey: '1234',
      potentialBalanceCents: 2400,
      potentialBalanceCurrency: 'EUR'
    },
    traits: {
      unregistered: {
        kycReady: false,
        lemonPkey: null
      },
      waitingForDocs: {
        kycReady: false
      },
      plentyOfMoney: {
        balanceCents: '50000',
        potentialBalanceCents: '50000'
      },
      withBankAccount: {
        lemonBankAccount: _emberDataFactoryGuy.default.belongsTo('lemonBankAccount', 'registered')
      },
      withUnregisteredBankAccount: {
        lemonBankAccount: _emberDataFactoryGuy.default.belongsTo('lemonBankAccount')
      }
    }
  });
});