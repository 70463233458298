define("label_provider_app/components/collection-table", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    sortOrder: null,
    sortField: null,
    sortAscending: null,
    sortClass: null,
    originRoute: null,
    domain: null,
    page: null,
    searchObject: null,
    showColumnSelector: true,
    tableFields: null,
    advancedSearchComponent: null,
    uniqueObjects: function () {
      return this.get('model').uniqBy('id');
    }.property('model.@each.id'),
    hasEditableFields: function () {
      return _lodash.default.some(this.get('tableFields'), function (field) {
        return !field.notSortable;
      });
    }.property('tableFields'),
    firstPage: function () {
      return this.get('page') === 1;
    }.property('page'),
    turnPage: function turnPage(offset) {
      var requestedNewPage = this.get('page') + offset;
      this.set('page', Math.max(0, requestedNewPage));
    },
    updateRoute: function updateRoute() {
      this.get('transition')({
        search: this.get('searchObject').toJSON(),
        page: this.get('page'),
        sortOrder: this.get('sortAscending') ? 'asc' : 'desc',
        sortField: this.get('sortField')
      });
    },
    columnConfig: function () {
      return this.get('store').findAll('userTableConfig');
    }.property(),
    domainColumns: function () {
      var _this = this;
      return this.get('columnConfig').filter(function (item) {
        // if (item.get('table') === this.get('domain')) {
        //   return item.get('enabled');
        // } else {
        //   return false;
        // }
        return item.get('table') === _this.get('domain');
      });
    }.property('domain', 'columnConfig.@each.table', 'columnConfig.@each.enabled'),
    // columns: function() {
    //   return _.map(this.get('tableFields'), function(f) {
    //     return f.name;
    //   });
    // }.property('tableFields'),

    selectedTableFields: function () {
      var _this2 = this;
      var colNames = _lodash.default.union(this.get('domainColumns').mapBy('column'), this.get('searchObject.searchedFields'));
      var baseSelection = _lodash.default.filter(this.get('tableFields'), function (f) {
        return _lodash.default.includes(colNames, f.name) || f.sticky;
      });
      baseSelection.forEach(function (field) {
        var newClass = '';
        if (field.name === _this2.get('sortField')) {
          newClass = '_' + _this2.get('sortOrder');
        }
        Ember.set(field, 'sortClass', newClass);
      });
      return baseSelection;
    }.property('tableFields', 'sortOrder', 'sortField', 'domainColumns.@each.column'),
    pageButtons: function () {
      var current = this.get('page');
      var buttons = [];
      for (var left = current - 1; left > Math.max(0, current - 3); left--) {
        buttons.unshift({
          index: left,
          current: false
        });
      }
      buttons.push({
        index: current,
        current: true
      });
      for (var right = current + 1; right < current + 3; right++) {
        buttons.push({
          index: right,
          current: false
        });
      }
      return buttons;
    }.property('page'),
    actions: {
      searchAction: function searchAction() {
        this.set('page', 1);
        this.updateRoute();
      },
      sortBy: function sortBy(sortField) {
        if (sortField === this.get('sortField')) {
          this.toggleProperty('sortAscending');
        } else {
          this.set('sortField', sortField);
          this.set('sortAscending', true);
        }
        this.updateRoute();
      },
      nextPage: function nextPage() {
        this.turnPage(1);
      },
      previousPage: function previousPage() {
        this.turnPage(-1);
      },
      goToPage: function goToPage(pageNum) {
        this.turnPage(pageNum - this.get('page'));
      },
      // reload: function() {
      //   this.get('reload')();
      // },
      resetQueryParams: function resetQueryParams() {
        this.get('router').transitionTo(this.get('originRoute'), {
          queryParams: {
            search: '{}',
            sortField: 'id',
            sortOrder: 'desc',
            page: 1
          }
        });
      }
    }
  });
  _exports.default = _default;
});