define("label_provider_app/templates/report-configs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3gnMRzxA",
    "block": "{\"symbols\":[\"group\",\"config\"],\"statements\":[[4,\"panel-table\",null,[[\"jsClass\"],[\"cuke-report-configs-page\"]],{\"statements\":[[4,\"each\",[[23,[\"groupedReports\"]]],null,{\"statements\":[[4,\"each\",[[22,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"tr\"],[11,\"class\",\"js-class-report-config-line\"],[9],[0,\"\\n        \"],[7,\"td\"],[9],[1,[22,2,[\"name\"]],false],[10],[0,\"\\n        \"],[7,\"td\"],[12,\"class\",[28,[\"cuke-go-to-\",[22,2,[\"cukeClass\"]]]]],[9],[0,\"\\n\"],[4,\"link-to\",[\"reportConfig.exports\",[22,2,[]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"button-icon\",null,[[\"btnType\",\"title\"],[\"config\",[27,\"t\",[\"reportConfig.go\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n        \"],[7,\"td\"],[12,\"class\",[28,[\"js-configure-\",[22,2,[\"cukeClass\"]]]]],[9],[0,\"\\n\"],[4,\"link-to\",[\"reportConfig.config\",[22,2,[]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"button-icon\",null,[[\"btnType\",\"title\"],[\"config\",[27,\"t\",[\"reportConfig.configure\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/report-configs.hbs"
    }
  });
  _exports.default = _default;
});