define("label_provider_app/templates/components/my-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0QyhBZ+3",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"each\",[[23,[\"content\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n    \"],[7,\"input\"],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"change\"],[[\"value\"],[\"target.value\"]]]],[12,\"id\",[28,[[21,\"name\"],\"-\",[22,1,[\"value\"]]]]],[12,\"name\",[28,[[21,\"name\"]]]],[12,\"value\",[28,[[22,1,[\"value\"]]]]],[12,\"checked\",[27,\"is-equal\",[[27,\"to-string\",[[22,1,[\"value\"]]],null],[27,\"to-string\",[[23,[\"selectedValue\"]]],null]],null]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n    \"],[7,\"label\"],[12,\"for\",[28,[[21,\"name\"],\"-\",[22,1,[\"value\"]]]]],[9],[1,[22,1,[\"label\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/my-radio.hbs"
    }
  });
  _exports.default = _default;
});