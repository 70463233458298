define("label_provider_app/models/lemon-bank-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    wallet: _emberData.default.belongsTo('wallet'),
    walletId: _emberData.default.attr('number'),
    holderFullname: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    bic: _emberData.default.attr('string'),
    iban: _emberData.default.attr('string'),
    bankName: _emberData.default.attr('string'),
    bankBranchCity: _emberData.default.attr('string'),
    bankBranchStreet: _emberData.default.attr('string'),
    lemonRef: _emberData.default.attr('string')
  });
  _exports.default = _default;
});