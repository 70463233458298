define("label_provider_app/components/object-creator", ["exports", "label_provider_app/mixins/status-message"], function (_exports, _statusMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
  var _default = Ember.Component.extend(_statusMessage.default, {
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    addButtonKey: 'main.add',
    btnType: 'add',
    table: null,
    model: null,
    modelFields: null,
    initialData: null,
    // beforeObjectCreated: null,
    explanation: null,
    action: null,
    cancelAction: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.defineReadyForCreateProperty();
    },
    actualExplanation: function () {
      return this.get('explanation') || this.get('i18n').t(this.get('table') + '.explanation');
    }.property('i18n', 'explanation', 'table'),
    creationDisabled: function () {
      return !this.get('readyForCreate');
    }.property('readyForCreate'),
    fields: function () {
      var _this = this;
      return this.get('modelFields').map(function (field) {
        return {
          field: field.name,
          type: field.type,
          optionSelected: field.optionSelected || null,
          options: field.options,
          name: _this.get('i18n').t(_this.get('table') + '.fields.' + field.name),
          inputClass: 'js-creator-' + field.name.dasherize(),
          readOnly: field.readOnly,
          warning: field.warning,
          necessary: _this.fieldEditable(field) && field.necessary,
          hidden: !_this.fieldEditable(field)
        };
      });
    }.property('modelFields', 'table'),
    fieldEditable: function fieldEditable(field) {
      if (!field.editCondition) {
        return true;
      }
      if (!this.model) {
        return true;
      }
      return field.editCondition(this.model);
    },
    defineReadyForCreateProperty: function defineReadyForCreateProperty() {
      var names = this.get('necessaryFieldsNames');
      Ember.defineProperty(this, 'readyForCreate', Ember.computed.apply(Ember, _toConsumableArray(names).concat(['necessaryFieldsNames', function () {
        var _this2 = this;
        return this.get('necessaryFieldsNames').every(function (necessaryField) {
          return _this2.get(necessaryField);
        });
      }])));
    },
    necessaryFields: function () {
      return this.get('fields').filter(function (field) {
        return field.necessary;
      });
    }.property('fields'),
    necessaryFieldsNames: function () {
      return this.get('necessaryFields').map(function (field) {
        return 'model.' + field.field;
      });
    }.property('necessaryFields'),
    reset: function reset() {
      if (this.isAlive()) {
        this.resetMessage();
        this.set('model', null);
        this.set('closingCb', null);
      }
    },
    showSuccess: function showSuccess() {
      var _this3 = this;
      this._super();
      Ember.run.later(function () {
        _this3._performOnSuccess();
      }, 2000);
    },
    _performOnSuccess: function _performOnSuccess() {
      var newModel = this.get('model');
      var closingCb = this.get('closingCb');
      this.reset();
      if (this.get('action')) {
        this.get('action')(newModel, closingCb);
      } else {
        if (closingCb) {
          closingCb();
        }
      }
    },
    actions: {
      setField: function setField(field, event) {
        this.model.set(field, this.$(event.target).val());
      },
      setDate: function setDate(field, date) {
        this.model.set(field, new Date(date.format()));
      },
      setFieldToValue: function setFieldToValue(field, value) {
        this.model.set(field, value);
        if (this.fieldChangedCb) {
          this.fieldChangedCb(field, value, this.model);
        }
      },
      start: function start() {
        // if (this.get('model')) {
        //   return;
        // }
        var data = this.get('initialData') || {};
        var newRecord = this.get('store').createRecord(this.get('table'), data);
        this.set('model', newRecord);
      },
      create: function create(cb) {
        var _this4 = this;
        // if (this.get('beforeObjectCreated')) {
        //   this.get('beforeObjectCreated')(this.get('model'));
        // }
        this.saveAndStatus(this.get('model'), function () {
          _this4.set('closingCb', cb);
        });
      },
      cancel: function cancel() {
        // The record would still persist in the store after cancelling
        if (this.model && this.model.isNew) {
          this.model.rollbackAttributes();
        }
        this.reset();
        // if (this.get('cancelAction')) {
        //   this.get('cancelAction')();
        // }
      }
    }
  });
  _exports.default = _default;
});