define("label_provider_app/templates/components/pricing-table-header-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QHkN0hjv",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"text\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/pricing-table-header-entry.hbs"
    }
  });
  _exports.default = _default;
});