define("label_provider_app/templates/components/dropdown-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JSP3tvCP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[11,\"role\",\"button\"],[11,\"tabindex\",\"1\"],[12,\"class\",[28,[\"dropdown-toggle \",[21,\"jsClass\"],\" js-dropdown-toggle\"]]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggle\"],null]],[9],[0,\"\\n  \"],[14,1,[\"title\"]],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"role\",\"menu\"],[11,\"class\",\"dropdown-menu\"],[9],[0,\"\\n  \"],[14,1,[\"menu\"]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/dropdown-menu.hbs"
    }
  });
  _exports.default = _default;
});