define("label_provider_app/components/provider-dropdown", ["exports", "label_provider_app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'ul',
    classNames: ['nav', 'navbar-toolbar', 'navbar-right', 'navbar-toolbar-right', 'cuke-user-menu'],
    currentUser: Ember.inject.service(),
    url: "".concat(_environment.default.api.oms, "/users/sign_out"),
    topbarDisplayName: function () {
      if (this.get('currentUser.companyName')) {
        return this.get('currentUser.companyName');
      }
      return this.get('currentUser.email');
    }.property('currentUser.email', 'currentUser.companyName')
  });
  _exports.default = _default;
});