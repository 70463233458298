define("label_provider_app/templates/components/panel-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "n0BY+G2v",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"panel-heading \",[21,\"class\"],\" \",[21,\"jsClass\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"panel-title js-panel-heading-title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"yieldTitle\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"panel-title yielded\"],[9],[0,\"\\n      \"],[14,1,[\"title\"]],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[14,1,[\"content\"]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/panel-heading.hbs"
    }
  });
  _exports.default = _default;
});