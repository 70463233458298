define("label_provider_app/components/my-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    content: null,
    selectedValue: null,
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (!this.get('action')) {
        console.log('You did not inject any action into my select.');
      }
      if (this.get('selectedValueMustBeReset') && this.get('content.firstObject')) {
        var value = this.get('content.firstObject').value;
        this.set('selectedValue', value);
        if (this.get('action')) {
          this.get('action')(value);
        }
      }
    },
    selectedValueMustBeReset: function () {
      if (this.get('selectedValue') === undefined) {
        return false;
      }
      return this.get('selectedValue') === null || !this.get('selectedValueIsAllowed');
    }.property('selectedValue', 'selectedValueIsAllowed'),
    selectedValueIsAllowed: function () {
      var selectedValue = this.get('selectedValue');
      return this.get('content').any(function (possibleValue) {
        return possibleValue.value === selectedValue;
      });
    }.property('selectedValue', 'content'),
    actions: {
      change: function change() {
        var changeAction = this.get('action');
        var selectedEl = this.$('select')[0];
        var selectedIndex = selectedEl.selectedIndex;
        var content = this.get('content');
        var selectedValue = content[selectedIndex].value;
        this.set('selectedValue', selectedValue);
        if (!changeAction) {
          console.log('Select change was observed. But no action has been injected.');
        }
        if (changeAction) {
          changeAction(selectedValue);
        }
      }
    }
  });
  _exports.default = _default;
});