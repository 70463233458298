define("label_provider_app/components/wallet-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    title: function () {
      return this.get('i18n').t('wallet.title');
    }.property('i18n'),
    allowMoneyOuts: function () {
      if (!this.get('operationalWallet')) {
        return false;
      }
      return this.get('model.lemonBankAccount.lemonRef');
    }.property('operationalWallet', 'model.lemonBankAccount.lemonRef'),
    operationalWallet: function () {
      return this.get('model.lemonPkey') && this.get('model.kycReady');
    }.property('model.lemonPkey', 'model.kycReady')
  });
  _exports.default = _default;
});