define("label_provider_app/templates/wallet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cP0jn+pW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"model\",\"lemonPkey\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"wallet-display\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n  \"],[1,[27,\"lemon-bank-account-display\",null,[[\"wallet\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"message-callout\",null,[[\"messageType\",\"messages\"],[\"info\",[27,\"t\",[\"wallet.waitingForRegistration\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/wallet.hbs"
    }
  });
  _exports.default = _default;
});