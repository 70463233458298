define("label_provider_app/templates/components/panel-bordered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "z4bWaIUr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"panel panel-bordered \",[21,\"jsClass\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"title\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"panel-heading\",null,[[\"title\"],[[23,[\"title\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/panel-bordered.hbs"
    }
  });
  _exports.default = _default;
});