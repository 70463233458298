define("label_provider_app/controllers/address-label-summaries", ["exports", "label_provider_app/controllers/pagination-base", "label_provider_app/mixins/model-fields"], function (_exports, _paginationBase, _modelFields) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var tableFields = [{
    name: 'details',
    notSearchable: true,
    notSortable: true,
    component: 'link-to-details',
    sticky: true,
    narrow: true
  }, {
    name: 'id',
    type: 'number',
    sticky: true
  }, {
    name: 'carrierName',
    type: 'string',
    isEditable: false,
    sticky: true,
    notSearchable: true,
    notSortable: true
  }, {
    name: 'country',
    type: 'string',
    isEditable: false,
    sticky: true
  }, {
    name: 'merchantCountry',
    type: 'string',
    isEditable: false,
    sticky: true
  }, {
    name: 'zip',
    type: 'string',
    isEditable: false,
    sticky: true
  }, {
    name: 'totalWeight',
    type: 'number',
    isEditable: false,
    sticky: true
  }, {
    name: 'shippingRef',
    type: 'string',
    isEditable: false,
    sticky: true
  }, {
    name: 'shippedAt',
    type: 'date',
    isEditable: false,
    sticky: true
  }, {
    name: 'createdAt',
    type: 'date',
    isEditable: false,
    sticky: true
  }];
  var _default = _paginationBase.default.extend(_modelFields.default, {
    currentUser: Ember.inject.service(),
    i18n: Ember.inject.service(),
    tableFields: tableFields
  });
  _exports.default = _default;
});