define("label_provider_app/models/label-credential", ["exports", "ember-data", "lodash", "label_provider_app/mixins/carrier-object"], function (_exports, _emberData, _lodash, _carrierObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var displayedInOfferView = [{
    name: 'name'
  }, {
    name: 'baseType'
  }];
  var detailsFields = [{
    name: 'id',
    type: 'string',
    isEditable: false
  }, {
    name: 'baseType',
    type: 'string',
    isEditable: false
  }, {
    name: 'name',
    type: 'string',
    isEditable: false
  }, {
    name: 'providedLabelCredentialId',
    type: 'number',
    isEditable: false
  }, {
    name: 'providedLabelCredentialName',
    type: 'string',
    isEditable: false
  }, {
    name: 'ready',
    type: 'boolean',
    isEditable: false
  }, {
    name: 'createdAt',
    type: 'moment',
    isEditable: false
  }];
  var _default = _emberData.default.Model.extend(_carrierObject.default, {
    type: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('moment'),
    name: _emberData.default.attr('string'),
    providedLabelCredentialId: _emberData.default.attr('number'),
    providedLabelCredentialName: _emberData.default.attr('string'),
    ready: _emberData.default.attr('boolean'),
    config: _emberData.default.attr(''),
    publicBooleanFields: _emberData.default.attr(''),
    displayedInOfferView: displayedInOfferView,
    labelOfferAvailableServices: _emberData.default.attr(''),
    availableProvidedLabelOffers: _emberData.default.attr(''),
    detailsFields: function () {
      var _this = this;
      return detailsFields.filter(function (field) {
        return !['providedLabelCredentialId', 'providedLabelCredentialName'].includes(field.name) || _this.get('providedLabelCredentialId');
      });
    }.property('providedLabelCredentialId'),
    configKeys: function () {
      return _lodash.default.keys(this.get('config'));
    }.property('config')
  });
  _exports.default = _default;
});