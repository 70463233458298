define("label_provider_app/templates/components/token-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XEQo3RWo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\"],[12,\"class\",[28,[\"token-label \",[21,\"jsClass\"],\" js-token-label\"]]],[11,\"style\",\"max-width: 439px;\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"removeAction\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"close js-remove-filter\"],[11,\"tabindex\",\"-1\"],[3,\"action\",[[22,0,[]],\"close\"]],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"symbol.close\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/token-field.hbs"
    }
  });
  _exports.default = _default;
});