define("label_provider_app/templates/label-offers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "i7yenF/m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"cuke-label-offers-page\"],[9],[0,\"\\n\"],[4,\"collection-table\",null,[[\"originRoute\",\"domain\",\"model\",\"tableFields\",\"sortOrder\",\"sortField\",\"sortAscending\",\"searchObject\",\"page\",\"transition\"],[\"labelOffers\",[23,[\"domain\"]],[23,[\"model\"]],[23,[\"tableFields\"]],[27,\"mut\",[[23,[\"sortOrder\"]]],null],[27,\"mut\",[[23,[\"sortField\"]]],null],[27,\"mut\",[[23,[\"sortAscending\"]]],null],[23,[\"searchObject\"]],[27,\"mut\",[[23,[\"page\"]]],null],[27,\"action\",[[22,0,[]],\"updateRoute\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"tableFieldsReady\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\"],[11,\"class\",\"js-label-offer-creator\"],[9],[0,\"\\n        \"],[1,[27,\"object-creator\",null,[[\"table\",\"action\",\"modelFields\",\"explanation\",\"initialData\",\"fieldChangedCb\",\"jsClass\"],[\"labelOffer\",[27,\"action\",[[22,0,[]],\"objectCreated\"],null],[23,[\"modelFields\"]],[23,[\"creatorExplanation\"]],[23,[\"initialData\"]],[27,\"action\",[[22,0,[]],\"updateAvailableProvidedOffers\"],null],\"js-create-label-offer\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/label-offers.hbs"
    }
  });
  _exports.default = _default;
});