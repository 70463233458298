define("label_provider_app/modules/phantom-detector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.phantomUser = phantomUser;
  // app/modules/phantom-detector.js

  // https://blog.shapesecurity.com/2015/01/22/detecting-phantomjs-based-visitors/

  function phantomUser() {
    return /PhantomJS/.test(window.navigator.userAgent);
  }
});