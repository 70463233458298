define("label_provider_app/controllers/report-config/config", ["exports", "label_provider_app/controllers/common-controller"], function (_exports, _commonController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _commonController.default.extend({
    currentUser: Ember.inject.service(),
    availableEncodings: [{
      value: 'utf-8',
      label: 'utf-8'
    }, {
      value: 'windows-1252',
      label: 'windows-1252'
    }, {
      value: 'ISO-8859-15',
      label: 'latin-9 (ISO-8859-15)'
    }],
    selectedOptions: function () {
      var defaultedOptions = this.get('model.defaultedOptions');
      if (!defaultedOptions) {
        return;
      }
      return defaultedOptions.map(function (option) {
        return option.camelize();
      });
    }.property('model.defaultedOptions')
  });
  _exports.default = _default;
});