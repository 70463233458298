define("label_provider_app/tests/factories/label-offers", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  var availableProvidedLabelOfferOptions = [{
    id: '78912',
    name: 'option-providing-you'
  }];
  _emberDataFactoryGuy.default.define('labelOffer', {
    polymorphic: false,
    default: {
      name: 'the great label offer',
      labelCredential: _emberDataFactoryGuy.default.belongsTo('labelCredential'),
      createdAt: Date.UTC(1952, 10, 7, 3, 24, 0)
    },
    chronoLabelOffer: {
      name: 'chronopost label offer',
      labelCredential: _emberDataFactoryGuy.default.belongsTo('chronoCredential'),
      createdAt: Date.UTC(2085, 11, 17, 3, 24, 0)
    },
    providedChronoLabelOffer: {
      name: 'provided chronopost label offer',
      labelCredential: _emberDataFactoryGuy.default.belongsTo('providedChronoCredential'),
      providedLabelOfferId: '34567',
      providedLabelOfferName: 'chronopost label offer',
      availableProvidedLabelOfferOptions: availableProvidedLabelOfferOptions,
      senderCountry: 'FR',
      type: 'LabelOffer::Basic',
      createdAt: Date.UTC(2085, 11, 17, 3, 24, 0)
    },
    laposteLabelOffer: {
      name: 'laposte offer',
      labelCredential: _emberDataFactoryGuy.default.belongsTo('laposteCredential'),
      createdAt: null
    },
    courrierSuiviLabelOffer: {
      name: 'courrier suivi offer',
      labelCredential: _emberDataFactoryGuy.default.belongsTo('courrierSuiviCredential'),
      createdAt: null,
      // receiverCountry: 'EU',
      senderCountry: 'FR',
      type: 'LabelOffer::CredentialResale'
    },
    colisPriveLabelOffer: {
      name: 'colis prive label offer',
      labelCredential: _emberDataFactoryGuy.default.belongsTo('colisPriveCredential'),
      carrierSupportsInsurance: true,
      carrierSupportsOfferOnZipCodes: true,
      createdAt: Date.UTC(2085, 11, 17, 3, 24, 0)
    }
  });
});