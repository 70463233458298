define("label_provider_app/helpers/t-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend({
    i18n: Ember.inject.service(),
    compute: function compute(params) {
      var _this = this;
      return params.map(function (item) {
        return _this.get('i18n').t(item);
      });
    }
  });
  _exports.default = _default;
});