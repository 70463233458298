define("label_provider_app/controllers/pagination-base", ["exports", "label_provider_app/models/search-object", "label_provider_app/controllers/common-controller"], function (_exports, _searchObject, _commonController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _commonController.default.extend({
    queryParams: ['sortOrder', 'sortField', 'search', 'page'],
    domain: null,
    searchObject: null,
    sortAscending: null,
    sortField: 'id',
    sortOrder: 'desc',
    page: 1,
    // This will also define the properties IdSearch...
    setupSearchVariables: function setupSearchVariables() {
      var mySearch = _searchObject.default.create().fromJSON(this.get('search'));
      this.set('searchObject', mySearch);
      this.set('sortAscending', this.get('sortOrder') === 'asc');
    },
    reload: function reload() {
      this.get('route').refresh();
    },
    _whenObjectIsCreated: function _whenObjectIsCreated(cb) {
      if (cb) {
        cb();
      }
      this.reload();
    },
    actions: {
      // reload: function() {
      //   this.reload();
      // },
      objectCreated: function objectCreated(_object, cb) {
        this._whenObjectIsCreated(cb);
      },
      updateRoute: function updateRoute(queryParams) {
        // Search is not passed and modified in the component, so we need to set it here
        // it'd be cleaner to not mutate the passed variables and reset it all here.
        this.set('search', queryParams.search);
        this.transitionToRoute({
          queryParams: queryParams
        });
      }
    }
  });
  _exports.default = _default;
});