define("label_provider_app/components/my-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    content: [],
    selectedValue: null,
    name: '',
    action: function action() {
      return undefined;
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      if (!this.get('selectedValue') && this.get('content.firstObject')) {
        var value = this.get('content.firstObject').value;
        this.set('selectedValue', value);
        this.action(value);
      }
    },
    actions: {
      change: function change(value) {
        this.set('selectedValue', value);
        this.action(value);
      }
    }
  });
  _exports.default = _default;
});