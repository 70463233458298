define("label_provider_app/components/date-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['js-date-display'],
    formatDate: Ember.inject.service(),
    date: null,
    // See http://momentjs.com/ for docs on the possible formats
    dateFormat: 'llll',
    inputLike: null,
    formattedDate: function () {
      var date = this.get('date');
      return this.get('formatDate').format(date, this.get('dateFormat'));
    }.property('date', 'dateFormat')
  });
  _exports.default = _default;
});