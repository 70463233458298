define("label_provider_app/components/date-time-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      updateDate: function updateDate(rawValue) {
        if (this.get('updateDate')) {
          this.get('updateDate')(rawValue);
        }
      }
    }
  });
  _exports.default = _default;
});