define("label_provider_app/routes/label-credentials", ["exports", "label_provider_app/routes/pagination-base"], function (_exports, _paginationBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _paginationBase.default.extend({
    init: function init() {
      this._super('labelCredential');
    }
  });
  _exports.default = _default;
});