define("label_provider_app/templates/components/classic-modal-popin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/2OtKNyU",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"modal-popin\",null,[[\"title\",\"createAction\",\"cancelAction\",\"renderedAction\",\"cancelText\",\"cancelJsClass\",\"createJsClass\",\"performBtn\",\"createButtonText\",\"createActionDisabled\",\"modalWrapperType\",\"forcedWidth\",\"class\"],[[23,[\"title\"]],[27,\"and\",[[23,[\"createAction\"]],[27,\"action\",[[22,0,[]],\"performAction\"],null]],null],[27,\"and\",[[23,[\"cancelAction\"]],[27,\"action\",[[22,0,[]],\"performCancel\"],null]],null],[23,[\"renderedAction\"]],[23,[\"cancelText\"]],\"js-create-cancel-button\",\"js-create-create-button\",\"save\",[23,[\"createButtonText\"]],[23,[\"createActionDisabled\"]],\"classic\",[23,[\"forcedWidth\"]],[23,[\"class\"]]]],{\"statements\":[[4,\"if\",[[23,[\"consequencesKey\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[9],[1,[27,\"t\",[[23,[\"consequencesKey\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/classic-modal-popin.hbs"
    }
  });
  _exports.default = _default;
});