define("label_provider_app/mixins/model-fields", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    modelFields: function () {
      return _lodash.default.filter(this.get('tableFields'), function (field) {
        return field.isEditable;
      });
    }.property('tableFields'),
    creationFields: function () {
      return _lodash.default.filter(this.get('tableFields'), function (field) {
        return field.isEditable || field.presentForCreation;
      });
    }.property('tableFields')
  });
  _exports.default = _default;
});