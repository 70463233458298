define("label_provider_app/models/search-object", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Object.extend({
    values: {},
    joins: {},
    _buildKey: function _buildKey(field, modifier) {
      return field.underscore() + '__' + modifier;
    },
    _splitKey: function _splitKey(key) {
      var splitted = key.split('__');
      return {
        mod: splitted.pop(),
        field: splitted.join('__').camelize()
      };
    },
    _buildTableName: function _buildTableName(table) {
      return table.underscore();
    },
    _extractTableName: function _extractTableName(table) {
      return table.camelize();
    },
    _wrap: function _wrap(val) {
      if (val === undefined || val.push) {
        return val;
      }
      return [val];
    },
    // Return undefined or an list of values
    getSearchValues: function getSearchValues(field, modifier) {
      return this._wrap(this.get('values')[this._buildKey(field, modifier)]);
    },
    // Return undefined or an list of values
    getJoinSearchValues: function getJoinSearchValues(table, field, modifier) {
      var tableName = this._buildTableName(table);
      var key = this._buildKey(field, modifier);
      if (this.get('joins')[tableName]) {
        return this._wrap(this.get('joins')[tableName][key]);
      }
    },
    updateSearch: function updateSearch(field, modifier, value) {
      var key = this._buildKey(field, modifier);
      if (value === null) {
        this.set('values', _lodash.default.omit(this.get('values'), key));
      } else {
        this._addValueToKey(this.get('values'), key, value);
      }
    },
    removeSearch: function removeSearch(field, modifier, value) {
      var _this = this;
      var key = this._buildKey(field, modifier);
      this._removeKey(this.get('values'), key, value, function () {
        _this.updateSearch(field, modifier, null);
      });
    },
    updateJoinSearch: function updateJoinSearch(table, field, modifier, value) {
      var key = this._buildKey(field, modifier);
      var tableName = this._buildTableName(table);
      var joins = this.get('joins');
      if (!joins[tableName]) {
        joins[tableName] = {};
      }
      if (value === null) {
        joins[tableName] = _lodash.default.omit(joins[tableName], key);
        if (_lodash.default.isEmpty(joins[tableName])) {
          this.set('joins', _lodash.default.omit(joins, tableName));
        }
      } else {
        this._addValueToKey(joins[tableName], key, value);
      }
    },
    removeJoinSearch: function removeJoinSearch(table, field, modifier, value) {
      var _this2 = this;
      var key = this._buildKey(field, modifier);
      var tableName = this._buildTableName(table);
      var joins = this.get('joins');
      this._removeKey(joins[tableName], key, value, function () {
        _this2.updateJoinSearch(table, field, modifier, null);
      });
    },
    _removeKey: function _removeKey(hash, key, value, removeCallback) {
      if (!hash) {
        return;
      }
      if (hash[key] === undefined) {
        return;
      }
      if (hash[key] === value) {
        removeCallback.apply(this);
        return;
      }
      if (hash[key].push) {
        hash[key] = _lodash.default.reject(hash[key], function (val) {
          return val === value;
        });
      }
      if (hash[key].length === 0) {
        removeCallback.apply(this);
      }
    },
    _addValueToKey: function _addValueToKey(hash, key, value) {
      if (hash[key] === undefined) {
        hash[key] = [];
      }
      if (!hash[key].push) {
        hash[key] = [hash[key]];
      }
      hash[key].push(value);
    },
    searchedFields: function () {
      return this._extractFields(this.get('values'));
    }.property('values'),
    takenOptions: function takenOptions(field) {
      return this._extractOptions(this.get('values'), field);
    },
    joinTables: function () {
      var that = this;
      return _lodash.default.map(_lodash.default.keys(this.get('joins')), function (tableName) {
        return that._extractTableName(tableName);
      });
    }.property('joins'),
    joinTakenOptions: function joinTakenOptions(table, field) {
      return this._extractOptions(this.get('joins')[this._buildTableName(table)], field);
    },
    joinSearchedFields: function joinSearchedFields(table) {
      return this._extractFields(this.get('joins')[this._buildTableName(table)]);
    },
    _extractOptions: function _extractOptions(hash, field) {
      var _this3 = this;
      var res = [];
      _lodash.default.forOwn(hash, function (_val, key) {
        var splitted = _this3._splitKey(key);
        if (splitted.field === field) {
          res.push(splitted.mod);
        }
      });
      return res;
    },
    _extractFields: function _extractFields(hash) {
      var that = this;
      return _lodash.default.uniq(_lodash.default.map(_lodash.default.keys(hash), function (k) {
        return that._splitKey(k).field;
      }));
    },
    toJSON: function toJSON() {
      return JSON.stringify(this.toSearch());
    },
    fromJSON: function fromJSON(search) {
      return this.fromSearch(JSON.parse(search || '{}'));
    },
    toSearch: function toSearch() {
      var newVals = _lodash.default.cloneDeep(this.get('values'));
      if (!_lodash.default.isEmpty(this.get('joins'))) {
        newVals['joins'] = this.get('joins');
      }
      return newVals;
      // return _.cloneDeep(this.get('values'));
    },

    fromSearch: function fromSearch(search) {
      search = search || {};
      this.set('values', _lodash.default.omit(search, 'joins'));
      this.set('joins', search['joins'] || {});
      return this;
    }
  });
  _exports.default = _default;
});