define("label_provider_app/controllers/label-offer", ["exports", "label_provider_app/controllers/common-controller"], function (_exports, _commonController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _commonController.default.extend({
    i18n: Ember.inject.service(),
    inactiveInfoMessage: function () {
      return this.get('i18n').t('labelOffer.info.inactive');
    }.property('i18n', 'model.active')
  });
  _exports.default = _default;
});