define("label_provider_app/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // jscs:disable maximumLineLength
  var _default = {
    address: {
      fields: {
        apartmentNumber: 'Apartment number',
        atLeastOneStreet: 'At least one street field',
        building: 'Building',
        city: 'City',
        civility: 'Civility',
        companyName: 'Company name',
        computedName: 'Name',
        country: 'Country code',
        email: 'Email',
        firstname: 'Firstname',
        fullname: 'Fullname',
        instructions: 'Instructions',
        lastname: 'Lastname',
        phone1: 'Phone 1',
        phone2: 'Phone 2',
        placeName: 'Place name',
        state: 'State',
        street1: 'Street 1',
        street2: 'Street 2',
        street3: 'Street 3',
        street4: 'Street 4',
        zip: 'Zip'
      },
      name: 'Address'
    },
    addressLabelConfig: {
      chronopost: 'Chronopost',
      colisPrive: 'Colis privé',
      colissimo: 'Colissimo',
      correos: 'Correos',
      courrierSuivi: 'Courrier Suivi',
      createReturnAddress: 'Create a return address',
      createSenderAddress: 'Create a sender address',
      creator: 'Add a new address label account',
      delivengo: 'Delivengo',
      dpd: 'DPD',
      dpdByApi: 'DPD by API',
      envoiMoinsCher: 'Envoi Moins Cher',
      explanation: 'Create a new address label account - WARNING not all services are actually supported',
      fields: {
        labelCredential: 'Account credentials',
        labelOfferSubscription: 'MKP subscription',
        masterLabelCredential: 'Mkp credentials',
        name: 'Name',
        service: 'Service'
      },
      gls: 'Gls',
      hermes: 'Hermes UK',
      kuehne: 'Kuehne',
      laposte: 'Colissimo',
      lettreSimple: 'Lettre Simple',
      mondialRelay: 'Mondial Relay',
      returnAddress: 'Return address',
      returnAddressCreation: {
        consequences: 'This address will be used for all labels created for this address label configuration.'
      },
      sda: 'SDA',
      senderAddress: 'Sender address',
      senderAddressCreation: {
        consequences: 'This address will be used for all labels created for this address label configuration.'
      },
      storePickup: 'Pickup in store',
      tnt: 'TNT',
      ups: 'UPS'
    },
    addressLabelSummary: {
      details: 'Details',
      fields: {
        carrierName: 'carrier',
        country: 'receiver country',
        createdAt: 'generation date',
        details: '',
        id: 'id',
        merchantCountry: 'sender country',
        shippedAt: 'shipping date',
        shippingRef: 'tracking number',
        totalWeight: 'weight',
        zip: 'zip'
      },
      heading: 'Provided label {{id}}'
    },
    addressLabels: {
      alreadySentToCarrier: 'Already sent to carrier',
      canceledAddressLabel: 'Canceled label',
      deliverySlip: 'Delivery slip',
      disapproveAssociatedEntry: 'Cancel',
      disapproveAssociatedEntryConsequences: 'This label will not be included in the next packing slip.',
      notSendableToCarrier: 'Label cannot be canceled'
    },
    advancedSearch: {
      fields: {
        condition: 'Condition',
        field: 'Field',
        table: 'Relation',
        value: 'Value'
      },
      title: 'Advanced search'
    },
    apiClient: {
      fields: {
        apeNumber: 'APE number',
        city: 'city',
        companyForm: 'Forme juridique',
        companyName: 'company name',
        companyRegister: 'Registry of Trade and Companies / Trade Directory',
        country: 'country code',
        email: 'email',
        firstname: 'first name',
        frontEmail: 'client facing email',
        id: 'sbo id',
        lastname: 'last name',
        mobilePhone: 'mobile',
        nafNumber: 'NAF number',
        phoneNumber: 'landline',
        shareCapitalCents: 'Share capital (cents)',
        shareCapitalCurrency: 'Share capital (currency)',
        sirenNumber: 'SIREN',
        siret: 'SIRET',
        sourceRef: 'ref',
        state: 'state',
        street: 'street',
        vatNumber: 'VAT number',
        websiteUrl: 'Website URL',
        zip: 'zip code'
      },
      invoices: 'Invoices',
      updatePaymentInformation: 'Update payment information'
    },
    application: {
      by: 'by',
      copyright: '©',
      craftedWith: 'Crafted with',
      navigation: {
        toggleMenubar: 'Toggle menubar',
        toggleNav: 'Toggle navigation'
      },
      pageIsLoading: 'Page is loading',
      shippingbo: 'Shippingbo'
    },
    ariaLabels: {
      close: 'Close'
    },
    atomicFormula: {
      create: 'Add a condition',
      creator: 'Create a new atomic condition',
      explanation: 'Create an atomic formula',
      field: 'field',
      fields: {
        field: 'Field',
        operator: 'Operator',
        parentFormulaId: 'Id of the parent formula',
        type: 'Type',
        value: 'Value'
      },
      obliterate: 'Delete',
      obliteration: {
        consequences: 'This precondition will be gone forever.'
      },
      operator: 'operator',
      priority: 'priority',
      value: 'value'
    },
    button: {
      reload: 'Reload',
      save: 'Save'
    },
    cardboard: {
      creator: 'Add a cardboard box',
      explanation: 'Only Reference is require',
      fields: {
        height: 'Height (mm)',
        id: 'Id',
        length: 'Length (mm)',
        priceCents: 'Price (cents)',
        priceCurrency: 'Currency code',
        ref: 'Reference',
        stock: 'Stock',
        supplier: 'Supplier',
        weight: 'Weight (g)',
        width: 'Width (mm)'
      }
    },
    close: 'Close',
    cn23Editor: {
      packageCategory: 'Informations on the package',
      packageProduct: 'Product line {{index}}',
      packageProducts: 'Informations on the products',
      shippingPrice: 'Shipping price'
    },
    collectionTable: {
      aria: 'Table navigation'
    },
    columnSelector: {
      open: 'Columns',
      title: 'Configure columns'
    },
    companyIdentification: {
      administrativeFields: 'Administrative information',
      associationArticles: 'Articles of association',
      bankStatement: 'Company bank information (proof of IBAN)',
      companyMembers: 'Company members',
      genericDocType: 'Company document',
      heading: 'Company identity information',
      incorporationCertificate: 'Certifcate of incorporation',
      shareCapitalDistribution: 'Share capital distribution'
    },
    companyIdentificationDocument: {
      iban: 'IBAN'
    },
    companyMember: {
      creator: 'Add a company member',
      explanation: 'Please choose the type of member you would like to add',
      fields: {
        displayableType: 'Member type',
        email: 'Email',
        firstname: 'First name',
        lastname: 'Last name',
        type: 'Type'
      },
      genericType: 'Company member',
      identityCard: 'ID card',
      identityInformation: '{{type}} {{id}}: general information',
      passport: 'Passport',
      types: {
        legalRepresentative: 'Legal representative',
        shareholder: 'Shareholder'
      }
    },
    companyMemberDocument: {
      fields: {
        country: 'Country'
      }
    },
    config: {
      basicMkpConfig: {
        agree: 'Accept EULA',
        downloadAgreement: 'Download EULA',
        heading: 'Shippingbo marketplace client',
        userAgreementAccepted: 'User agreement accepted'
      },
      invoices: {
        additionalLanguages: 'Additional languages',
        autoEmail: 'Automated email',
        ccEmail: 'CC email',
        emailBody: 'Email body',
        heading: 'Invoices configuration',
        legalText: 'Legal text',
        logoUrl: 'Invoice logo (Height: 100px, Max-width 360px)',
        otherFeesTaxRate: 'Default tax rate on other fees',
        productTaxRate: 'Default product tax rate',
        shippingTaxRate: 'Default shipping tax rate',
        startingInvoiceRef: 'First invoice number',
        wrapTaxRate: 'Default wrap tax rate'
      },
      logistic: {
        advancedInsurance: 'Default advanced insurance values',
        autoClickPrintButton: 'Automatically click print button when package is ready',
        autoFillAddress: 'Automatic completion of addresses',
        autoFillPackage: 'Automatic completion of packages information',
        autoPrintEnabled: 'Enable auto printing of address labels',
        defaultEmail: 'Email used in autocompletion',
        defaultHsCode: 'Default HS Code',
        defaultPackageDescription: 'Description used to send package in Europe',
        defaultPhone: 'Phone used in autocompletion',
        defaultScanningField: 'Preselected field when scanning packing slip barcode',
        defaultUnitWeight: 'Unit weight used in autocompletion',
        heading: 'Logistics configuration',
        minValueForInsurance: 'Minimal value for insurance in labels (in cents of {{curr}})',
        subHeading: 'Logistics configuration',
        superSimpleCn23: 'Super simple CN23',
        warning: {
          addressCompletion: 'Auto-completion will apply to all addresses for which the information is missing.',
          packageCompletion: 'Auto-completion will apply to all packages whose total weight cannot be resolved.'
        }
      },
      notif: {
        email: 'Email address',
        enabled: 'Enabled',
        explanation: 'Here you can configure receiving emails when a message is added to an order.',
        heading: 'Email notifications',
        language: 'Language',
        level: 'Minimal level for notification sending',
        subHeading: 'You can configure email notifications here'
      },
      orderToCarrierMappingRuleBook: {
        defaultCarrier: 'Default carrier',
        heading: 'Default carrier',
        rules: 'Order to carrier mapping rules'
      },
      pack: {
        heading: 'Packs',
        packField: 'Field used to determine packs',
        subHeading: 'You can configure packs here'
      },
      packingSlip: {
        banner: 'Banner (PNG, width: 820px max.)',
        checkboxEnabled: 'Checkbox',
        clientEmailEnabled: 'Client email',
        columns: 'Enable / disable options on the packing slip',
        customOrderLogo: 'Custom origin logo (PNG, width: 70px, height: 33px)',
        customerHistoryEnabled: 'Customer history',
        footer: 'Footer (PNG, width: 820px max.)',
        header: 'Header (PNG, width: 360px max.)',
        heading: 'Customize the packing slips',
        locationEnabled: 'Location',
        misc: 'Misc configurations',
        pictureEnabled: 'Picture',
        pricesEnabled: 'Prix commande',
        productBarcodeEnabled: 'Product barcode',
        productStockEnabled: 'Product current stock',
        skuEnabled: 'Sku',
        subHeading: 'Customize the packing slips',
        titleEnabled: 'Title',
        useProductCountInOrdering: 'Sort by product count'
      },
      stock: {
        decrementOnOrder: 'Decrement stock for new orders',
        heading: 'Stocks',
        subHeading: 'You can configure stocks here'
      }
    },
    configs: {
      apiClient: 'Client account',
      basicMkpConfig: 'Marketplace client',
      cardboards: 'Cardboards',
      clientInformation: {
        necessaryFields: 'Required information',
        optionalFields: 'Optional information',
        subscription: 'Your subscription'
      },
      companyIdentification: 'Identification information',
      invoicesConfig: 'Invoices configuration',
      label: 'Address label accounts',
      labelProvider: 'Vendor account',
      labelProviderInformation: {
        necessaryFields: 'Required information',
        optionalFields: 'Optional information'
      },
      logisticConfig: 'Logistics',
      notif: 'Notifications configuration',
      orderToCarrierMappingRuleBook: 'Order to carrier mapping rules',
      pack: 'Packs configuration',
      packingSlipConfig: 'Packing slip configuration',
      returnLabel: 'Return label accounts',
      stock: 'Stocks configuration'
    },
    customOrderLogo: {
      creator: 'Add a new custom logo',
      explanation: "The origin value will be compared to the order's origin value",
      fields: {
        originValue: 'Origin value'
      }
    },
    dashboard: {
      dailyNumbers: "Today's numbers",
      orderNumber: 'Orders status overview over the last {{period}} days',
      orderState: {
        fresh: {
          sentenceEnding: 'received'
        },
        inPreparation: {
          sentenceEnding: 'not prepared'
        },
        preparing: {
          sentenceEnding: 'in preparation'
        },
        shipped: {
          sentenceEnding: 'shipped'
        },
        toBePrepared: {
          sentenceEnding: 'to prepare'
        },
        waiting: {
          sentenceEnding: 'to be prepared'
        }
      }
    },
    date: {
      format: {
        long: 'dddd Do MMMM YYYY',
        short: 'DD/MM/YYYY'
      },
      weekDate: {
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
        7: 'Sunday'
      }
    },
    destroyObject: {
      consequences: 'Do you really want to destroy this entry?',
      title: 'Delete'
    },
    history: {
      after: 'After',
      before: 'Before',
      blame: 'Done by',
      blameOrder: 'Order {{id}}',
      date: 'Date',
      field: 'Field',
      title: 'Changes history'
    },
    insuranceEditor: {
      insuranceValue: 'Insured value'
    },
    invoices: {
      labels: 'Labels',
      returnLabels: 'Return labels',
      title: 'Invoices'
    },
    labelConfigTemplate: {
      creator: 'Default label config',
      explanation: 'Add a default label config. It will be automatically deployed when subscribing to this offer.',
      fields: {
        name: 'Name',
        service: 'Service'
      }
    },
    labelConfigTemplates: {
      title: 'Default label configs'
    },
    labelCredential: {
      configNotVisibleExplanation: 'You cannot configure label credential as it is associated with a provided one.',
      configure: 'Configure associated account - WARNING: may be shared',
      creation: {
        explanation: 'You will be able to specify credentials afterwards, by going to the credential page.'
      },
      creator: 'Create carrier credentials',
      details: {
        config: 'Configure carrier credentials {{baseType}}: {{id}} ({{name}})',
        main: 'Carrier credentials {{baseType}}: {{id}} ({{name}})'
      },
      fields: {
        baseType: 'Carrier',
        createdAt: 'Created at',
        details: '',
        id: 'Id',
        name: 'Name',
        providedLabelCredentialId: 'Provided credential  id',
        providedLabelCredentialName: 'Provided credential name',
        ready: 'Ready',
        type: 'Carrier'
      }
    },
    labelOffer: {
      configuration: 'Configuration',
      creator: 'Create a new label offer',
      credential: 'Label credential',
      explanation: 'You will be able to upload the label offer details later on',
      fields: {
        createdAt: 'created at',
        details: '',
        displayableType: 'offer type',
        id: 'id',
        labelCredential: 'label credential',
        labelCredentialName: 'label credential',
        name: 'name',
        providedLabelOfferId: 'provided label offer',
        providedLabelOfferName: 'provided label offer',
        receiverCountry: 'receiver countries (FR, EU...)',
        senderCountry: 'sender countries (FR, EU...)',
        type: 'offer type'
      },
      heading: 'Offer {{id}}: {{name}}',
      info: {
        inactive: 'This offer has not been activated yet. Once activated, you will be able to unactivate it. Unactivating will prevent any new subscription. Existing subscribers will still be able to use it.'
      },
      labelOfferOptions: 'Options',
      showPrices: 'Show prices',
      types: {
        basic: 'Basic',
        credentialResale: 'Credential resale',
        firstMile: 'Hub'
      }
    },
    labelOfferOption: {
      creator: 'Add a new option',
      explanation: 'Choose a service',
      fields: {
        archived: 'archive',
        insuranceEnabled: 'insurance enabled',
        onlyUseIfZipCodeAvailable: 'use only if zip available for offer',
        providedLabelOfferOptionId: 'linked option',
        providedLabelOfferOptionName: 'linked option',
        receiverCountry: 'destinations (FR, EU...)',
        service: 'service',
        zipPrefix: 'zip prefix'
      },
      heading: 'Option {{id}}: {{service}}'
    },
    labelOfferSubscription: {
      creator: 'Subscribe to a transport offer',
      explanation: 'Please select an offer',
      fields: {
        labelOffer: 'Offer'
      }
    },
    labelPricingTable: {
      details: 'State',
      heading: 'Pricing table for option {{optionId}}',
      id: 'id',
      mappings: 'Pricing table',
      processingStatus: 'processing'
    },
    labelPrinter: {
      addressInvalid: 'The shipping address has errors. Please check them.',
      associatedConfig: {
        noLongerReady: 'The associated address label config is no longer ready for printing. You can release the package by clicking Unselect.'
      },
      checkAddress: 'Check shipping address before printing',
      checkPackage: 'Check package details before printing',
      checkPickUpLocationRef: 'Check pick up location reference',
      chooseConfig: 'Choose the carrier for this package',
      cn23Editor: 'Edit information for CN23 generation',
      dimensionsEditor: 'Edit package dimensions',
      emcOperator: 'Choose an operator and a service level',
      errors: {
        label: 'Error generating the label, please check the address, then the label configuration.',
        timeOut: 'The label generation has timed out',
        timeOutWithRef: 'Generation of label {{ref}} has timed out',
        validation: 'Error notifying the application that you printed the label. Check the error messages.'
      },
      generationInProgress: 'Please wait while the label is being generated',
      insuranceEditor: 'Edit information for insurance, if you would like to insure the shipment at all.',
      labelMessages: 'Important information from the carrier',
      noReadyConfig: 'No address label config ready for printing.',
      packageDescription: 'Package description',
      packageInvalid: 'Package information have errors. Please check them.',
      partsCount: 'Please specify the number of packages on this pallet',
      pickUpLocationRef: 'Pick up location reference',
      productsPriceEditor: 'Edit information on the package declared value',
      requestedShippingDate: 'Please select a loading date',
      unselect: 'Unselect',
      weightRequired: 'Weight needs to be filled in to generate label'
    },
    labelProvider: {
      fields: {
        apeNumber: 'APE number',
        city: 'City',
        companyForm: 'Forme juridique',
        companyName: 'Company name',
        country: 'Country code',
        email: 'Email',
        firstname: 'First name',
        frontEmail: 'Client facing email',
        lastname: 'Last name',
        mobilePhone: 'Mobile phone',
        nafNumber: 'NAF number',
        phoneNumber: 'Phone number',
        shareCapitalCents: 'Share capital (cents)',
        shareCapitalCurrency: 'Share capital (currency)',
        sirenNumber: 'SIREN',
        siret: 'SIRET',
        street: 'Street',
        vatNumber: 'VAT number',
        websiteUrl: 'Website URL',
        zip: 'Zip code'
      },
      invoices: 'Invoices',
      updatePaymentInformation: 'Update payment information'
    },
    language: {
      english: 'English',
      french: 'French'
    },
    lemonBankAccount: {
      creator: 'Add bank account',
      explanation: 'Withdrawals target bank account',
      fields: {
        bankBranchCity: 'bank branch city',
        bankBranchStreet: 'bank branch street',
        bankName: 'bank name',
        bic: 'bic',
        country: 'country',
        holderFullname: 'holder fullname',
        iban: 'iban'
      },
      iban: 'IBAN',
      lemonRef: 'Lemon ref',
      waitingForLemonRegistration: 'Waiting for validation'
    },
    level: {
      error: 'Error',
      info: 'Info'
    },
    logisticConfig: {
      fields: {
        closing: 'Closing',
        fixing: 'Fixing',
        material: 'Material',
        protection: 'Internal protection',
        wrap: 'Wrap'
      }
    },
    main: {
      activate: 'Activate',
      add: 'Add',
      addressLabelSummaries: 'Provided labels',
      areYouSure: 'Are you sure?',
      cancel: 'Cancel',
      cancelDangerousAction: 'Nevermind',
      cardboards: 'Cardboards',
      config: 'Configure',
      configs: 'Configuration',
      copy: 'Copy',
      create: 'Create',
      createOrder: 'Create order',
      dashboard: 'Dashboard',
      deactivate: 'Deactivate',
      delete: 'Delete',
      doDangerousAction: 'I am sure',
      download: 'Download',
      edit: 'Edit',
      false: 'No',
      fillInTracking: 'Tracking number',
      finish: 'Finish',
      generateLabel: 'Generate label',
      generating: 'Generating...',
      impersonate: 'Seize control',
      labelCredentials: 'Carrier credentials',
      labelOffers: 'Label offers',
      leave: 'Leave',
      loading: 'Loading...',
      logout: 'Logout',
      monitor: 'Monitor',
      navigation: 'Navigation',
      next: 'Next',
      null: '',
      order: 'Order',
      orderItemProductMappings: 'Mappings',
      orders: 'Orders',
      preparationRuns: 'Logistics',
      preparationSearches: 'Prepare from saved searches',
      prepare: 'Prepare',
      previous: 'Previous',
      print: 'Print',
      productPacks: 'Packs',
      products: 'Products',
      providedClients: 'Clients',
      refresh: 'Refresh',
      reportConfigs: 'Reports',
      reset: 'Reinitialize filters',
      save: 'Save',
      saveSearch: 'Save search',
      search: 'Search',
      serviceCredentials: 'External services',
      topbar: {
        documentation: 'Documentation',
        linksupport: 'Contact Support'
      },
      true: 'Yes',
      uploads: 'Upload',
      validate: 'Validate',
      wallet: 'ShippingBo Wallet'
    },
    mappedProduct: {
      fields: {
        location: 'Location',
        price: 'Price (Tax included)',
        quantity: 'Quantity',
        sku: 'SKU',
        title: 'Title'
      }
    },
    mapping: {
      addField: 'Add a field',
      key: 'Key',
      knownFieldName: {
        state: 'State on Shippingbo',
        updaterCarrier: 'Closing carrier',
        updaterCarrierName: 'Closing state from carrier',
        updaterState: 'Closing state'
      },
      name: 'Mappings',
      value: 'Value,'
    },
    mappingRule: {
      create: 'Create a new mapping rule',
      creator: 'Create a new mapping rule',
      enabled: 'Enabled',
      explanation: 'Please confirm you would like to create a new rule',
      fields: {
        name: 'Rule #{{priority}} => {{name}}',
        operator: 'Main operator',
        ruleBookId: 'Rule book id',
        sourceType: 'Mapping a(n)',
        targetId: 'Target',
        targetType: 'to a(n)'
      },
      obliterate: 'Delete mapping rule',
      obliteration: {
        consequences: 'The mapping rule will be deleted.'
      },
      operator: {
        meaning: {
          and: 'If the following conditions are all fulfilled'
        }
      },
      priority: 'priority',
      targetType: {
        carrier: 'Assign the following carrier'
      }
    },
    message: {
      markAsRead: 'Acknowledge'
    },
    mkpBuyer: {
      labelOfferSubscriptions: 'Your transport offer subscriptions'
    },
    mondialRelay: {
      button: 'Choose a Mondial-Relay pickup point'
    },
    nA: 'N/A',
    navettePickUp: {
      beginningHoliday: 'Starting holiday date',
      choosePudo: 'Choose a pick-up point',
      configurationTitle: 'Advanced search',
      endingHoliday: 'Ending holiday date',
      holiday: 'Holiday',
      openingHours: 'Opening Hours',
      pudoDetails: 'Pick up point details',
      pudoField: {
        address1: 'Address',
        address2: 'Address(2)',
        address3: 'Address(3)',
        available: 'Available',
        city: 'City',
        countryCode: 'Country Code',
        handicapes: 'Disabled Access',
        id: 'Id',
        name: 'Name',
        parking: 'parking',
        zipCode: 'Zip'
      },
      pudoId: 'Current pick up point id',
      pudoSearchResult: 'Result for the current address {{street}}',
      title: 'Pick-up point choice'
    },
    order: {
      create: {
        confirmContent: 'The order will not be editable once saved',
        confirmTitle: 'Order confirmation',
        details: 'Details',
        discountPrice: 'Discount',
        duplicateBilling: 'Duplicate billing address',
        duplicateShipping: 'Duplicate shipping address',
        hidePreview: 'Hide preview',
        itemPriceTaxIncludedCents: 'Price (tax included, cents)',
        noAddress: 'None',
        orderSummary: 'Order summary',
        otherFees: 'Other fees',
        shippingPrice: 'Shipping price',
        showPreview: 'Show preview',
        title: 'Create order',
        wrapPrice: 'Wrapping price'
      },
      details: {
        deliveredAtDate: 'Delivery date',
        deliveryDate: 'Requested delivery date',
        discount: 'Discount',
        displayCurrencyLeft: 'true',
        estimatedAfter: 'After',
        estimatedAnd: 'and',
        estimatedBefore: 'Before',
        estimatedBetween: 'Between',
        missingAddress: 'Unknown address',
        noMappedCarrier: 'None',
        otherFees: 'Other fees',
        price: 'Price',
        priceDecimalSeparator: '.',
        priceHundredsSeparator: ',',
        priceTTC: 'Tax included',
        priceTaxIncluded: 'Tax included',
        priceTaxIncludedCents: 'Tax included (cents)',
        productsPrice: 'Products',
        recompute: {
          and: 'and',
          matchingFields: 'The first matching field will be used.',
          warning: 'Order items are matched by {{list}}, in this order.'
        },
        shippedAtDate: 'Shipping date',
        shippingDate: 'Requested shipping date',
        shippingPrice: 'Shipping',
        totalPrice: 'Total',
        unknownCurrency: 'Unknown currency',
        wrapPrice: 'Wrap'
      },
      errors: {
        cannotBeRemovedFromRun: 'Failed to remove from the preparation run',
        failedToRecomputeMappedProducts: 'Failed to recompute mapped products',
        failedToRegenerateInvoice: 'Failed to regenerate an invoice'
      },
      fields: {
        allLinesAreMapped: 'All lines are mapped',
        billingAddressId: 'Billing address',
        chosenDeliveryService: 'Carrier code',
        clientRef: 'Client reference',
        customState: 'Custom state',
        details: '',
        earliestDeliveryAt: 'Delivery after',
        earliestShippedAt: 'Shipping after',
        fulfilledByMarketplace: 'Fulfilled by MKP',
        id: 'Id',
        invoiceRef: 'Invoice ref.',
        latestDeliveryAt: 'Delivery before',
        latestShippedAt: 'Shipping before',
        origin: 'Origin',
        originCreatedAt: 'Order date',
        originRef: 'Original reference',
        packageId: 'Package id',
        preparationRunId: 'Order run id',
        productCount: 'Product count',
        productsOtherRef1: 'Products Other ref1',
        productsOtherRef2: 'Products Other ref2',
        productsOtherRef3: 'Products Other ref3',
        relayRef: 'Pick up location reference',
        shippedAt: 'Shipping date',
        shippingCountry: 'Shipping country',
        shippingRequiresCn23: 'Requires CN23',
        shippingToDomTom: 'Shipping to DOM TOM',
        shippingToEu: 'Shipping within UE',
        shippingZip: 'Shipping zip code',
        source: 'Source',
        sourceRef: 'Source reference',
        state: 'State',
        tagValues: 'Tag list',
        totalDiscountTaxIncludedCents: 'Total discount tax included (cents)',
        totalItemPriceCents: 'Total item price (cents)',
        totalItemPriceTaxIncludedCents: 'Total item price tax included (cents)',
        totalItemTaxCents: 'Total item tax (cents)',
        totalMarketplaceFeesCents: 'Total marketplace fees (cents)',
        totalMarketplaceFeesTaxCents: 'Total marketplace tax (cents)',
        totalMarketplaceFeesTaxIncludedCents: 'Total marketplace tax included (cents)',
        totalOtherFeesCents: 'Total other fees (cents)',
        totalOtherFeesTaxCents: 'Total other fees tax (cents)',
        totalOtherFeesTaxIncludedCents: 'Total other fees tax included (cents)',
        totalPriceCents: 'Total price (cents)',
        totalPriceCurrency: 'Currency',
        totalShippingCents: 'Total shipping (cents)',
        totalShippingTaxCents: 'Total shipping tax (cents)',
        totalShippingTaxIncludedCents: 'Total shipping tax included (cents)',
        totalTaxCents: 'Total tax (cents)',
        totalWeight: 'Total weight (g)',
        totalWithoutTaxCents: 'Total without tax (cents)',
        totalWrapCents: 'Total wrap (cents)',
        totalWrapTaxCents: 'Total wrap tax (cents)',
        totalWrapTaxIncludedCents: 'Total wrap tax included (cents)'
      },
      recomputeMappedProducts: 'Recompute mapped products',
      regenerateInvoice: 'Regenerate an invoice',
      removeFromRun: {
        buttonTitle: 'Remove from run',
        explanation: 'This order will be definitely removed from this run.',
        title: 'Remove the order from the run'
      },
      state: {
        atPickupLocation: 'at pickup location',
        backFromClient: 'back from client',
        canceled: 'canceled',
        closed: 'delivered',
        handedToCarrier: 'handed to the carrier',
        inPreparation: 'in preparation',
        inTrouble: 'in trouble',
        merged: 'merged',
        null: '',
        partiallyShipped: 'partially shipped',
        rejected: 'rejected',
        sentToLogistics: 'sent to logistics',
        shipped: 'shipped',
        toBePrepared: 'to be prepared',
        waitingForPayment: 'waiting for payment',
        waitingForStock: 'waiting for stock'
      },
      success: {
        invoiceIsBeingGenerated: 'A new invoice is being generated',
        mappedProductsUpdated: 'Mapped products updated'
      }
    },
    orderAccepter: {
      fields: {
        enabled: 'Enabled'
      }
    },
    orderDocument: {
      creator: 'Return Label Generation',
      explanation: 'Edit email address for return label',
      fields: {
        email: 'Email'
      },
      reports: {
        package: 'Addresses'
      },
      shipment: {
        danger: 'Shipment notification failed',
        success: 'Shipment notification success'
      },
      types: {
        invoice: 'Invoice',
        packingSlip: 'Packing slip',
        returnLabel: 'Return label'
      }
    },
    orderEvent: {
      creator: 'Add a message',
      explanation: 'Add a message regarding this order',
      fields: {
        level: 'Level',
        message: 'Message',
        title: 'Title'
      }
    },
    orderFetcher: {
      fields: {
        fetcherEnabled: 'Enabled',
        lastUpdatedAtInApiFmt: 'Start date (UTC)'
      }
    },
    orderItem: {
      fields: {
        productRef: 'Seller reference',
        productSource: 'Product source',
        productSourceRef: 'Product source reference',
        title: 'Title'
      },
      name: 'Order line'
    },
    orderItemProductMapping: {
      creator: 'Add a mapping',
      explanation: 'You can create mappings to map items from the orders to your products',
      fields: {
        cdiscountPrice: 'Price on CDiscount (cents)',
        destroy: '',
        id: 'Id',
        matchedQuantity: 'Batch quantity',
        orderItemField: 'Order item field',
        orderItemSource: 'Order source',
        orderItemValue: 'Additional reference',
        packProductId: 'Id of the pack product',
        productField: 'Product field',
        productValue: 'Value for the product field',
        spartooPartnaireRef: 'Spartoo ref.'
      },
      title: 'Product references'
    },
    orderState: {
      change: {
        warning: 'WARNING: This state change will not be propagated to the source of the order. Are you sure that you want to proceed ?'
      }
    },
    orderTag: {
      creator: 'Add a tag',
      explanation: 'Add a tag on this order to be able to filter on it',
      fields: {
        value: 'tag'
      },
      name: 'Tags'
    },
    orderToAddressLabelConfigMapping: {
      fields: {
        addressLabelConfigId: 'Label account'
      },
      name: 'Mapped carrier'
    },
    orderUpdater: {
      fields: {
        inPreparationEnabled: 'Notify when in preparation',
        inTroubleEnabled: 'Notify when in trouble',
        shippedEnabled: 'Notify service of order shipping'
      }
    },
    orders: {
      details: {
        billingAddress: 'Billing address',
        details: 'Details',
        documents: 'Documents',
        genReturnLabel: 'Generate return label',
        mappedProducts: 'Products',
        orderEvents: 'Messages',
        printPs: 'Generate packing slip',
        sendToClogistique: 'Send to Clogistique',
        sendToFba: 'Send to FBA',
        sendToLogistics: {
          error: 'There was an error scheduling shipping',
          success: 'Order scheduled for shipping'
        },
        shipping: 'Shipping',
        shippingAddress: 'Shipping address',
        tags: 'Tags',
        title: 'Order {{id}}'
      },
      invoice: {
        printPs: 'Generate invoice'
      },
      shipments: {
        addressLabelUrl: 'Label',
        carrier: 'Carrier',
        generateReturnLabel: 'Generate Return Label',
        method: 'Method',
        otherDoc: 'Document',
        packageId: 'Id package',
        parcelStatus: 'Parcel status',
        pickingControlValidated: 'Picking',
        returnLabelGeneration: 'Generate Return Label',
        shippedAt: 'Shipped at',
        shippingRef: 'Shipping ref.',
        unknownUser: 'Unknown',
        user: 'User'
      }
    },
    packComponent: {
      creator: 'Add product in your pack',
      explanation: 'You can add product to your pack',
      fields: {
        componentProductId: 'Component product id',
        location: 'location',
        quantity: 'Quantity in a pack',
        title: 'Title',
        userRef: 'Reference (SKU)'
      },
      title: 'Ajouter dans le pack'
    },
    package: {
      createShipment: 'Save shipping information',
      errors: {
        failedSubpacksCreation: 'Failed to create subpackages'
      },
      failureHeader: 'Failure',
      failureMessage: "The shipping information couldn't be saved.",
      fields: {
        cardboard: 'Cardboard reference',
        closing: 'Closing',
        description: 'Description',
        fixing: 'Fixing',
        height: 'Height (mm)',
        insuranceValueCents: 'Insured value (cents)',
        insuranceValueCurrency: 'Insured value currency',
        length: 'Length (mm)',
        material: 'Material',
        packageCategory: 'Package information',
        packageProducts: 'Product lines',
        partsCount: 'Number of packages on this pallet',
        pickUpLocationRef: 'Pick-up point reference',
        productsPriceCents: 'Declared value (cents)',
        productsPriceCurrency: 'Declared value currency',
        protection: 'Internal protection',
        requestedShippingDate: 'Requested shipping date',
        shippingPriceCents: 'Shipping price value (cents)',
        shippingPriceCurrency: 'Shipping price currency',
        totalQuantity: 'Total quantity',
        totalWeight: 'Total weight (grams)',
        width: 'Width (mm)',
        wrap: 'Wrap'
      },
      fillInTracking: 'Fill in a tracking number for package {{id}}',
      generateLabel: 'Generate a label for the package {{id}}',
      searchMethods: 'Search for a shipping method:',
      seeOrder: 'See corresponding order',
      shippingRef: 'Tracking number',
      subPackage: 'Sub-package {{count}}',
      successHeader: 'Success',
      successMessage: 'Shipping information saved successfully.',
      totalPackages: 'Total packages needed',
      waitingForItsTurn: 'Package waiting for previous one(s) to be finished'
    },
    packageCategory: {
      fields: {
        importersContact: "Importer's contact",
        importersReference: "Importer's reference",
        officeOrigin: 'Origin office',
        value: 'Type of package'
      },
      value: {
        1: 'Gift',
        2: 'Sample',
        3: 'Sale',
        4: 'Document',
        5: 'Other',
        6: 'Return'
      }
    },
    packageCreator: {
      packageLabel: 'Generate label',
      packageTracking: 'Tracking number'
    },
    packageLabel: {
      fields: {
        carrier: 'Carrier',
        errorMessage: 'Error details',
        errorType: 'Error',
        packageId: 'Package id',
        shippingRef: 'Shipping ref',
        state: 'State'
      },
      fixMe: 'Fix the package',
      notifyShipment: 'Mark as shipped',
      orderId: 'Order id',
      ready: 'Generated',
      reschedule: 'Retry generating label',
      waiting: 'Waiting',
      withErrors: 'Has errors'
    },
    packageProduct: {
      creator: 'Add a product line to the CN23',
      explanation: 'You can here add a product line to the CN23 document',
      fields: {
        description: 'Description',
        hsCode: 'HS code',
        originCountry: 'Origin country',
        quantity: 'Quantity',
        unitValueCents: 'Unit price (cents)',
        unitValueCurrency: 'Currency',
        weight: 'Unit weight (g)'
      }
    },
    packingInfo: {
      creator: 'Add a packing information',
      explanation: 'This will be added to the product on the preparation slip',
      fields: {
        clientRef: 'Client reference',
        displayOrder: 'Display order',
        id: 'id',
        value: 'value'
      }
    },
    packingSlip: {
      carrier: 'Carrier',
      explainCarrier: 'By choosing a label account here, you force any label for this packing slip to be generated with this account. Do not choose a carrier if you want to keep this choice open.',
      shipPackages: 'Mark as shipped'
    },
    packingSlipConfig: {
      fields: {
        additionalSkuField: 'Additional sku field',
        originRefField: 'Field for order reference'
      }
    },
    pickUpLocationDisplay: {
      errors: {
        notImplemented: 'Warning. The selected service does not implement pick up locations.\n Despite this a pick up point reference ({{ref}}) was given.\n If you print a label with this service, the reference will be ignored.'
      },
      information: {
        noneGiven: 'No pick up location reference has been given.',
        notImplemented: 'The selected service does not support pick up locations.'
      }
    },
    pickingControl: {
      checkedQuantity: 'Already controlled',
      doneWithPackage: 'Done flashing products',
      inputLabel: 'SKU or EAN13',
      panelTitle: 'Picking verification of subpackage {{packIndex}}',
      pickingComplete: '',
      productsToPick: 'Products',
      quantity: 'Ordered quantity',
      skip: 'Skip control step',
      sku: 'SKU',
      title: 'Title',
      unknownValue: '{{value}} does not match any product'
    },
    product: {
      additionalReferences: {
        title: 'Additional product references (first 50)'
      },
      creator: 'New product',
      details: {
        detailTitle: 'Product information',
        packingInfos: 'Packing information',
        title: 'Product {{id}} {{title}}'
      },
      explanation: 'Reference is the only required field and for which uniqueness is enforced',
      explanationWithSource: 'Source identifies where the data is coming from, use "Manual" if you do not have a source for the products.<br/>Source reference is a unique identifier amoung this source, use the SKU by default.<br/>',
      name: 'Product',
      packComponents: {
        notAPack: 'This product is not a pack',
        title: 'Pack components'
      },
      packProduct: {
        title: 'This product belongs to this packs'
      },
      stockVariation: 'Add or remove stock'
    },
    productFetcher: {
      fields: {
        fetcherEnabled: 'Enabled'
      }
    },
    productsPriceEditor: {
      productsPrice: 'Declared value'
    },
    providedClient: {
      creation: {
        explanation: 'Please fill in client information:'
      },
      creator: 'Create a new client',
      details: {
        main: 'Client {{ref}} (sbo id: {{id}})'
      },
      fields: {
        city: 'city',
        companyName: 'company',
        country: 'country',
        details: '',
        email: 'email',
        firstname: 'first name',
        id: 'sbo id',
        lastname: 'last name',
        mobilePhone: 'mobile',
        phoneNumber: 'phone',
        sourceRef: 'ref',
        state: 'status',
        street: 'street',
        zip: 'zip'
      }
    },
    report: {
      creator: 'New export',
      explanation: 'Export data:',
      fields: {
        customEndDate: 'End',
        customStartDate: 'Start',
        dateBorderedSelected: '',
        requestedShippingDate: 'Requested shipping date'
      }
    },
    reportConfig: {
      config: {
        encoding: 'Encoding',
        encodingWarning: 'Warning regarding encoding: if the chosen encoding does not contain some of the original UTF-8 characters, they will be replaced by a white space',
        explanation: 'Configure general report properties (name, csv separator, encoding)',
        fieldExplanation: 'You can enable or disable the following options in the export:',
        fieldHeader: 'Fields configuration',
        forceQuotes: 'Double quote all fields in the output',
        header: 'Configuration',
        name: 'Name',
        separator: 'Separator',
        showHeader: 'Header line'
      },
      configure: 'Configure',
      customFields: {
        delete: 'Delete',
        deleteRcf: {
          error: {
            header: 'Error',
            messages: 'An unknown error occurred while deleting the custom rule'
          },
          success: {
            header: 'Success',
            messages: 'Custom rule successfully deleted'
          }
        },
        explanation: 'You can add and edit custom rules for reports.<br>They consist of additional columns being added in the report.<br>You can configure the header and the value',
        title: 'Custom fields'
      },
      dateBordered: {
        bordered: 'Select a date range',
        includeAll: 'Include all entries since the last export'
      },
      generate: 'Generate a new report',
      go: 'Reports',
      kuehne: {
        shipmentRequest: {
          generate: 'Request shipping'
        }
      },
      noTrigger: 'This report type cannot be manually triggered',
      notYetGenerated: 'Report has not yet been generated',
      options: {
        carrierNameEnabled: 'Label credential name',
        contractEnabled: 'Show contract information',
        fullSourceEnabled: 'Full source',
        labelOfferNameEnabled: 'Label offer name',
        pricesEnabled: 'Price'
      }
    },
    reportCustomField: {
      creator: 'Create custom field',
      explanation: 'Add custom fields to this export',
      fields: {
        field: 'Header',
        value: 'Value'
      }
    },
    returnLabelConfig: {
      back: 'Go back to previous page',
      fields: {
        name: 'name',
        readyToUse: 'Usage condition accepted',
        returnLabelCcEmail: 'cc email'
      },
      linkedConfigNotReady: 'This carrier has not be fully configured. Please contact customer support to have it done',
      notFullyConfigured: 'This configuration is incomplete, please fill in all fields before being able to use it.',
      title: 'Return label configuration {{name}}'
    },
    returnLabelGenerator: {
      chooseCarrier: {
        configure: 'Configure',
        create: 'Confirm',
        modalTitle: 'Check carrier before label generation',
        title: 'Choose a carrier'
      },
      generateLabel: {
        doIt: 'Generate return label',
        failure: 'Return label failed to generate:',
        title: 'Return informations'
      }
    },
    returnPackage: {
      fields: {
        buyerEmail: 'Client email',
        height: 'Height (mm)',
        length: 'Length (mm)',
        totalWeight: 'Weight (gr)',
        width: 'Width (mm)'
      }
    },
    save: {
      errors: 'Failure when saving the data',
      success: 'Operation success'
    },
    search: {
      additionalSearch: {
        required: 'You must enter a value'
      },
      creator: 'Search condition for {{field}}',
      modifiers: {
        blank: 'is empty:',
        contains: 'contains',
        // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
        ends_with: 'ends with',
        // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
        eq: '&#61;',
        gt: '&gt;',
        gteq: '&ge;',
        lt: '&lt;',
        lteq: '&le;',
        neq: '!=',
        nil: 'undefined:',
        // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
        starts_with: 'starts with',
        // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
        true: 'true:'
      },
      values: {
        false: 'no',
        true: 'yes'
      }
    },
    serviceCredential: {
      check: {
        failure: 'There seems to be an issue with your credentials. Please check them.',
        success: 'Looks like your credentials are properly set up.',
        tryAgain: 'We encountered an error while trying to check credentials. Please try again.'
      },
      checkCredentials: 'Check access to external service',
      config: {
        edit: 'Edit',
        failure: 'Saving failed. Please check your credentials first.',
        header: 'Follow our <a href="/tutorials/{{service}}.pdf" target="_blank">tutorial</a> to find out how to find your credentials.',
        save: 'Save',
        success: 'Success updating your access.',
        warnings: {
          ebay: {
            siteid: 'Siteid represents the country of your eBay account, default is ebay.fr (71).',
            token: 'Make sure that you click on the button to renew your token to authorize the application to access your data.'
          },
          fnac: {
            partnerCreds: 'You need to ask your account manager to give you API credentials linked to Facilecomm so that it works.'
          },
          prestashop: {
            mappings: 'We setup a default mapping for Prestashop states. If you need a custom mapping, please contact us.'
          },
          priceminister: {
            shipped: 'There is no shipped state for orders on Priceminister for now. Thus all orders will arrive in the "to be prepared" state. Make sure you filter out orders that you have already sent.'
          },
          wizishop: {
            states: "We only retrieve orders from a single Wizishop state. There is default setup for this state, if you need to change it, please contact us. If you change states on Wizishop, these changes won't be repercuted on Shippingbo order states. Thus make sure to filter out the orders that you have already send, or the ones that you cancel on Wizishop directly."
          }
        }
      },
      creator: 'Add a link to an external service.',
      doesNotImplementCredentialsChecking: 'Access to this service cannot be checked automatically.',
      explanation: 'Add a connection between Shippingbo and a market place or your website.',
      fields: {
        details: '',
        displayType: 'service',
        id: 'id',
        name: 'name',
        orderAccepter: 'Accept orders',
        orderDeliverer: 'Send orders',
        orderFetcher: 'Get orders',
        orderShipper: 'Ship order',
        productFetcher: 'Get Products',
        stockDeliverer: 'Send stock',
        stockDelivererShown: 'Send stock',
        stockFetcher: 'Get stock',
        type: 'service'
      },
      relayRef: {
        warning: 'Options for configuring realy integrations are limited to admins, please contact the support to do so.'
      }
    },
    serviceCredentials: {
      details: {
        config: 'API credentials',
        exchangeToken: 'Renew token',
        orderAccepter: {
          explanation: 'Enable / disable automated order acceptation.',
          title: 'Orders acceptation'
        },
        orderFetcher: {
          explanation: 'Enable / disable automated order import. You also need to set a date at which the order import is starting',
          title: 'Orders import'
        },
        orderUpdater: {
          explanation: 'Enable / disable automated repercution of state changes to the order on the external service',
          title: 'State update'
        },
        productFetcher: {
          explanation: 'Enable / disable automated product import',
          title: 'Products fetcher'
        },
        stockDeliverer: {
          explanation: 'Enable / disable stock updates on the external service',
          stockEntriesUrl: 'We cannot send the stock information to this service. Instead, they are regularly fetching this information from us. For this to happen, you must give this url to your account manager there: {{url}}',
          title: 'Stock update'
        }
      }
    },
    shipment: {
      fields: {
        carrierName: 'Carrier name',
        packageId: 'Package id',
        shippingRef: 'Shipping ref.'
      },
      name: 'Shipment'
    },
    stockVariation: {
      creator: 'Add or remove stock for this product',
      explanation: 'You can add stock with a positive number or remove stock with a negative number.',
      fields: {
        variation: 'Value of the stock variation (+/-)'
      },
      productKey: 'Key on which to match the product'
    },
    subscription: {
      cancel: 'Cancel subscription',
      destroy: {
        failed: 'Something went wrong while trying to terminate your subscription.',
        performed: 'Your subscription was successfully terminated.',
        scheduledFor: 'Your subscription will be terminated on'
      },
      destroyConsequences: 'This action will terminate your subscription.',
      fields: {
        state: 'State',
        terminationDate: 'Ends'
      },
      states: {
        active: 'Active',
        canceled: 'Canceled',
        failed: 'Initial payment failed',
        finishing: 'Cancelation requested',
        inactive: 'Inactive',
        pastDue: 'Payment late'
      }
    },
    symbol: {
      close: '×'
    },
    table: {
      currentOrdering: 'Ordered by',
      page: 'Page:',
      search: 'Search',
      sortOrder: {
        asc: 'asc.',
        desc: 'desc.'
      },
      totalRecords: 'Total records: {{total}}'
    },
    tracking: {
      collapsibleStatusTitle: 'Parcel {{id}} tracking',
      comment: 'Comment from carrier',
      parcelActualWeight: 'Parcel actual weight (g)',
      parcelRef: 'Parcel ref',
      parcelStatus: 'Status',
      status: {
        atCustoms: 'At customs',
        atPickUpPoint: 'At pick up point',
        beingProcessed: 'Processing',
        delayed: 'Delayed',
        delivered: 'Delivered',
        deliveryFailed: 'Delivery failed',
        deliveryInProgress: 'Delivery in progress',
        deliveryScheduled: 'Delivery scheduled',
        failedPickUp: 'Pick up failed',
        handedOver: 'Handed over to carrier',
        inTrouble: 'In trouble',
        pickedUp: 'Picked up',
        receiverActionRequired: 'Received action required',
        receiverInformed: 'Received informed',
        receiverNotificationFailed: 'Received notification failed',
        receiverNotified: 'Received notified',
        rejected: 'Rejected',
        requestedReceived: 'Request received',
        returnInProgress: 'Return in progress',
        returned: 'Returned',
        scannedIn: 'Scanned in',
        senderActionRequired: 'Sender action required',
        stopped: 'Stopped',
        toBePickedUpAtCarrierStore: 'To be picked up at carrier store',
        unknown: 'Unknown',
        unloaded: 'Unloaded'
      },
      statusChangedAt: 'Last change'
    },
    upload: {
      cdiscount: 'CDiscount payment',
      completeOipms: 'product: additional references',
      confirm: 'Do not close this tab before the import ended or it will be partially completed.',
      delimiter: 'Values separator',
      dpdShipment: 'DPD tracking',
      errors: 'Errors',
      exampleFile: 'Sample file',
      explanation: 'You can upload objects here from a CSV. It must include a header row specifying the columns.',
      file: 'File to import',
      fnac: 'Fnac payment',
      glsShipment: 'GLS tracking',
      inProgress: 'In Progress',
      modelName: 'Upload type',
      orderItemProductMapping: 'Order line / product mapping',
      packComponent: 'Pack',
      packingInfo: 'Packing information',
      payment: 'Payment',
      processRate: 'Imported: {{rate}}',
      product: 'Product',
      ref: 'Reference',
      shipment: 'Tracking information',
      stockVariation: 'Stock changes',
      success: 'Success',
      successRate: 'Success: {{rate}}',
      title: {
        completeOipms: 'product: additional references',
        orderItemProductMapping: 'Order line / product mapping',
        packComponent: 'Pack',
        packingInfo: 'Packing information',
        product: 'product',
        shipment: 'Tracking information',
        stockVariation: 'Stock changes',
        xlsUpload: {
          cdiscount: 'Cdiscount payments',
          fnac: 'FNAC payments'
        }
      },
      update: 'Update',
      updateWarning: 'The first column must be the primary key used for updating. Allowed primary keys are: {{keys}}.'
    },
    validator: {
      errors: {
        characterSet: '{{field}} can only contain the following characters: {{displayChars}}',
        delegateHasManyValidity: '{{field}}s have errors',
        delegateValidity: '{{field}} has errors',
        different: '{{value}} is not a permitted value for the field {{field}}',
        equal: '{{field}} must be equal to {{value}}',
        greaterOrEq: '{{field}} must be greater than (or equal to) {{value}}',
        greaterThanEq: '{{field}} must be greater than (or equal to) {{value}}',
        included: '{{field}} must take a value within: {{displayableList}}',
        isAnInt: '{{field}} must be an int',
        lengthEq: "{{field}}'s length must equal to {{value}}",
        lessThanEq: '{{field}} must be less than (or equal to) {{value}}',
        longerThanEq: "{{field}}'s length must be greater than (or equal to) {{value}}",
        matchesRegExp: '{{field}} does not have the expected format.',
        required: '{{field}} is required',
        shorterThanEq: "{{field}}'s length must be less than (or equal to) {{value}}",
        startsWith: '{{field}} must start by {{interpolate}}',
        stringOfAlphas: 'The {{field}} can only contain alphabetic letters',
        stringOfDigits: 'The {{field}} can only contain digits'
      },
      specialErrors: {
        configLogistic: {
          defaultPackageDescription: "The field: 'Description used to send package in Europe' in logistic must be completed"
        },
        courrierSuivi: {
          notAFrenchZip: 'The entered zip ({{zip}}) is not a valid French zip code. Please check it.'
        }
      }
    },
    wallet: {
      associatedBankAccount: 'Associated bank account',
      balance: 'Balance',
      potentialBalance: 'Virtual balance',
      status: 'Status',
      title: 'Shippingbo marketplace wallet',
      waitingForDocs: 'Waiting for documents, or for documents to be validated.',
      waitingForRegistration: 'Waiting for wallet registration.'
    },
    walletMoneyIn: {
      cancel: 'Payment canceled',
      creator: 'Add money to the wallet',
      error: 'Payment failed',
      explanation: 'Please select the payment method and the amount to credit on your wallet.',
      fields: {
        amountCents: 'Amount (cents)',
        amountCurrency: 'Currency',
        amountInUnits: 'Amount',
        type: 'Payment method'
      },
      id: 'Shippingbo payment reference',
      initialized: 'Initialized',
      pending: 'Pending',
      success: 'Successful payment!',
      types: {
        creditCard: 'Credit card'
      },
      unknownState: 'Unknown payment state'
    },
    walletMoneyOut: {
      buttonKey: 'Withdraw',
      creator: 'Withdraw money from wallet',
      explanation: 'Money will be withdrawn from wallet and transfered to its associated bank account.',
      fields: {
        amountCents: 'Amount (cents)',
        amountCurrency: 'Currency',
        amountInUnits: 'Amount'
      }
    },
    weightToPriceMapping: {
      maxWeight: 'Max weight (g)',
      minWeight: 'Min weight (g)',
      priceCents: 'Price cents (EUR)'
    }
  };
  _exports.default = _default;
});