define("label_provider_app/services/current-user", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    isAdmin: false,
    email: false,
    isLoaded: false,
    loader: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var request = Ember.$.get('/users/me', {}, function (response) {
        var user = response.user;
        if (!user) {
          return;
        }
        Ember.run(function () {
          var csrf_token = response.csrf_token;
          _this.set('csrfToken', csrf_token);
          Ember.$.ajaxSetup({
            beforeSend: function beforeSend(request) {
              request.setRequestHeader('X-CSRF-Token', csrf_token);
            },
            xhrFields: {
              withCredentials: true
            },
            crossDomain: true,
            headers: {
              'X-ActiveModelSerializers': '1'
            }
          });
          _lodash.default.forEach(user, function (value, key) {
            _this.set(key.camelize(), value);
          });
          _this.preload();
          _this.initFreshdeskChat();
          _this.set('isLoaded', true);
        });
      });
      this.set('loader', request);
    },
    preload: function preload() {
      console.log('Nothing to preload at the moment.');
    },
    //istanbul ignore next
    initFreshChatWidget: function initFreshChatWidget(self) {
      window.fcWidget.init({
        token: '08681579-e2f9-4963-b6ae-e53f7ca26bd9',
        host: 'https://wchat.eu.freshchat.com'
      });
      var plan = self.goldServiceEnabled && 'Gold' || self.platinumServiceEnabled && 'Platinum' || 'Silver';
      window.fcWidget.user.setProperties({
        email: self.email,
        plan: plan,
        apiClient: self.apiClientId,
        userId: self.id,
        firstName: self.email,
        lastName: self.companyName,
        planId: self.planId,
        fullAccess: self.hasFullAccess,
        supportURL: self.supportURL
      });
    },
    //istanbul ignore next
    initFreshdeskChat: function initFreshdeskChat() {
      var _this2 = this;
      if (!Ember.testing) {
        if (this.silverServiceEnabled || this.goldServiceEnabled || this.platinumServiceEnabled) {
          var e;
          var t = 'Freshdesk Messaging-js-sdk';
          e = document.createElement('script');
          e.id = t;
          e.async = !0;
          e.src = 'https://wchat.eu.freshchat.com/js/widget.js';
          e.onload = function () {
            _this2.initFreshChatWidget(_this2);
          };
          document.head.appendChild(e);
        }
      }
    },
    labelProvider: function () {
      var _this3 = this;
      var result = null;
      if (this.get('labelProviderId')) {
        this.get('store').find('labelProvider', this.get('labelProviderId')).then(function (record) {
          result = record;
          _this3.set('labelProvider', record);
        });
      }
      return result;
    }.property('labelProviderId')
  });
  _exports.default = _default;
});