define("label_provider_app/components/date-time-picker-metal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    didRender: function didRender() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('picker', this.$('.js-date-time-picker').datetimepicker({
        inline: false,
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'bottom'
        }
      }));
      this.get('picker').on('dp.change', function (ev) {
        _this.set('lastEvent', ev);
      });
      this.set('engine', this.get('picker').data('DateTimePicker'));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('engine').destroy();
    },
    // keyPress(e) {
    //   const keyCode = e.keyCode;
    //   if (keyCode === 13) {
    //     this.saveDate();
    //     this.hide();
    //   }
    // },

    // keyUp(e) {
    //   const keyCode = e.keyCode;
    //   if (keyCode === 27) {
    //     this.escapePress();
    //     this.hide();
    //   }
    // },

    // escapePress() {
    //   if (this.get('onEscape')) {
    //     this.get('onEscape')();
    //   }
    // },

    currentlySelectedDate: function () {
      var evt = this.get('lastEvent');
      if (evt) {
        return evt.date;
      }
    }.property('lastEvent'),
    dateChange: Ember.observer('currentlySelectedDate', function () {
      if (this.get('updateOnDateChange') === true) {
        this.saveDate();
      }
    }),
    saveDate: function saveDate() {
      var date = this.get('currentlySelectedDate');
      if (this.get('onDateSave')) {
        this.get('onDateSave')(date);
      }
    } // hide() {
    //   this.get('engine').hide();
    // },
  });
  _exports.default = _default;
});