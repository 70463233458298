define("label_provider_app/locales/fr/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // jscs:disable maximumLineLength
  var _default = {
    address: {
      fields: {
        apartmentNumber: "N° d'appartement",
        atLeastOneStreet: 'Au moins un champ rue',
        building: 'Bâtiment',
        city: 'Ville',
        civility: 'Civilité',
        companyName: 'Raison sociale',
        computedName: 'Nom',
        country: 'Code pays',
        email: 'Email',
        firstname: 'Prénom',
        fullname: 'Nom complet',
        instructions: 'Instructions',
        lastname: 'Nom de famille',
        phone1: 'Téléphone 1',
        phone2: 'Téléphone 2',
        placeName: 'Lieu dit / BP',
        state: 'Région',
        street1: 'Rue 1',
        street2: 'Rue 2',
        street3: 'Rue 3',
        street4: 'Rue 4',
        zip: 'Code postal'
      },
      name: 'Adresse'
    },
    addressLabelConfig: {
      chronopost: 'Chronopost',
      colisPrive: 'Colis privé',
      colissimo: 'Colissimo',
      correos: 'Correos',
      courrierSuivi: 'Courrier Suivi',
      createReturnAddress: 'Créer une adresse de retour',
      createSenderAddress: "Créer une adresse d'expédition",
      creator: "Ajouter une nouvelle configuration d'étiquettes",
      delivengo: 'Delivengo',
      dpd: 'DPD',
      dpdByApi: 'DPD par API',
      envoiMoinsCher: 'Envoi Moins Cher',
      explanation: "Ajouter une nouvelle configuration d'étiquettes - Attention, tous ces services ne sont pas effectivement supportés",
      fields: {
        labelCredential: 'Accès compte',
        labelOfferSubscription: 'Inscription MKP',
        masterLabelCredential: 'Accès par mkp',
        name: 'Nom',
        service: 'Service'
      },
      gls: 'Gls',
      hermes: 'Hermes UK',
      kuehne: 'Kuehne',
      laposte: 'Colissimo',
      lettreSimple: 'Lettre Simple',
      mondialRelay: 'Mondial Relay',
      returnAddress: 'Adresse de retour',
      returnAddressCreation: {
        consequences: "Cette adresse sera utilisée pour toutes les étiquettes crées pour ce compte d'étiquette."
      },
      sda: 'SDA',
      senderAddress: "Adresse d'expédition",
      senderAddressCreation: {
        consequences: "Cette adresse sera utilisée pour toutes les étiquettes crées pour ce compte d'étiquette."
      },
      storePickup: 'Retrait sur place',
      tnt: 'TNT',
      ups: 'UPS'
    },
    addressLabelSummary: {
      details: 'Détails',
      fields: {
        carrierName: 'transporteur',
        country: 'pays (destinataire)',
        createdAt: 'Date de génération',
        details: '',
        id: 'id',
        merchantCountry: "Pays d'expédition",
        shippedAt: "Date d'expédition",
        shippingRef: 'Numéro de suivi',
        totalWeight: 'poids',
        zip: 'code postal'
      },
      heading: 'Etiquette fournie {{id}}'
    },
    addressLabels: {
      alreadySentToCarrier: 'Transporteur notifié',
      canceledAddressLabel: 'Etiquette annulée',
      deliverySlip: 'Bordereau de remise',
      disapproveAssociatedEntry: 'Annuler',
      disapproveAssociatedEntryConsequences: 'Cette étiquette ne sera pas incluse dans le prochain bordereau.',
      notSendableToCarrier: 'Non annulable'
    },
    advancedSearch: {
      fields: {
        condition: 'Condition',
        field: 'Champ',
        table: 'Relation',
        value: 'Valeur'
      },
      title: 'Recherche avancée'
    },
    apiClient: {
      fields: {
        apeNumber: 'Numéro APE',
        city: 'ville',
        companyForm: 'Forme juridique',
        companyName: "nom de l'entreprise",
        companyRegister: 'RCS/RM',
        country: 'code pays',
        email: 'email',
        firstname: 'prénom',
        frontEmail: 'Email visible par les clients',
        id: 'id sbo',
        lastname: 'nom',
        mobilePhone: 'tél. portable',
        nafNumber: 'Numéro NAF',
        phoneNumber: 'tél. fixe',
        shareCapitalCents: 'Capital social (centimes)',
        shareCapitalCurrency: 'Devise du capital social',
        sirenNumber: 'SIREN',
        siret: 'SIRET',
        sourceRef: 'ref',
        state: 'Etat',
        street: 'rue',
        vatNumber: 'Numéro TVA',
        websiteUrl: 'Site internet',
        zip: 'code postal'
      },
      invoices: 'Factures',
      updatePaymentInformation: 'Mettre à jour les informations de paiement'
    },
    application: {
      by: 'par',
      copyright: '©',
      craftedWith: 'Conçu avec',
      navigation: {
        toggleMenubar: 'Toggle menubar',
        toggleNav: 'Toggle navigation'
      },
      pageIsLoading: 'Page en cours de chargement',
      shippingbo: 'Shippingbo'
    },
    ariaLabels: {
      close: 'Fermer'
    },
    atomicFormula: {
      create: 'Ajouter une condition',
      creator: 'Créer une nouvelle condition atomique',
      explanation: 'Créer une condition',
      field: 'champ',
      fields: {
        field: 'Champ',
        operator: 'Opérateur',
        parentFormulaId: 'Id de la formule-mère',
        type: 'Type',
        value: 'Valeur'
      },
      obliterate: 'Supprimer',
      obliteration: {
        consequences: 'Cette condition sera supprimée définitivement.'
      },
      operator: 'opérateur',
      priority: 'priorité',
      value: 'valeur'
    },
    button: {
      reload: 'Recharger',
      save: 'Sauvegarder'
    },
    cardboard: {
      creator: 'Ajouter un modèle de carton',
      explanation: 'Seule la référence est obligatoire',
      fields: {
        height: 'Hauteur (mm)',
        id: 'Id',
        length: 'Longueur (mm)',
        priceCents: 'Prix (cents)',
        priceCurrency: 'Code devise',
        ref: 'Référence',
        stock: 'Stock',
        supplier: 'Fournisseur',
        weight: 'Poids (g)',
        width: 'Largeur (mm)'
      }
    },
    close: 'Fermer',
    cn23Editor: {
      packageCategory: 'Informations sur le colis',
      packageProduct: 'Ligne produit {{index}}',
      packageProducts: 'Informations sur les produits',
      shippingPrice: 'Frais de port'
    },
    collectionTable: {
      aria: 'Navigation dans le tableau'
    },
    columnSelector: {
      open: 'Colonnes',
      title: 'Configurer les colonnes'
    },
    companyIdentification: {
      administrativeFields: 'Informations administratives',
      associationArticles: 'Status de la société',
      bankStatement: 'Information bancaire de la société (RIB)',
      companyMembers: "Membres de l'entreprise",
      genericDocType: 'Document de la société',
      heading: "Information d'identification de la société",
      incorporationCertificate: 'Kbis',
      shareCapitalDistribution: 'CERFA 2035-F'
    },
    companyIdentificationDocument: {
      iban: 'IBAN'
    },
    companyMember: {
      creator: "Ajouter un membre de l'entreprise",
      explanation: 'Choisissez le type de membre à ajouter',
      fields: {
        displayableType: 'Type de membre',
        email: 'Email',
        firstname: 'Prénom',
        lastname: 'Nom',
        type: 'Type'
      },
      genericType: "Member de l'entreprise",
      identityCard: "Carte d'identité",
      identityInformation: '{{type}} {{id}}: informations générales',
      passport: 'Passeport',
      types: {
        legalRepresentative: 'Représentant légal',
        shareholder: 'Actionnaire'
      }
    },
    companyMemberDocument: {
      fields: {
        country: 'Pays'
      }
    },
    config: {
      basicMkpConfig: {
        agree: 'Accepter les CGU',
        downloadAgreement: 'Télécharger les CGU',
        heading: 'Client place de marché Shippingbo',
        userAgreementAccepted: "Conditions d'utilisations acceptées."
      },
      invoices: {
        additionalLanguages: 'Langues additionnelles',
        autoEmail: 'Email automatique',
        ccEmail: 'CC email',
        emailBody: "Texte de l'email",
        heading: 'Configuration des factures',
        legalText: 'Texte légal',
        logoUrl: 'Logo (Haut. 100px, Larg. max 360px)',
        otherFeesTaxRate: 'Taux de taxe par défaut pour les autres frais',
        productTaxRate: 'Taux de taxe par défaut pour les produits',
        shippingTaxRate: "Taux de taxe par défaut pour l'envoi",
        startingInvoiceRef: 'Premier numéro de facture',
        wrapTaxRate: "Taux de taxe par défaut pour l'emballage"
      },
      logistic: {
        advancedInsurance: "Valeurs par défaut des options d'assurance avancée",
        autoClickPrintButton: "Cliquer automatiquement sur le bouton d'impression quand le colis est prêt",
        autoFillAddress: 'Complétion automatique des adresses',
        autoFillPackage: 'Complétion automatique des informations de colis',
        autoPrintEnabled: "Activation de l'impression automatique des étiquettes",
        defaultEmail: 'Email utilisé pour la complétion automatique',
        defaultHsCode: 'HS Code par défaut',
        defaultPackageDescription: 'Description du paquet utilisé pour les expéditions en Europe',
        defaultPhone: 'Numéro de téléphone utilisé pour la complétion automatique',
        defaultScanningField: 'Champ par défaut lors du scan des codes barres du bordereau',
        defaultUnitWeight: 'Poids unitaire utilisé pour la complétion automatique',
        heading: 'Configuration de la logistique',
        minValueForInsurance: "Valeur minimale pour l'assurance (cents, devise: {{curr}})",
        subHeading: 'Configuration de la logistique',
        superSimpleCn23: 'CN23 super simple',
        warning: {
          addressCompletion: "L'auto-complétion s'appliquera à toutes les adresses pour lesquelles l'information correspondante est manquante.",
          packageCompletion: "L'auto-complétion s'appliquera à tous les colis pour lesquels le poids total ne peut pas être résolu."
        }
      },
      notif: {
        email: 'Adresse email',
        enabled: 'Actif',
        explanation: "Vous pouvez configurer l'envoi d'un email lors de l'ajout d'un message à une commande.",
        heading: 'Notifications par email',
        language: 'Langue',
        level: "Niveau minimal d'envoi",
        subHeading: "Vous pouvez configurer l'application ici"
      },
      orderToCarrierMappingRuleBook: {
        defaultCarrier: 'Transporteur par défaut',
        heading: 'Transporteur par défaut',
        rules: 'Mappings transporteurs'
      },
      pack: {
        heading: 'Packs',
        packField: 'Champ pour la détermination des packs',
        subHeading: 'Configurer la gestion des packs'
      },
      packingSlip: {
        banner: 'Bannière (PNG, largeur: 820px max.)',
        checkboxEnabled: 'Cochage',
        clientEmailEnabled: 'Email du client',
        columns: 'Activez / Désactivez des options du bordereau',
        customOrderLogo: 'Logo de commande personnalisé (PNG largeur: 70px, hauteur 33px)',
        customerHistoryEnabled: 'Historique client',
        footer: 'Pied de page (PNG, largeur: 820px max.)',
        header: 'En-tête (PNG, largeur: 360px max.)',
        heading: 'Personalisez vos bordereaux',
        locationEnabled: 'Emplacement',
        misc: 'Autres configurations',
        pictureEnabled: 'Photo',
        pricesEnabled: 'Prix de la commande',
        productBarcodeEnabled: 'Code barre produit',
        productStockEnabled: 'Stock actuel du produit',
        skuEnabled: 'Sku',
        subHeading: 'Personalisez vos bordereaux',
        titleEnabled: 'Titre',
        useProductCountInOrdering: 'Trier par le nombre de produits'
      },
      stock: {
        decrementOnOrder: "Décrémenter les stocks lors d'une nouvelle commande",
        heading: 'Stocks',
        subHeading: 'Configurer la gestion des stocks'
      }
    },
    configs: {
      apiClient: 'Compte client',
      basicMkpConfig: 'Client marketplace',
      cardboards: 'Cartons',
      clientInformation: {
        necessaryFields: 'Informations indispensables',
        optionalFields: 'Informations falculatives',
        subscription: 'Votre abonnement'
      },
      companyIdentification: "Information d'identification",
      invoicesConfig: 'Configuration des factures',
      label: 'Comptes étiquettes adresses',
      labelProvider: 'Compte vendeur',
      labelProviderInformation: {
        necessaryFields: 'Informations indispensables',
        optionalFields: 'Informations falculatives'
      },
      logisticConfig: 'Logistique',
      notif: 'Notification par email',
      orderToCarrierMappingRuleBook: 'Mappings transporteurs',
      pack: 'Gestion des packs',
      packingSlipConfig: 'Configuration des bordereaux',
      returnLabel: 'Comptes étiquettes retour',
      stock: 'Gestion des stocks'
    },
    customOrderLogo: {
      creator: 'Ajouter un nouveau logo personalisé',
      explanation: 'La valeur d\'origine sera comparée à la valeur du champ "origine" de la commande',
      fields: {
        originValue: "Valeur d'origine"
      }
    },
    dashboard: {
      dailyNumbers: 'Vos statistiques de la journée',
      orderNumber: "Aperçu de l'état des commandes ces {{period}} derniers jours",
      orderState: {
        fresh: {
          sentenceEnding: 'reçues'
        },
        inPreparation: {
          sentenceEnding: 'non préparées'
        },
        preparing: {
          sentenceEnding: 'en préparation'
        },
        shipped: {
          sentenceEnding: 'expédiées'
        },
        toBePrepared: {
          sentenceEnding: 'à préparer'
        },
        waiting: {
          sentenceEnding: 'à préparer'
        }
      }
    },
    date: {
      format: {
        long: 'dddd Do MMMM YYYY',
        short: 'DD/MM/YYYY'
      },
      weekDate: {
        1: 'Lundi',
        2: 'Mardi',
        3: 'Mercredi',
        4: 'Jeudi',
        5: 'Vendredi',
        6: 'Samedi',
        7: 'Dimanche'
      }
    },
    destroyObject: {
      consequences: 'Êtes-vous certain de vouloir supprimer cette entrée?',
      title: 'Supprimer'
    },
    history: {
      after: 'Après',
      before: 'Avant',
      blame: 'Fait par',
      blameOrder: 'Commande {{id}}',
      date: 'Date',
      field: 'Champ',
      title: 'Historique des changements'
    },
    insuranceEditor: {
      insuranceValue: 'Valeur assurée'
    },
    invoices: {
      labels: 'Étiquettes',
      returnLabels: 'Étiquettes retour',
      title: 'Factures'
    },
    labelConfigTemplate: {
      creator: 'Config étiquette par défaut',
      explanation: "Ajouter une config d'étiquettes par défaut. Elle sera automatiquement déployée à chaque nouvelle subscription.",
      fields: {
        name: 'Nom',
        service: 'Service'
      }
    },
    labelConfigTemplates: {
      title: 'Default label configs'
    },
    labelCredential: {
      configNotVisibleExplanation: 'Impossible de configurer les accès transporteurs : ils sont déjà associés à des accès fournis.',
      configure: 'Configuration des accès transporteur - ATTENTION: peut être partagé',
      creation: {
        explanation: 'Vous pourrez renseigner les accès par la suite dans la page dédiée.'
      },
      creator: 'Ajouter un accès transporteur',
      details: {
        config: 'Configuration accès transporteur {{baseType}}: {{id}} ({{name}})',
        main: 'Accès transporteur {{baseType}}: {{id}} ({{name}})'
      },
      fields: {
        baseType: 'Transporteur',
        createdAt: 'Date de création',
        details: '',
        id: 'Id',
        name: 'Nom',
        providedLabelCredentialId: "Id de l'identifiant fourni",
        providedLabelCredentialName: "Nom de l'identifiant fourni",
        ready: 'Prête',
        type: 'Transporteur'
      }
    },
    labelOffer: {
      configuration: 'Configuration',
      creator: "Créer une nouvelle offre d'étiquettes",
      credential: 'Accès transporteur',
      explanation: "Vous pourrez uploader les détails de l'offre par la suite",
      fields: {
        createdAt: 'date de création',
        details: '',
        displayableType: "type d'offre",
        id: 'id',
        labelCredential: 'accès transporteur',
        labelCredentialName: 'accès transporteur',
        name: 'nom',
        providedLabelOfferId: 'offre liée',
        providedLabelOfferName: 'offre liée',
        receiverCountry: 'destinations (FR, EU...)',
        senderCountry: 'expéditions (FR, EU...)',
        type: "type d'offre"
      },
      heading: 'Offre {{id}}: {{name}}',
      info: {
        inactive: "Cette offre n'est pas encore active. Une fois activée, vous pourrez la désactiver ce qui empêchera de nouvelles inscriptions. Les utilisateurs existants pourront eux continuer à l'utiliser."
      },
      labelOfferOptions: 'Options',
      showPrices: 'Voir les prix',
      types: {
        basic: 'Basic',
        credentialResale: 'Accès seul',
        firstMile: 'Hub'
      }
    },
    labelOfferOption: {
      creator: "Ajout d'une nouvelle option",
      explanation: 'Choisissez un service',
      fields: {
        archived: 'archiver',
        insuranceEnabled: 'assurance activée',
        onlyUseIfZipCodeAvailable: "utiliser seulement si le code postal est disponible pour l'offre",
        providedLabelOfferOptionId: 'option liée',
        providedLabelOfferOptionName: 'option liée',
        receiverCountry: 'destinations (FR, EU...)',
        service: 'service',
        zipPrefix: 'début du code postal'
      },
      heading: 'Option {{id}}: {{service}}'
    },
    labelOfferSubscription: {
      creator: 'Souscrire à une offre de transport',
      explanation: "Veuillez choisir l'offre à laquelle vous souhaitez souscrire",
      fields: {
        labelOffer: 'Offre'
      }
    },
    labelPricingTable: {
      details: 'Etat',
      heading: "Table de prix pour l'option' {{optionId}}",
      id: 'id',
      mappings: 'Tableau de prix',
      processingStatus: 'traitement'
    },
    labelPrinter: {
      addressInvalid: "L'adresse de l'expéditeur contient des erreurs. Merci de les corriger.",
      associatedConfig: {
        noLongerReady: "Le compte adresse associé n'est plus configuré pour impression. You pouvez libérer le colis en cliquant sur Déselectionner."
      },
      checkAddress: "Vérifiez l'adresse d'expédition avant impression",
      checkPackage: 'Vérifiez les détails du colis avant impression',
      checkPickUpLocationRef: 'Vérifiez le numéro du point de retrait',
      chooseConfig: 'Choisissez le transporteur pour ce colis',
      cn23Editor: 'Éditez les informations nécessaires à la génération du formulaire CN23',
      dimensionsEditor: 'Éditez les informations relatives aux dimensions',
      emcOperator: 'Choisissez un opérateur et un niveau de service',
      errors: {
        label: "Erreur à la génération de l'étiquette. Merci de vérifier l'adresse puis la configuration des étiquettes.",
        timeOut: "La demande de génération de l'étiquette a expiré",
        timeOutWithRef: "La demande de génération de l'étiquette {{ref}} a expiré",
        validation: "Erreur à la notification de l'application que l'étiquette a été imprimée. Merci de vérifier les messages d'erreur."
      },
      generationInProgress: "Veuillez patienter durant la génération de l'étiquette.",
      insuranceEditor: "Editez les informations d'assurance, si vous souhaitez assurer l'envoi.",
      labelMessages: 'Informations importantes du transporteur',
      noReadyConfig: "Aucun compte d'étiquette n'est prêt pour impression",
      packageDescription: 'Description du colis',
      packageInvalid: 'Les informations du colis contiennent des erreurs. Merci de les vérifier.',
      partsCount: "Merci d'indiquer le nombre de colis sur cette palette",
      pickUpLocationRef: 'Numéro du point de retrait',
      productsPriceEditor: 'Editez les information sur la valeur déclarée du colis',
      requestedShippingDate: 'Merci de choisir une date de chargement',
      unselect: 'Déselectionner',
      weightRequired: "Le poids est nécessaire à la génération de l'étiquette."
    },
    labelProvider: {
      fields: {
        apeNumber: 'Numéro APE',
        city: 'Ville',
        companyForm: 'Forme juridique',
        companyName: "Nom de l'entreprise",
        country: 'Code pays',
        email: 'Email',
        firstname: 'Prénom',
        frontEmail: 'Email visible par les clients',
        lastname: 'Nom',
        mobilePhone: 'Numéro de portable',
        nafNumber: 'Numéro NAF',
        phoneNumber: 'Numéro de téléphone',
        shareCapitalCents: 'Capital social (centimes)',
        shareCapitalCurrency: 'Devise du capital social',
        sirenNumber: 'SIREN',
        siret: 'SIRET',
        street: 'Rue',
        vatNumber: 'Numéro TVA',
        websiteUrl: 'Site internet',
        zip: 'Code postal'
      },
      invoices: 'Factures',
      updatePaymentInformation: 'Mettre à jour les informations de paiement'
    },
    language: {
      english: 'Anglais',
      french: 'Français'
    },
    lemonBankAccount: {
      creator: 'Ajouter un compte bancaire',
      explanation: "Compte vers lequel les retraits pourront s'effectuer",
      fields: {
        bankBranchCity: "ville de l'agence bancaire",
        bankBranchStreet: "rue de l'agence bancaire",
        bankName: 'banque',
        bic: 'bic',
        country: 'country',
        holderFullname: 'nom complet du titulaire',
        iban: 'iban'
      },
      iban: 'IBAN',
      lemonRef: 'Ref lemon',
      waitingForLemonRegistration: 'Validation en cours'
    },
    level: {
      error: 'Erreur',
      info: 'Info'
    },
    logisticConfig: {
      fields: {
        closing: 'Fermeture',
        fixing: 'Fixation',
        material: 'Matériau',
        protection: 'Protection interne',
        wrap: 'Emballage'
      }
    },
    main: {
      activate: 'Activer',
      add: 'Ajouter',
      addressLabelSummaries: 'Etiquettes fournies',
      areYouSure: 'Êtes-vous sûr ?',
      cancel: 'Annuler',
      cancelDangerousAction: 'Ne pas le faire',
      cardboards: 'Cartons',
      config: 'Configurer',
      configs: 'Configurations',
      copy: 'Copier',
      create: 'Créer',
      createOrder: 'Créer une commande',
      dashboard: 'Tableau de bord',
      deactivate: 'Desactiver',
      delete: 'Supprimer',
      doDangerousAction: 'Je suis sûr',
      download: 'Télécharger',
      edit: 'Modifier',
      false: 'Non',
      fillInTracking: 'Numéro de suivi',
      finish: 'Terminer',
      generateLabel: 'Générer une étiquette',
      generating: 'En cours de génération...',
      impersonate: 'Prendre le contrôle',
      labelCredentials: 'Accès transporteurs',
      labelOffers: "Offres d'étiquettes",
      leave: 'Quitter',
      loading: 'Chargement en cours...',
      logout: 'Déconnexion',
      monitor: 'Vérifier',
      navigation: 'Navigation',
      next: 'Suivant',
      null: '',
      order: 'Commande',
      orderItemProductMappings: 'Correspondances',
      orders: 'Commandes',
      preparationRuns: 'Logistique',
      preparationSearches: 'Mes recherches sauvegardées',
      prepare: 'Préparer',
      previous: 'Précédent',
      print: 'Imprimer',
      productPacks: 'Packs',
      products: 'Produits',
      providedClients: 'Clients',
      refresh: 'Rafraîchir',
      reportConfigs: 'Export',
      reset: 'Réinitialiser les filtres',
      save: 'Sauvegarder',
      saveSearch: 'Sauvegarder la recherche',
      search: 'Recherche',
      serviceCredentials: 'Services externes',
      topbar: {
        documentation: 'Consulter les tutoriels',
        linksupport: 'Contacter le support'
      },
      true: 'Oui',
      uploads: 'Import',
      validate: 'Valider',
      wallet: 'Porte-monnaie ShippingBo'
    },
    mappedProduct: {
      fields: {
        location: 'Emplacement',
        price: 'Prix (TTC)',
        quantity: 'Quantité',
        sku: 'SKU',
        title: 'Titre'
      }
    },
    mapping: {
      addField: 'Ajouter une association',
      key: 'Clé',
      knownFieldName: {
        state: 'État sur Shippingbo',
        updaterCarrier: 'Transporteur de clôture',
        updaterCarrierName: 'État de clôture selon le transporteur',
        updaterState: 'État de clôture'
      },
      name: 'Associations',
      value: 'Valeur'
    },
    mappingRule: {
      create: "Créer une nouvelle règle d'association",
      creator: "Créer une nouvelle règle d'association",
      enabled: 'Activée',
      explanation: "Confirmer la création d'une nouvelle règle",
      fields: {
        name: 'Règle #{{priority}} => {{name}}',
        operator: 'Opérateur principal',
        ruleBookId: 'Id du règlement',
        sourceType: 'Associant un(e)',
        targetId: 'Cible',
        targetType: 'à un(e)'
      },
      obliterate: "Supprimer la règle d'association",
      obliteration: {
        consequences: "La règle d'association sera détruite."
      },
      operator: {
        meaning: {
          and: 'Si toutes les conditions suivantes sont remplies'
        }
      },
      priority: 'priorité',
      targetType: {
        carrier: 'Assigner le transporteur'
      }
    },
    message: {
      markAsRead: 'Vu'
    },
    mkpBuyer: {
      labelOfferSubscriptions: 'Vos inscriptions à des offres de transport'
    },
    mondialRelay: {
      button: 'Choisir un point relais Mondial Relay'
    },
    nA: 'N/A',
    navettePickUp: {
      beginningHoliday: 'Début des vacances',
      choosePudo: 'Choisissez un point relais',
      configurationTitle: 'Recherche avancée',
      endingHoliday: 'Fin des vacances',
      holiday: 'Vacances',
      openingHours: "Horaires d'ouvertures",
      pudoDetails: 'Détails du point relais',
      pudoField: {
        address1: 'Adresse',
        address2: 'Adresse(2)',
        address3: 'Adresse(3)',
        available: 'Disponible',
        city: 'Ville',
        countryCode: 'Code Pays',
        handicapes: 'Accés handicapé',
        id: 'Identifiant',
        name: 'Nom',
        parking: 'parking',
        zipCode: 'Zip'
      },
      pudoId: 'Identifiant du point relais actuel',
      pudoSearchResult: "Résultat pour l'adresse {{street}}",
      title: 'Séléction du point relais'
    },
    order: {
      create: {
        confirmContent: 'La commande ne pourra plus être modifiée une fois créée',
        confirmTitle: 'Confirmation de la commande',
        details: 'Détails',
        discountPrice: 'Remise',
        duplicateBilling: "Copier l'adresse de facturation",
        duplicateShipping: "Copier l'adresse de livraison",
        hidePreview: 'Masquer le résumé',
        itemPriceTaxIncludedCents: 'Prix (TTC, centimes)',
        noAddress: 'Aucune',
        orderSummary: 'Résumé de la commande',
        otherFees: 'Autres frais',
        shippingPrice: 'Frais de port',
        showPreview: 'Afficher le résumé',
        title: 'Créer une commande',
        wrapPrice: "Frais d'emballage"
      },
      details: {
        deliveredAtDate: 'Date de livraison',
        deliveryDate: 'Date de livraison demandée',
        discount: 'Remise',
        displayCurrencyLeft: 'false',
        estimatedAfter: 'Après le',
        estimatedAnd: 'et le',
        estimatedBefore: 'Avant le',
        estimatedBetween: 'Entre le',
        missingAddress: 'Adresse inconnue',
        noMappedCarrier: 'Aucun',
        otherFees: 'Autres frais',
        price: 'Prix',
        priceDecimalSeparator: ',',
        priceHundredsSeparator: ' ',
        priceTTC: 'TTC',
        priceTaxIncluded: 'TTC',
        priceTaxIncludedCents: 'TTC (centimes)',
        productsPrice: 'Produits',
        recompute: {
          and: 'et',
          matchingFields: 'Le premier champ trouvé sera utilisé.',
          warning: 'Les lignes de commande sont associées par {{list}}, dans cet ordre.'
        },
        shippedAtDate: "Date d'expédition",
        shippingDate: "Date d'expédition demandée",
        shippingPrice: 'Transporteur',
        totalPrice: 'Total',
        unknownCurrency: 'Devise inconnue',
        wrapPrice: 'Emballage'
      },
      errors: {
        cannotBeRemovedFromRun: "La commande n'a pas pu être retirée de la session.",
        failedToRecomputeMappedProducts: 'Echec du recalcul des produits associés',
        failedToRegenerateInvoice: 'Echec de la regénération de la facture'
      },
      fields: {
        allLinesAreMapped: 'Toutes les lignes ont des produits',
        billingAddressId: 'Adresse de facturation',
        chosenDeliveryService: 'Transporteur',
        clientRef: 'Réf. client',
        customState: 'Etat spécifique',
        details: '',
        earliestDeliveryAt: 'Livraison après',
        earliestShippedAt: 'Expédition après',
        fulfilledByMarketplace: 'Fulfilled par PDM',
        id: 'Id',
        invoiceRef: 'Réf. facture',
        latestDeliveryAt: 'Livraison avant',
        latestShippedAt: 'Expédition avant',
        origin: 'Origine',
        originCreatedAt: 'Date de commande',
        originRef: "Référence d'origine",
        packageId: 'Numéro de colis',
        preparationRunId: 'Id préparation',
        productCount: 'Nombre de produits',
        productsOtherRef1: 'Autre ref1 des produits',
        productsOtherRef2: 'Autre ref2 des produits',
        productsOtherRef3: 'Autre ref3 des produits',
        relayRef: 'Numéro de point relais',
        shippedAt: "Date d'expédition",
        shippingCountry: 'Pays de destination',
        shippingRequiresCn23: 'CN23 requis',
        shippingToDomTom: 'Destination aux DOM TOM',
        shippingToEu: 'Destination en UE',
        shippingZip: 'Code postal de destination',
        source: 'Source',
        sourceRef: 'Référence source',
        state: 'État',
        tagValues: 'Liste des tags',
        totalDiscountTaxIncludedCents: 'Total remise TTC (cents)',
        totalItemPriceCents: 'Total produits HT (cents)',
        totalItemPriceTaxIncludedCents: 'Total produits TTC (cents)',
        totalItemTaxCents: 'Total des taxes produits (cents)',
        totalMarketplaceFeesCents: 'Total frais place de marché HT (cents)',
        totalMarketplaceFeesTaxCents: 'Total des taxes frais place de marché(cents)',
        totalMarketplaceFeesTaxIncludedCents: 'Total frais place de marché TTC (cents)',
        totalOtherFeesCents: 'Total autres frais HT (cents)',
        totalOtherFeesTaxCents: 'Total des taxes autres frais (cents)',
        totalOtherFeesTaxIncludedCents: 'Total autres frais TTC (cents)',
        totalPriceCents: 'Prix total (cents)',
        totalPriceCurrency: 'Devise',
        totalShippingCents: 'Total expédition HT (cents)',
        totalShippingTaxCents: 'Total des taxes expédition (cents)',
        totalShippingTaxIncludedCents: 'Total expédition TTC (cents)',
        totalTaxCents: 'Total des taxes (cents)',
        totalWeight: 'Poids total (g)',
        totalWithoutTaxCents: 'Total HT (cents)',
        totalWrapCents: 'Total emballage HT (cents)',
        totalWrapTaxCents: 'Total des taxes emballage (cents)',
        totalWrapTaxIncludedCents: 'Total emballage TTC (cents)'
      },
      recomputeMappedProducts: 'Recalculer les produits associés',
      regenerateInvoice: 'Regénérer une facture',
      removeFromRun: {
        buttonTitle: 'Retirer du run',
        explanation: 'Cette commande sera définitivement retirée de cette session de préparation.',
        title: 'Retirer la commade du run'
      },
      state: {
        atPickupLocation: 'en point retrait',
        backFromClient: 'renvoyée par le client',
        canceled: 'annulée',
        closed: 'livrée',
        handedToCarrier: 'remis au transporteur',
        inPreparation: 'en préparation',
        inTrouble: 'en erreur',
        merged: 'fusionnée',
        null: '',
        partiallyShipped: 'partiellement expédiée',
        rejected: 'refusée',
        sentToLogistics: 'envoyée en logistique',
        shipped: 'expédiée',
        toBePrepared: 'à préparer',
        waitingForPayment: 'en attente de paiement',
        waitingForStock: 'en attente de stock'
      },
      success: {
        invoiceIsBeingGenerated: 'Une nouvelle facture va être générée',
        mappedProductsUpdated: 'Produits associés mis à jour'
      }
    },
    orderAccepter: {
      fields: {
        enabled: 'Activé'
      }
    },
    orderDocument: {
      creator: 'Géneration Etiquette de Retour',
      explanation: "Vous pouvez éditer l'adresse email pour l'étiquette de retour",
      fields: {
        email: 'Email'
      },
      reports: {
        package: 'Adresses'
      },
      shipment: {
        danger: "Erreur lors de la notification d'expédition",
        success: "Succès de la notification d'expédition"
      },
      types: {
        invoice: 'Facture',
        packingSlip: 'Bordereau de préparation',
        returnLabel: 'Étiquette retour'
      }
    },
    orderEvent: {
      creator: 'Ajouter un message',
      explanation: 'Ajouter un message concernant cette commande',
      fields: {
        level: 'Niveau',
        message: 'Message',
        title: 'Titre'
      }
    },
    orderFetcher: {
      fields: {
        fetcherEnabled: 'Activé',
        lastUpdatedAtInApiFmt: 'Date de départ (UTC)'
      }
    },
    orderItem: {
      fields: {
        productRef: 'Référence vendeur',
        productSource: 'Source produit',
        productSourceRef: 'Référence source produit',
        title: 'Titre'
      },
      name: 'Ligne de commande'
    },
    orderItemProductMapping: {
      creator: 'Ajouter une correspondance',
      explanation: 'Vous pouvez ici faire correspondre les lignes de commandes avec vos produits',
      fields: {
        cdiscountPrice: 'Prix sur Cdiscount (en centimes)',
        destroy: '',
        id: 'Id',
        matchedQuantity: 'Quantité dans le lot',
        orderItemField: 'Champ ligne de commande',
        orderItemSource: 'Source commande',
        orderItemValue: 'Référence additionnelle',
        packProductId: 'Id du produit pack',
        productField: 'Champ produit',
        productValue: 'Valeur du champ produit',
        spartooPartnaireRef: 'Réf. Spartoo'
      },
      title: 'Références produits'
    },
    orderState: {
      change: {
        warning: "ATTENTION: Ce changement d'état ne sera pas répercuté à la source de la commande. Êtes-vous sûr(e) de vouloir continuer ?"
      }
    },
    orderTag: {
      creator: 'Ajouter un tag',
      explanation: 'Ajouter un tag sur cette commande pour être capable de filtrer dessus',
      fields: {
        value: 'tag'
      },
      name: 'Tags'
    },
    orderToAddressLabelConfigMapping: {
      fields: {
        addressLabelConfigId: 'Compte étiquette'
      },
      name: 'Transporteur calculé'
    },
    orderUpdater: {
      fields: {
        inPreparationEnabled: 'Notification lors de la préparation',
        inTroubleEnabled: "Notification en cas d'erreur",
        shippedEnabled: "Notification de l'expedition des commandes"
      }
    },
    orders: {
      details: {
        billingAddress: 'Adresse de facturation',
        details: 'Détails',
        documents: 'Documents',
        genReturnLabel: 'Géneration Etiquette de Retour',
        mappedProducts: 'Produits',
        orderEvents: 'Messages',
        printPs: 'Générer le bordereau de préparation',
        sendToClogistique: 'Envoyer à Clogistique',
        sendToFba: 'Envoyer à FBA',
        sendToLogistics: {
          error: "Echec de la programmation de l'expédition",
          success: 'Commande programmée pour expédition'
        },
        shipping: 'Expédition',
        shippingAddress: 'Adresse de livraison',
        tags: 'Tags',
        title: 'Commande {{id}}'
      },
      invoice: {
        printPs: 'Générer la facture'
      },
      shipments: {
        addressLabelUrl: 'Étiquette',
        carrier: 'Transporteur',
        generateReturnLabel: 'Étiquette retour',
        method: 'Méthode',
        otherDoc: 'Document',
        packageId: 'Id colis',
        parcelStatus: 'Statut colis',
        pickingControlValidated: 'Picking',
        returnLabelGeneration: 'Géneraton Etiquettes de Retour',
        shippedAt: "Date d'expédition",
        shippingRef: 'Num. de suivi',
        unknownUser: 'Inconnu',
        user: 'Utilisateur'
      }
    },
    packComponent: {
      creator: 'Ajouter un produit dans votre pack',
      explanation: 'Vous pouvez ici ajouter un produit dans votre pack',
      fields: {
        componentProductId: 'id du produit composant le pack',
        location: 'Emplacement',
        quantity: 'Quantité dans un pack',
        title: 'Titre',
        userRef: 'Référence (SKU)'
      },
      title: 'Ajouter dans le pack'
    },
    package: {
      createShipment: 'Sauver les informations de suivi',
      errors: {
        failedSubpacksCreation: 'Echec de la création des sous-colis'
      },
      failureHeader: 'Échec',
      failureMessage: "Les informations de suivis n'ont pas pu être sauvegardées.",
      fields: {
        cardboard: 'Référence carton',
        closing: 'Fermeture',
        description: 'Description',
        fixing: 'Fixation',
        height: 'Hauteur (mm)',
        insuranceValueCents: 'Valeur assurée (centimes)',
        insuranceValueCurrency: 'Devise de la valeur assurée',
        length: 'Longueur (mm)',
        material: 'Matériau',
        packageCategory: 'Informations du colis',
        packageProducts: 'Lignes produit',
        partsCount: 'Nombre de colis sur cette palette',
        pickUpLocationRef: 'Référence du point retrait',
        productsPriceCents: 'Valeur déclarée (centimes)',
        productsPriceCurrency: 'Devise de la valeur déclarée',
        protection: 'Protection interne',
        requestedShippingDate: 'Date de chargement souhaitée',
        shippingPriceCents: 'Valeur des frais de port (centimes)',
        shippingPriceCurrency: 'Devise des frais de port',
        totalQuantity: 'Quantité totale',
        totalWeight: 'Poids total (grammes)',
        width: 'Largeur (mm)',
        wrap: 'Emballage'
      },
      fillInTracking: 'Assigner un numéro de suivi au colis {{id}}',
      generateLabel: 'Générer une étiquette pour le colis {{id}}',
      searchMethods: "Rechercher une méthode d'envoi:",
      seeOrder: 'Voir la commande correspondante',
      shippingRef: 'Numéro de suivi',
      subPackage: 'Sous-colis {{count}}',
      successHeader: 'Succès',
      successMessage: 'Les informations de suivis ont été sauvegardées.',
      totalPackages: 'Nombre de colis nécessaires',
      waitingForItsTurn: 'Ce colis attend que le(s) précédent(s) soi(en)t terminé(s)'
    },
    packageCategory: {
      fields: {
        importersContact: "Contact de l'importateur",
        importersReference: "Référence de l'importateur",
        officeOrigin: "Bureau d'origine",
        value: "Nature de l'envoi"
      },
      value: {
        1: 'Cadeau',
        2: 'Échantillon commercial',
        3: 'Envoi commercial',
        4: 'Document',
        5: 'Autre',
        6: 'Retour de marchandise'
      }
    },
    packageCreator: {
      packageLabel: 'Générer une étiquette',
      packageTracking: 'Renseigner un numéro de suivi'
    },
    packageLabel: {
      fields: {
        carrier: 'Transporteur',
        errorMessage: "Détails de l'erreur",
        errorType: 'Erreur',
        packageId: 'Id colis',
        shippingRef: 'Numéro de suivi',
        state: 'État'
      },
      fixMe: 'Editer le colis',
      notifyShipment: 'Marquer comme "Expédié"',
      orderId: 'Id de la commande',
      ready: 'Générée',
      reschedule: "Retenter la génération de l'étiquette",
      waiting: 'En cours',
      withErrors: 'En erreur'
    },
    packageProduct: {
      creator: 'Ajouter une ligne de produit au CN23',
      explanation: 'Vous pouvez ajouter une ligne de produit au document CN23',
      fields: {
        description: 'Description',
        hsCode: 'N° tarifaire',
        originCountry: "Pays d'origine",
        quantity: 'Quantité',
        unitValueCents: 'Prix unitaire (cents)',
        unitValueCurrency: 'Devise',
        weight: 'Poids unitaire (g)'
      }
    },
    packingInfo: {
      creator: 'Ajouter une information de preparation',
      explanation: 'Cette information sera ajoutée au produit dans le bordereau de préparation',
      fields: {
        clientRef: 'Référence client',
        displayOrder: "Ordre d'affichage",
        id: 'id',
        value: 'valeur'
      }
    },
    packingSlip: {
      carrier: 'Transporteur',
      explainCarrier: "En choisissant un compte d'étiquettes ici, vous forcerez le choix du transporteur lors de la génération de l'étiquette pour ce bordereau. Laissez à vide si vous voulez laisser ce choix lors de la génération de l'étiquette.",
      shipPackages: 'Marquer comme envoyée'
    },
    packingSlipConfig: {
      fields: {
        additionalSkuField: 'Champ de référence supplémentaire',
        originRefField: 'Champ pour la référence commande'
      }
    },
    pickUpLocationDisplay: {
      errors: {
        notImplemented: 'Attention. Le service sélectionné ne supporte pas les points de retrait.\n Mais un numéro de point de retrait ({{ref}}) a été donné. Si vous imprimez une étiquette avec ce service, le numéro sera ignoré.'
      },
      information: {
        noneGiven: "Aucun numéro de point de retrait n'a été donné.",
        notImplemented: 'Le service sélectionné ne supporte pas les points de retrait.'
      }
    },
    pickingControl: {
      checkedQuantity: 'Déjà contrôlé',
      doneWithPackage: 'Flashage produits terminé',
      inputLabel: 'SKU ou EAN13',
      panelTitle: 'Contrôle picking du sous-colis {{packIndex}}',
      pickingComplete: '',
      productsToPick: 'Produits',
      quantity: 'Quantité commandée',
      skip: "Passer l'étape de contrôle",
      sku: 'SKU',
      title: 'Titre',
      unknownValue: '"{{value}}" ne correspond à aucun produit'
    },
    product: {
      additionalReferences: {
        title: 'Références produit additionnelles (50 premières)'
      },
      creator: 'Nouveau produit',
      details: {
        detailTitle: 'Informations du produit',
        packingInfos: 'Informations de préparation',
        title: 'Produit {{id}} {{title}}'
      },
      explanation: "La référénce est le seul champ obligatoire et dont l'unicité est requise",
      explanationWithSource: 'La source identifie l\'origine des données, utiliser "Manual" si vous n\'avez pas de source pour vos produits.<br/>Référence source est un identifiant unique parmi cette source, utilisez votre SKU par défaut.',
      name: 'Produit',
      packComponents: {
        notAPack: "Ce produit n'est pas un pack",
        title: 'Composants du pack'
      },
      packProduct: {
        title: 'Ce produit appartient à ces packs'
      },
      stockVariation: 'Entrée ou sortie de stock'
    },
    productFetcher: {
      fields: {
        fetcherEnabled: 'Activé'
      }
    },
    productsPriceEditor: {
      productsPrice: 'Valeur déclarée'
    },
    providedClient: {
      creation: {
        explanation: 'Merci de renseigner les informations ci-dessous'
      },
      creator: 'Créer un nouveau client',
      details: {
        main: 'Client {{ref}} (sbo id: {{id}})'
      },
      fields: {
        city: 'ville',
        companyName: 'société',
        country: 'pays',
        details: '',
        email: 'email',
        firstname: 'prénom',
        id: 'id sbo',
        lastname: 'nom',
        mobilePhone: 'mobile',
        phoneNumber: 'tél.',
        sourceRef: 'ref',
        state: 'état',
        street: 'rue',
        zip: 'cp'
      }
    },
    report: {
      creator: 'Nouvel export',
      explanation: 'Exporter les données :',
      fields: {
        customEndDate: 'Fin',
        customStartDate: 'Début',
        dateBorderedSelected: '',
        requestedShippingDate: "Date d'expédition souhaitée"
      }
    },
    reportConfig: {
      config: {
        encoding: 'Encodage',
        encodingWarning: "Attention, au sujet de l'encodage: Si l'encodage choisi ne contient pas certains des caractères UTF-8 originaux, ils seront remplacés par un espace",
        explanation: 'Configuration de ce format export (nom, séparateur, encodage)',
        fieldExplanation: "Vous pouvez activer ou désactiver les options suivantes dans l'export :",
        fieldHeader: 'Configuration des champs',
        forceQuotes: 'Valeurs entre guillemets',
        header: 'Configuration',
        name: 'Nom',
        separator: 'Separateur',
        showHeader: 'Ligne en-tête'
      },
      configure: 'Configurer',
      customFields: {
        delete: 'Supprimer',
        deleteRcf: {
          error: {
            header: 'Erreur',
            messages: "Une erreur s'est produite lors de la suppression de règle"
          },
          success: {
            header: 'Succès',
            messages: 'Règle suprimée avec succès'
          }
        },
        explanation: "Vous pouvez ajouter et supprimer des règles pour les exports.<br>Elles consistent en des colonnes supplémentaires ajoutées aux exports.<br>L'en-tête et la valeur sont configurables",
        title: 'Champs personalisés'
      },
      dateBordered: {
        bordered: 'Choisir une plage de dates',
        includeAll: 'Inclure tous les éléments depuis le dernier export'
      },
      generate: 'Générer un nouvel export',
      go: 'Exports',
      kuehne: {
        shipmentRequest: {
          generate: 'Demande une expédition'
        }
      },
      noTrigger: 'Cet export peut pas être déclenché à la main',
      notYetGenerated: "L'export n'a pas encore été généré",
      options: {
        carrierNameEnabled: 'Nom accès transporteur',
        contractEnabled: 'Montrer les informations de contrat',
        fullSourceEnabled: 'Source complète',
        labelOfferNameEnabled: 'Nom de l’offre',
        pricesEnabled: 'Prix'
      }
    },
    reportCustomField: {
      creator: 'Créer un champ personalisé',
      explanation: 'Ajouter un champ personalisé à cet export',
      fields: {
        field: 'En-tête',
        value: 'Valeur'
      }
    },
    returnLabelConfig: {
      back: 'Retour à la page précédente',
      fields: {
        name: 'Nom',
        readyToUse: "Conditions d'utilisation acceptées",
        returnLabelCcEmail: 'Email en copie'
      },
      linkedConfigNotReady: "Ce transporteur n'a pas été complètement configuré, merci de contacter le support client pour que cela soit fait.",
      notFullyConfigured: 'Cette configuration est incomplète. Merci de remplir tous les champs ci-dessus.',
      title: 'Configuration des retours: {{name}}'
    },
    returnLabelGenerator: {
      chooseCarrier: {
        configure: 'Configurer',
        create: 'Confirmer',
        modalTitle: 'Vérifier les informations du transporteur',
        title: 'Choix du transporteur'
      },
      generateLabel: {
        doIt: 'Générer une étiquette retour',
        failure: "La génération de l'étiquette retour a échoué:",
        title: 'Informations de retour'
      }
    },
    returnPackage: {
      fields: {
        buyerEmail: 'Email client',
        height: 'Hauteur (mm)',
        length: 'Longueur (mm)',
        totalWeight: 'Poids (gr)',
        width: 'Largeur (mm)'
      }
    },
    save: {
      errors: 'Erreur lors de la sauvegarde des informations',
      success: 'Sauvegarde réussie'
    },
    search: {
      additionalSearch: {
        required: 'Vous devez entrer une valeur'
      },
      creator: 'Condition de recherche pour {{field}}',
      modifiers: {
        blank: 'est vide :',
        contains: 'contient',
        // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
        ends_with: 'se termine par',
        // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
        eq: '&#61;',
        gt: '&gt;',
        gteq: '&ge;',
        lt: '&lt;',
        lteq: '&le;',
        neq: '!=',
        nil: 'non défini :',
        // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
        starts_with: 'commence par',
        // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
        true: 'vrai :'
      },
      values: {
        false: 'non',
        true: 'oui'
      }
    },
    serviceCredential: {
      check: {
        failure: "Vos informations n'ont pas permis de vous authentifier. Merci de les vérifier.",
        success: 'Vos informations semblent correctes.',
        tryAgain: "La vérification de vos informations a recontré un problème. Merci d'essayer à nouveau."
      },
      checkCredentials: 'Vérfication des accès au service externe',
      config: {
        edit: 'Modifier',
        failure: "Erreur lors de la mise à jour. Tout d'abord, merci de commencer par vérifier vos accès.",
        header: 'Suivez notre <a href="/tutorials/{{service}}.pdf" target="_blank">tutoriel</a> pour savoir comment trouver vos accès.',
        save: 'Sauvegarder',
        success: 'Accès mis à jour.',
        warnings: {
          ebay: {
            siteid: 'Siteid represente le pays de votre compte eBay, la valeur par défaut est ebay.fr (71).',
            token: "Merci de cliquer sur le bouton ci-dessous nous permettant de vous générer un token authorisant l'application à accéder à vos données."
          },
          fnac: {
            partnerCreds: 'Vous devez demander à votre gestionnaire de compte de vous donner des accès APi liés à Facilecomm.'
          },
          prestashop: {
            mappings: 'Votre compte est créé avec un mapping par défaut pour les états des commandes. Si vous avez besoin de changer cette table de correspondance, merci de nous contacter.'
          },
          priceminister: {
            shipped: 'Il n\'y a pas d\'état "Envoyé" sur Priceminister. Donc toutes les commandes arriveront dans l\'état "à préparer". Assurez vous de bien filtrer les commandes que vous avez déjà envoyées.'
          },
          wizishop: {
            states: 'Nous ne récupérons de Wizishop que les commandes dans un état particulier configuré par défaut. Si vous souhaitez changer cet état, merci de nous contacter. Si vous changez le statut des commandes sur Wizishop, ces changements ne seront pas répercutés sur Shippingbo. Assurez vous donc de bien filtrer les commandes annulées sur Wizishop ou celles déjà envoyées.'
          }
        }
      },
      creator: 'Ajouter un lien avec un service externe.',
      doesNotImplementCredentialsChecking: 'Les accès à ce service ne peuvent pas être vérifiés automatiquement.',
      explanation: 'Ajouter une connexion entre Shippingbo et une place de marché, ou votre site web.',
      fields: {
        details: '',
        displayType: 'service',
        id: 'id',
        name: 'nom',
        orderAccepter: 'Acc. commandes',
        orderDeliverer: 'Envoi commandes',
        orderFetcher: 'Récup. commandes',
        orderShipper: 'Clôture commandes',
        productFetcher: 'Récup. produits',
        stockDeliverer: 'Envoi stocks',
        stockDelivererShown: 'Envoi stocks',
        stockFetcher: 'Récup. stocks',
        type: 'service'
      },
      relayRef: {
        warning: 'Les options relatives à la configuration des points relais sont limitées aux admins. Veuillez contacter le support à ce sujet.'
      }
    },
    serviceCredentials: {
      details: {
        config: 'Accès API',
        exchangeToken: 'Renouveler le token',
        orderAccepter: {
          explanation: "Activation / désactivation de l'acceptation automatique des commandes.",
          title: 'Acceptation des commandes'
        },
        orderFetcher: {
          explanation: "Activation / désactivation de l'import automatique des commandes. Vous devez aussi indiquer une date correspondant à la date de début de récupération des commandes",
          title: 'Récupération des commandes'
        },
        orderUpdater: {
          explanation: "Activation / désactivation de la répercution des changements d'état des commandes vers le service externe",
          title: "Mise à jour de l'état des commandes"
        },
        productFetcher: {
          explanation: "Activation / désactivation de l'import automatique des produits",
          title: 'Récupération des produits'
        },
        stockDeliverer: {
          explanation: 'Activation / désactivation des mises à jour de stock vers le service externe',
          stockEntriesUrl: "Il ne nous est pas possible d'envoyer les stocks vers ce service. À la place, ils récupèrent régulièrement cette information depuis notre système. Merci de donner cette url à votre gestionnaire de compte: {{url}}",
          title: 'Mise à jour du stock'
        }
      }
    },
    shipment: {
      fields: {
        carrierName: 'Transporteur',
        packageId: 'Numéro de colis',
        shippingRef: 'Numéro de suivi'
      },
      name: 'Expédition'
    },
    stockVariation: {
      creator: 'Ajouter ou retirer du stock pour ce produit',
      explanation: 'Ajouter du stock avec un nombre positif et retirer du stock avec un nombre négatif',
      fields: {
        variation: 'Valeur du changement de stock (+/-)'
      },
      productKey: 'Champ de référence pour le produit'
    },
    subscription: {
      cancel: "Annuler l'abonnement",
      destroy: {
        failed: 'Un problème est survenu lors de la suppression de votre abonnement.',
        performed: 'Votre abonnement a été terminé avec succès.',
        scheduledFor: 'Votre abonnement se terminera le'
      },
      destroyConsequences: 'Cette action mettra fin à votre abonnement.',
      fields: {
        state: 'Etat',
        terminationDate: 'Date de fin'
      },
      states: {
        active: 'Actif',
        canceled: 'Annulée',
        failed: 'Échec du premier paiement',
        finishing: 'Annulation demandée',
        inactive: 'Inactif',
        pastDue: 'En retard de paiement'
      }
    },
    symbol: {
      close: '×'
    },
    table: {
      currentOrdering: 'Trié par',
      page: 'Page:',
      search: 'Rechercher',
      sortOrder: {
        asc: 'croissant',
        desc: 'décroissant'
      },
      totalRecords: 'Total: {{total}}'
    },
    tracking: {
      collapsibleStatusTitle: 'Suivi du colis {{id}}',
      comment: 'Commentaire du transporteur',
      parcelActualWeight: 'Poids réel du colis (g)',
      parcelRef: 'Ref. colis',
      parcelStatus: 'Statut',
      status: {
        atCustoms: 'En douane',
        atPickUpPoint: 'Au point relais',
        beingProcessed: 'En cours de traîtement',
        delayed: 'En retard',
        delivered: 'Livré',
        deliveryFailed: 'Erreur de livraison',
        deliveryInProgress: 'Livraison en cours',
        deliveryScheduled: 'Livraison prévue',
        failedPickUp: 'Erreur de ramassage',
        handedOver: 'Remis au transporteur',
        inTrouble: 'En erreur',
        pickedUp: 'Ramassé',
        receiverActionRequired: 'Action du destinataire requise',
        receiverInformed: 'Destinataire informé',
        receiverNotificationFailed: 'Erreur de notification destinataire',
        receiverNotified: 'Destinataire notifié',
        rejected: 'Rejeté',
        requestedReceived: 'Requête reçue',
        returnInProgress: 'Retour en cours',
        returned: 'Retourné',
        scannedIn: 'Scanné',
        senderActionRequired: "Action de l'expéditeur requise",
        stopped: 'Arrêté',
        toBePickedUpAtCarrierStore: 'A récupérer en magasin transporteur',
        unknown: 'Inconnu',
        unloaded: 'Déchargé'
      },
      statusChangedAt: 'Dernier changement'
    },
    upload: {
      cdiscount: 'Paiement CDiscount',
      completeOipms: 'produit: référence additionnelle',
      confirm: "Ne pas fermer cette fenêtre avant la fin de l'import ou il ne sera pas complet.",
      delimiter: 'Séparateur de colonnes',
      dpdShipment: 'Suivi DPD',
      errors: 'Erreurs',
      exampleFile: 'Fichier exemple',
      explanation: "Vous pouvez ici importer des objets depuis un CSV. Le fichier doit inclure une ligne d'entête spécifiant les colonnes.",
      file: 'Fichier à importer',
      fnac: 'Paiement FNAC',
      glsShipment: 'Suivi GLS',
      inProgress: 'En cours',
      modelName: "Type d'import",
      orderItemProductMapping: 'Correspondance Ligne de commande / Produit',
      packComponent: 'Pack',
      packingInfo: 'Informations de préparation',
      payment: 'Paiement',
      processRate: 'Importé: {{rate}}',
      product: 'Produit',
      ref: 'Référence',
      shipment: 'Informations de suivi',
      stockVariation: 'Entrées / sorties de stock',
      success: 'Succès',
      successRate: 'Succès: {{rate}}',
      title: {
        completeOipms: 'produit: référence additionnelle',
        orderItemProductMapping: 'Correspondance ligne de commande / produit',
        packComponent: 'Pack',
        packingInfo: 'Informations de préparation',
        product: 'Produit',
        shipment: 'Informations de suivi',
        stockVariation: 'Entrées / sorties de stock',
        xlsUpload: {
          cdiscount: 'Paiements Cdiscount',
          fnac: 'Paiements FNAC'
        }
      },
      update: 'Mise à jour',
      updateWarning: 'La première colonne doit être la clef primaire utilisée pour la mise à jour. Les clefs primaires autorisées sont: {{keys}}.'
    },
    validator: {
      errors: {
        characterSet: 'Le champ {{field}} ne peut contenir que les charactères suivants: {{displayChars}}',
        delegateHasManyValidity: 'Les {{field}}s ne sont pas valides',
        delegateValidity: "Le champ {{field}} n'est pas valide",
        different: "La valeur {{value}} n'est pas autorisée pour le champ {{field}}",
        equal: 'Le champ {{field}} doit être égal à {{value}}',
        greaterOrEq: 'Le champ {{field}} doit être supérieur (ou égal à) {{value}}',
        greaterThanEq: 'Le champ {{field}} doit être supérieur (ou égal à) à {{value}}',
        included: "La champ {{field}} ne peut avoir que l'une des valeurs suivantes: {{displayableList}}",
        isAnInt: 'Le champ {{field}} doit avoir une valeur entière',
        lengthEq: 'La longuer du champ {{field}} doit être de {{value}}',
        lessThanEq: 'Le champ {{field}} doit être inférieur (ou égal à) {{value}}',
        longerThanEq: "La longueur du champ {{field}}'s doit être supérieure (ou égale à) {{value}}",
        matchesRegExp: "Le champ {{field}} n'a pas le format attendu.",
        required: 'Le champ {{field}} doit être rempli',
        shorterThanEq: 'La longueur du champ {{field}} doit être inférieure à {{value}}',
        startsWith: 'Le champ {{field}} doit commencer par {{interpolate}}',
        stringOfAlphas: 'Le champ {{field}} ne peut contenir que des lettres alphabétiques',
        stringOfDigits: 'Le champ {{field}} ne peut contenir que des chiffres'
      },
      specialErrors: {
        configLogistic: {
          defaultPackageDescription: "Le champ 'Description du paquet utilisé pour les expéditions en Europe' dans logistique doit être complété"
        },
        courrierSuivi: {
          notAFrenchZip: "Le code postal donné ({{zip}}) n'est pas un code postal français valide. Merci de le vérifier."
        }
      }
    },
    wallet: {
      associatedBankAccount: 'Compte bancaire associé',
      balance: 'Solde',
      potentialBalance: 'Solde virtuel',
      status: 'Etat',
      title: 'Porte-monnaie Shippingbo marketplace',
      waitingForDocs: 'En attente de documents ou de leur validation.',
      waitingForRegistration: "En attente de l'enregistrement de la wallet."
    },
    walletMoneyIn: {
      cancel: 'Paiement annulé',
      creator: "Ajouter de l'argent sur le porte-monnaie",
      error: 'Erreur lors de la tentative de paiement',
      explanation: 'Merci de choisir le mode de paiement et le montant à créditer sur votre porte-monnaie.',
      fields: {
        amountCents: 'Montant (centimes)',
        amountCurrency: 'Devise',
        amountInUnits: 'Montant',
        type: 'Mode de paiement'
      },
      id: 'Référence shippingbo du paiement',
      initialized: 'Initialisé',
      pending: 'En cours',
      success: 'Paiement réussi !',
      types: {
        creditCard: 'Credit card'
      },
      unknownState: 'Etat du paiement inconnu'
    },
    walletMoneyOut: {
      buttonKey: 'Retirer',
      creator: "Retirer de l'argent du porte-monnaie",
      explanation: 'Ce montant sera retiré du porte-monnaie Shippingbo et crédité par virement sur le compte bancaire associé.',
      fields: {
        amountCents: 'Montant (centimes)',
        amountCurrency: 'Devise',
        amountInUnits: 'Montant'
      }
    },
    weightToPriceMapping: {
      maxWeight: 'Poids max (g)',
      minWeight: 'Poids min (g)',
      priceCents: 'Prix centimes (EUR)'
    }
  };
  _exports.default = _default;
});