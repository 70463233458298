define("label_provider_app/locales/es/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // jscs:disable maximumLineLength
  var _default = {
    address: {
      fields: {
        apartmentNumber: 'Número de apartamento',
        atLeastOneStreet: 'Al menos un campo de calle',
        building: 'Edificio',
        city: 'Ciudad',
        civility: 'Civilidad',
        companyName: 'Nombre de la empresa',
        computedName: 'Nombre',
        country: 'Código del país',
        email: 'Correo electrónico',
        firstname: 'Nombre',
        fullname: 'Nombre y apellidos',
        instructions: 'Instrucciones',
        lastname: 'Apellido',
        phone1: 'Teléfono 1',
        phone2: 'Teléfono 2',
        placeName: 'Nombre de la localidad',
        state: 'Estado',
        street1: 'Calle 1',
        street2: 'Calle 2',
        street3: 'Calle 3',
        street4: 'Calle 4',
        zip: 'Código postal'
      },
      name: 'Dirección'
    },
    addressLabelConfig: {
      chronopost: 'Chronopost',
      colisPrive: 'ColisPrive',
      colissimo: 'Colissimo',
      correos: 'Correos',
      courrierSuivi: 'CourrierSuivi',
      createReturnAddress: 'Crear una dirección de devolución',
      createSenderAddress: 'Crear una dirección de remitente',
      creator: 'Añadir una nueva configuración de etiqueta',
      delivengo: 'Delivengo',
      dpd: 'DPD',
      dpdByApi: 'DPD by API',
      envoiMoinsCher: 'envoiMoinsCher',
      explanation: 'Crear una nueva configuración de etiqueta - ADVERTENCIA no todos los servicios son realmente compatibles',
      fields: {
        labelCredential: 'Credenciales de la cuenta',
        labelOfferSubscription: 'Suscripción MKP',
        masterLabelCredential: 'Credenciales Mkp',
        name: 'Nombre',
        service: 'Servicio'
      },
      gls: 'Gls',
      hermes: 'Hermes UK',
      kuehne: 'Kuehne',
      laposte: 'Colissimo',
      lettreSimple: 'LettreSimple',
      mondialRelay: 'MondialRelay',
      returnAddress: 'Dirección de devolución',
      returnAddressCreation: {
        consequences: 'Esta dirección se utilizará para todas las etiquetas creadas para esta configuración de etiquetas.'
      },
      sda: 'SDA',
      senderAddress: 'Dirección del remitente',
      senderAddressCreation: {
        consequences: 'Esta dirección se utilizará para todas las etiquetas creadas para esta configuración de etiquetas.'
      },
      storePickup: 'Recogida en tienda',
      tnt: 'TNT',
      ups: 'UPS'
    },
    addressLabelSummary: {
      details: 'Detalles',
      fields: {
        carrierName: 'transportista',
        country: 'país de destino',
        createdAt: 'fecha de generación',
        details: '',
        id: 'id',
        merchantCountry: 'país remitente',
        shippedAt: 'fecha de envío',
        shippingRef: 'número de seguimiento',
        totalWeight: 'peso',
        zip: 'código postal'
      },
      heading: 'Etiqueta proporcionada {{id}}'
    },
    addressLabels: {
      alreadySentToCarrier: 'Ya enviado al transportista',
      canceledAddressLabel: 'Etiqueta cancelada',
      deliverySlip: 'Albarán de entrega',
      disapproveAssociatedEntry: 'Cancelar',
      disapproveAssociatedEntryConsequences: 'Esta etiqueta no se incluirá en el siguiente albarán.',
      notSendableToCarrier: 'La etiqueta no se puede cancelar'
    },
    advancedSearch: {
      fields: {
        condition: 'Condición',
        field: 'Campo',
        table: 'Relación',
        value: 'Valor'
      },
      title: 'Búsqueda avanzada'
    },
    apiClient: {
      fields: {
        apeNumber: 'Número APE',
        city: 'ciudad',
        companyForm: 'Forma jurídica',
        companyName: 'nombre de la empresa',
        companyRegister: 'Registro de Comercio y Sociedades / Directorio de Comercio',
        country: 'código del país',
        email: 'correo electrónico',
        firstname: 'nombre',
        frontEmail: 'correo electrónico visible por los clientes',
        id: 'id sbo',
        lastname: 'apellido',
        mobilePhone: 'móvil',
        nafNumber: 'Número NAF',
        phoneNumber: 'teléfono fijo',
        shareCapitalCents: 'Capital social (céntimos)',
        shareCapitalCurrency: 'Capital social (moneda)',
        sirenNumber: 'SIRENA',
        siret: 'SIRET',
        sourceRef: 'ref',
        state: 'Estado',
        street: 'calle',
        vatNumber: 'Número de IVA',
        websiteUrl: 'URL del sitio web',
        zip: 'código postal'
      },
      invoices: 'Facturas',
      updatePaymentInformation: 'Actualizar la información de pago'
    },
    application: {
      by: 'por',
      copyright: '©',
      craftedWith: 'Fabricado con',
      navigation: {
        toggleMenubar: 'Alternar menú',
        toggleNav: 'Alternar la navegación'
      },
      pageIsLoading: 'La página está cargando',
      shippingbo: 'Shippingbo'
    },
    ariaLabels: {
      close: 'Cerrar'
    },
    atomicFormula: {
      create: 'Añadir una condición',
      creator: 'Crear una nueva condición atómica',
      explanation: 'Crear una fórmula atómica',
      field: 'campo',
      fields: {
        field: 'Campo',
        operator: 'Operador',
        parentFormulaId: 'Id de la fórmula madre',
        type: 'Tipo',
        value: 'Valor'
      },
      obliterate: 'Borrar',
      obliteration: {
        consequences: 'Esta condición quedará definitivamente eliminada.'
      },
      operator: 'operador',
      priority: 'prioridad',
      value: 'valor'
    },
    button: {
      reload: 'Recarga',
      save: 'Guardar'
    },
    cardboard: {
      creator: 'Añadir una caja de cartón',
      explanation: 'Sólo se requiere referencia',
      fields: {
        height: 'Altura (mm)',
        id: 'Id',
        length: 'Longitud (mm)',
        priceCents: 'Precio (céntimos)',
        priceCurrency: 'Código de moneda',
        ref: 'Referencia',
        stock: 'Stock',
        supplier: 'Proveedor',
        weight: 'Peso (g)',
        width: 'Anchura (mm)'
      }
    },
    close: 'Cerrar',
    cn23Editor: {
      packageCategory: 'Información sobre el paquete',
      packageProduct: 'Línea de productos {{index}}',
      packageProducts: 'Información sobre los productos',
      shippingPrice: 'Precio del envío'
    },
    collectionTable: {
      aria: 'Tabla de navegación'
    },
    columnSelector: {
      open: 'Columnas',
      title: 'Configurar columnas'
    },
    companyIdentification: {
      administrativeFields: 'Información administrativa',
      associationArticles: 'Estatutos',
      bankStatement: 'Datos bancarios de la empresa (prueba del IBAN)',
      companyMembers: 'Miembros de la empresa',
      genericDocType: 'Documento de empresa',
      heading: 'Información sobre la identidad de la empresa',
      incorporationCertificate: 'Certificado de incorporación',
      shareCapitalDistribution: 'Distribución del capital social'
    },
    companyIdentificationDocument: {
      iban: 'IBAN'
    },
    companyMember: {
      creator: 'Añadir un miembro a la empresa',
      explanation: 'Elija el tipo de afiliado que desea añadir',
      fields: {
        displayableType: 'Tipo de miembro',
        email: 'Correo electrónico',
        firstname: 'Nombre',
        lastname: 'Apellido',
        type: 'Tipo'
      },
      genericType: 'Miembro de la empresa',
      identityCard: 'Tarjeta de identidad',
      identityInformation: '{{type}} {{id}}: información general',
      passport: 'Pasaporte',
      types: {
        legalRepresentative: 'Representante legal',
        shareholder: 'Accionista'
      }
    },
    companyMemberDocument: {
      fields: {
        country: 'País'
      }
    },
    config: {
      basicMkpConfig: {
        agree: 'Aceptar el CGU',
        downloadAgreement: 'Descargar CGU',
        heading: 'Cliente marketplace de Shippingbo',
        userAgreementAccepted: 'Condiciones de uso aceptadas'
      },
      invoices: {
        additionalLanguages: 'Idiomas adicionales',
        autoEmail: 'Correo electrónico automatizado',
        ccEmail: 'Correo CC',
        emailBody: 'Correo electrónico',
        heading: 'Configuración de facturas',
        legalText: 'Texto legal',
        logoUrl: 'Logotipo de la factura (Altura: 100px, Anchura máxima 360px)',
        otherFeesTaxRate: 'Tasa de impuesto por defecto sobre otras tasas',
        productTaxRate: 'Tasa de impuesto por defecto',
        shippingTaxRate: 'Tasa de impuesto de envío por defecto',
        startingInvoiceRef: 'Primer número de factura',
        wrapTaxRate: 'Tasa de impuesto por defecto por envase'
      },
      logistic: {
        advancedInsurance: 'Valores por defecto del seguro avanzado',
        autoClickPrintButton: 'Pulse automáticamente el botón de impresión cuando el paquete esté listo',
        autoFillAddress: 'Cumplimentación automática de direcciones',
        autoFillPackage: 'Cumplimentación automática de la información sobre paquetes',
        autoPrintEnabled: 'Activar la impresión automática de etiquetas',
        defaultEmail: 'Correo electrónico utilizado en el autocompletado',
        defaultHsCode: 'Código SH por defecto',
        defaultPackageDescription: 'Descripción utilizada para enviar paquetes en Europa',
        defaultPhone: 'Teléfono utilizado en el autocompletado',
        defaultScanningField: 'Campo preseleccionado al escanear el código de barras del albarán',
        defaultUnitWeight: 'Peso unitario utilizado en la autocompletación',
        heading: 'Configuración logística',
        minValueForInsurance: 'Valor mínimo del seguro en etiquetas (en céntimos de {{curr}})',
        subHeading: 'Configuración logística',
        superSimpleCn23: 'CN23 super simple',
        warning: {
          addressCompletion: 'El autocompletado se aplicará a todas las direcciones para las que falte información.',
          packageCompletion: 'El autocompletado se aplicará a todos los paquetes cuyo peso total no pueda resolverse.'
        }
      },
      notif: {
        email: 'Dirección de correo electrónico',
        enabled: 'Activado',
        explanation: 'Aquí puede configurar la recepción de correos electrónicos cuando se añade un mensaje a un pedido.',
        heading: 'Notificaciones por correo electrónico',
        language: 'Idioma',
        level: 'Nivel mínimo para el envío de notificaciones',
        subHeading: 'Aquí puede configurar las notificaciones por correo electrónico'
      },
      orderToCarrierMappingRuleBook: {
        defaultCarrier: 'Transportista por defecto',
        heading: 'Transportista por defecto',
        rules: 'Mapping transportistas'
      },
      pack: {
        heading: 'Packs',
        packField: 'Campo utilizado para determinar los paquetes',
        subHeading: 'Aquí puede configurar los paquetes'
      },
      packingSlip: {
        banner: 'Banner (PNG, anchura: 820px máx.)',
        checkboxEnabled: 'Casilla de verificación',
        clientEmailEnabled: 'Correo electrónico del cliente',
        columns: 'Activar / desactivar opciones del albarán',
        customOrderLogo: 'Logotipo de origen personalizado (PNG, ancho: 70px, alto: 33px)',
        customerHistoryEnabled: 'Historial del cliente',
        footer: 'Pie de página (PNG, anchura: 820px máx.)',
        header: 'Encabezado (PNG, anchura: 360px máx.)',
        heading: 'Personalizar los albaranes',
        locationEnabled: 'Ubicación',
        misc: 'Otras configuraciones',
        pictureEnabled: 'Fotografía',
        pricesEnabled: 'Precio del pedido',
        productBarcodeEnabled: 'Código de barras del producto',
        productStockEnabled: 'Stock actual del producto',
        skuEnabled: 'SKU',
        subHeading: 'Personalizar los albaranes',
        titleEnabled: 'Título',
        useProductCountInOrdering: 'Ordenar por el número de productos'
      },
      stock: {
        decrementOnOrder: 'Reducir el stock cuando hay un pedido',
        heading: 'Stock',
        subHeading: 'Aquí puede gestionar los stocks'
      }
    },
    configs: {
      apiClient: 'Cuenta cliente',
      basicMkpConfig: 'Cliente marketplace',
      cardboards: 'Cartones',
      clientInformation: {
        necessaryFields: 'Información obligatoria',
        optionalFields: 'Información opcional',
        subscription: 'Su suscripción'
      },
      companyIdentification: 'Información de identificación',
      invoicesConfig: 'Configuración de facturas',
      label: 'Cuentas de etiquetas de direcciones',
      labelProvider: 'Cuenta del vendedor',
      labelProviderInformation: {
        necessaryFields: 'Información obligatoria',
        optionalFields: 'Información opcional'
      },
      logisticConfig: 'Logística',
      notif: 'Notificaciones por correo electrónico',
      orderToCarrierMappingRuleBook: 'Mapping transportistas',
      pack: 'Configuración de los paquetes',
      packingSlipConfig: 'Configuración de los albaranes',
      returnLabel: 'Cuentas de etiquetas de devolución',
      stock: 'Configuración de stock'
    },
    customOrderLogo: {
      creator: 'Añadir un nuevo logotipo personalizado',
      explanation: 'El valor de origen se comparará con el valor de origen del pedido',
      fields: {
        originValue: 'Valor de origen'
      }
    },
    dashboard: {
      dailyNumbers: 'Las estadísticas del día',
      orderNumber: 'Resumen del estado de los pedidos en los últimos {{period}} días',
      orderState: {
        fresh: {
          sentenceEnding: 'recibido'
        },
        inPreparation: {
          sentenceEnding: 'no preparado'
        },
        preparing: {
          sentenceEnding: 'en preparación'
        },
        shipped: {
          sentenceEnding: 'enviado'
        },
        toBePrepared: {
          sentenceEnding: 'a preparar'
        },
        waiting: {
          sentenceEnding: 'a preparar'
        }
      }
    },
    date: {
      format: {
        long: 'dddd A MMMM AAAA',
        short: 'DD/MM/AAAA'
      },
      weekDate: {
        1: 'Lunes',
        2: 'Martes',
        3: 'Miércoles',
        4: 'Jueves',
        5: 'Viernes',
        6: 'Sábado',
        7: 'Domingo'
      }
    },
    destroyObject: {
      consequences: '¿Está seguro que desea eliminar esta entrada ?',
      title: 'Eliminar'
    },
    history: {
      after: 'Después',
      before: 'Antes',
      blame: 'Hecho por',
      blameOrder: 'Pedido {{id}}',
      date: 'Fecha',
      field: 'Campo',
      title: 'Histórico de cambios'
    },
    insuranceEditor: {
      insuranceValue: 'Valor asegurado'
    },
    invoices: {
      labels: 'Etiquetas',
      returnLabels: 'Etiquetas de devolución',
      title: 'Facturas'
    },
    labelConfigTemplate: {
      creator: 'Configuración por defecto de la etiqueta',
      explanation: 'Añade una configuración de etiqueta por defecto. Se desplegará automáticamente al suscribirse a esta oferta.',
      fields: {
        name: 'Nombre',
        service: 'Servicio'
      }
    },
    labelConfigTemplates: {
      title: 'Configuración por defecto de las etiquetas'
    },
    labelCredential: {
      configNotVisibleExplanation: 'No se puede configurar los accesos transportadores : ya estan asociados a accesos proporcionados.',
      configure: 'Configuración accesos transportadores - ADVERTENCIA: puede ser compartida',
      creation: {
        explanation: 'Podrá añadir los accesos posteriormente, accediendo a la página de credenciales.'
      },
      creator: 'Crear accesos de transportista',
      details: {
        config: 'Configurar acceso de transportista {{baseType}}: {{id}} ({{name}})',
        main: 'Acceso del transportista {{baseType}}: {{id}} ({{name}})'
      },
      fields: {
        baseType: 'Transportista',
        createdAt: 'Creado en',
        details: '',
        id: 'Id',
        name: 'Nombre',
        providedLabelCredentialId: 'Id. de credencial proporcionada',
        providedLabelCredentialName: 'Nombre de la credencial proporcionada',
        ready: 'Listo',
        type: 'Transportista'
      }
    },
    labelOffer: {
      configuration: 'Configuración',
      creator: 'Crear una nueva oferta de etiqueta',
      credential: 'Acceso del transportista',
      explanation: 'Más adelante podrá añadir los datos de la oferta de etiquetas',
      fields: {
        createdAt: 'creado en',
        details: '',
        displayableType: 'tipo de oferta',
        id: 'id',
        labelCredential: 'acceso transportista',
        labelCredentialName: 'acceso transportista',
        name: 'nombre',
        providedLabelOfferId: 'oferta de etiqueta proporcionada',
        providedLabelOfferName: 'oferta de etiqueta proporcionada',
        receiverCountry: 'países receptores (FR, UE...)',
        senderCountry: 'países remitentes (FR, UE...)',
        type: 'tipo de oferta'
      },
      heading: 'Oferta {{id}}: {{name}}',
      info: {
        inactive: 'Esta oferta aún no ha sido activada. Una vez activada, podrá desactivarla. La desactivación impedirá cualquier nueva suscripción. Los abonados actuales podrán seguir utilizándola.'
      },
      labelOfferOptions: 'Opciones',
      showPrices: 'Mostrar precios',
      types: {
        basic: 'Básico',
        credentialResale: 'Acceso solo',
        firstMile: 'Hub'
      }
    },
    labelOfferOption: {
      creator: 'Añadir una nueva opción',
      explanation: 'Elija un servicio',
      fields: {
        archived: 'archivo',
        insuranceEnabled: 'seguro activado',
        onlyUseIfZipCodeAvailable: 'utilizar unicamente si el código postal está disponible para la oferta',
        providedLabelOfferOptionId: 'opción vinculada',
        providedLabelOfferOptionName: 'opción vinculada',
        receiverCountry: 'destinos (FR, UE...)',
        service: 'servicio',
        zipPrefix: 'prefijo zip'
      },
      heading: 'Opción {{id}}: {{service}}'
    },
    labelOfferSubscription: {
      creator: 'Suscribirse a una oferta de transporte',
      explanation: 'Seleccione una oferta',
      fields: {
        labelOffer: 'Oferta'
      }
    },
    labelPricingTable: {
      details: 'Estado',
      heading: 'Tabla de precios para la opción {{optionId}}',
      id: 'id',
      mappings: 'Tabla de precios',
      processingStatus: 'tramitación'
    },
    labelPrinter: {
      addressInvalid: 'La dirección de envío tiene errores. Por favor, compruébelos.',
      associatedConfig: {
        noLongerReady: 'La configuración de etiqueta asociada ya no está lista para imprimirse. Puede liberar el paquete haciendo clic en Deseleccionar.'
      },
      checkAddress: 'Compruebe la dirección de envío antes de imprimir',
      checkPackage: 'Compruebe los detalles del paquete antes de imprimir',
      checkPickUpLocationRef: 'Comprobar la referencia del lugar de recogida',
      chooseConfig: 'Elija el transportista para este paquete',
      cn23Editor: 'Editar información para la generación CN23',
      dimensionsEditor: 'Editar las dimensiones del envase',
      emcOperator: 'Elija un operador y un nivel de servicio',
      errors: {
        label: 'Error al generar la etiqueta, compruebe la dirección y, a continuación, la configuración de la etiqueta.',
        timeOut: 'La generación de etiquetas ha caducado',
        timeOutWithRef: 'La generación de la etiqueta {{ref}} ha caducado',
        validation: 'Error al notificar a la aplicación que imprimió la etiqueta. Compruebe los mensajes de error.'
      },
      generationInProgress: 'Espere mientras se genera la etiqueta',
      insuranceEditor: 'Edite la información para el seguro, si desea asegurar el envío.',
      labelMessages: 'Información importante del transportista',
      noReadyConfig: 'No hay ninguna cuenta transportista lista para imprimir.',
      packageDescription: 'Descripción del paquete',
      packageInvalid: 'La información del paquete contiene errores. Por favor, compruébelos.',
      partsCount: 'Especifique el número de paquetes de este palé',
      pickUpLocationRef: 'Referencia del lugar de recogida',
      productsPriceEditor: 'Editar información sobre el valor declarado del paquete',
      requestedShippingDate: 'Seleccione una fecha de carga',
      unselect: 'Deseleccionar',
      weightRequired: 'Es necesario rellenar el peso para generar la etiqueta'
    },
    labelProvider: {
      fields: {
        apeNumber: 'Número APE',
        city: 'Ciudad',
        companyForm: 'Forma jurídica',
        companyName: 'Nombre de la empresa',
        country: 'Código del país',
        email: 'Correo electrónico',
        firstname: 'Nombre',
        frontEmail: 'Correo electrónico dirigido al cliente',
        lastname: 'Apellido',
        mobilePhone: 'Teléfono móvil',
        nafNumber: 'Número NAF',
        phoneNumber: 'Número de teléfono',
        shareCapitalCents: 'Capital social (céntimos)',
        shareCapitalCurrency: 'Capital social (moneda)',
        sirenNumber: 'SIRENA',
        siret: 'SIRET',
        street: 'Calle',
        vatNumber: 'Número de IVA',
        websiteUrl: 'URL del sitio web',
        zip: 'Código postal'
      },
      invoices: 'Facturas',
      updatePaymentInformation: 'Actualizar la información de pago'
    },
    language: {
      english: 'Inglés',
      french: 'Francés'
    },
    lemonBankAccount: {
      creator: 'Añadir cuenta bancaria',
      explanation: 'Cuenta bancaria en la cual se podrá realizar los retiros',
      fields: {
        bankBranchCity: 'ciudad de la sucursal bancaria',
        bankBranchStreet: 'calle de la sucursal bancaria',
        bankName: 'nombre del banco',
        bic: 'bic',
        country: 'país',
        holderFullname: 'nombre completo del titular',
        iban: 'iban'
      },
      iban: 'IBAN',
      lemonRef: 'Limón ref',
      waitingForLemonRegistration: 'Validación en curso'
    },
    level: {
      error: 'Error',
      info: 'Información'
    },
    logisticConfig: {
      fields: {
        closing: 'Cerrar',
        fixing: 'Fijar',
        material: 'Material',
        protection: 'Protección interna',
        wrap: 'Envolver'
      }
    },
    main: {
      activate: 'Activar',
      add: 'Añadir',
      addressLabelSummaries: 'Etiquetas suministradas',
      areYouSure: '¿Seguro?',
      cancel: 'Cancelar',
      cancelDangerousAction: 'No hacerlo',
      cardboards: 'Cartones',
      config: 'Configurar',
      configs: 'Configuración',
      copy: 'Copia',
      create: 'Crear',
      createOrder: 'Crear pedido',
      dashboard: 'Dashboard',
      deactivate: 'Desactivar',
      delete: 'Borrar',
      doDangerousAction: 'Estoy seguro',
      download: 'Descargar',
      edit: 'Editar',
      false: 'No',
      fillInTracking: 'Número de seguimiento',
      finish: 'Acabado',
      generateLabel: 'Generar etiqueta',
      generating: 'Generación en curso...',
      impersonate: 'Tomar el control',
      labelCredentials: 'Acceso del transportista',
      labelOffers: 'Ofertas de etiquetas',
      leave: 'Abandonar',
      loading: 'Cargando...',
      logout: 'Cierre de sesión',
      monitor: 'Verificar',
      navigation: 'Navegación',
      next: 'Siguiente',
      null: '',
      order: 'Pedido',
      orderItemProductMappings: 'Asignaciones',
      orders: 'Pedidos',
      preparationRuns: 'Logística',
      preparationSearches: 'Preparar a partir de búsquedas guardadas',
      prepare: 'Preparar',
      previous: 'Anterior',
      print: 'Imprimir',
      productPacks: 'Packs',
      products: 'Productos',
      providedClients: 'Clientes',
      refresh: 'Actualizar',
      reportConfigs: 'Informes',
      reset: 'Reinicializar filtros',
      save: 'Guardar',
      saveSearch: 'Guardar búsqueda',
      search: 'Buscar en',
      serviceCredentials: 'Servicios externos',
      topbar: {
        documentation: 'Ver los tutoriales',
        linksupport: 'Contactar con el equipo soporte'
      },
      true: 'Sí',
      uploads: 'Archivos',
      validate: 'Validar',
      wallet: 'Cartera ShippingBo'
    },
    mappedProduct: {
      fields: {
        location: 'Ubicación',
        price: 'Precio (impuestos incluidos)',
        quantity: 'Cantidad',
        sku: 'SKU',
        title: 'Título'
      }
    },
    mapping: {
      addField: 'Añadir un campo',
      key: 'Clave',
      knownFieldName: {
        state: 'Estado en Shippingbo',
        updaterCarrier: 'Transportista de cierre',
        updaterCarrierName: 'Estado de cierre del transportista',
        updaterState: 'Estado de cierre'
      },
      name: 'Asignaciones',
      value: 'Valor,'
    },
    mappingRule: {
      create: 'Crear una nueva regla de asignación',
      creator: 'Crear una nueva regla de asignación',
      enabled: 'Activado',
      explanation: 'Confirme que desea crear una nueva regla',
      fields: {
        name: 'Regla #{{priority}} => {{name}}',
        operator: 'Operador principal',
        ruleBookId: 'Id de la regla de asignación',
        sourceType: 'Asignación a(n)',
        targetId: 'Objetivo',
        targetType: 'a a(n)'
      },
      obliterate: 'Borrar regla de asignación',
      obliteration: {
        consequences: 'La regla de asignación se eliminará.'
      },
      operator: {
        meaning: {
          and: 'Si se cumplen todas las condiciones siguientes'
        }
      },
      priority: 'prioridad',
      targetType: {
        carrier: 'Asignar el siguiente transportista'
      }
    },
    message: {
      markAsRead: 'Acuse recibo'
    },
    mkpBuyer: {
      labelOfferSubscriptions: 'Suscripciones a la oferta de transporte'
    },
    mondialRelay: {
      button: 'Elija un punto de recogida de Mondial-Relay'
    },
    nA: 'N/A',
    navettePickUp: {
      beginningHoliday: 'Fecha de inicio de las vacaciones',
      choosePudo: 'Elija un punto de recogida',
      configurationTitle: 'Búsqueda avanzada',
      endingHoliday: 'Fecha final de vacaciones',
      holiday: 'Vacaciones',
      openingHours: 'Horario de apertura',
      pudoDetails: 'Detalles del punto de recogida',
      pudoField: {
        address1: 'Dirección',
        address2: 'Dirección(2)',
        address3: 'Dirección(3)',
        available: 'Disponible',
        city: 'Ciudad',
        countryCode: 'Código del país',
        handicapes: 'Acceso para discapacitados',
        id: 'Id',
        name: 'Nombre',
        parking: 'aparcamiento',
        zipCode: 'Código postal'
      },
      pudoId: 'Id del punto de recogida actual',
      pudoSearchResult: 'Resultado para la dirección actual {{street}}',
      title: 'selección del punto de recogida'
    },
    order: {
      create: {
        confirmContent: 'El pedido no se podrá editar una vez creado',
        confirmTitle: 'Confirmación del pedido',
        details: 'Detalles',
        discountPrice: 'Descuento',
        duplicateBilling: 'Copiar la dirección de facturación',
        duplicateShipping: 'Copiar la dirección de envío',
        hidePreview: 'Ocultar vista previa',
        itemPriceTaxIncludedCents: 'Precio (impuestos incluidos, céntimos)',
        noAddress: 'Ninguno',
        orderSummary: 'Resumen del pedido',
        otherFees: 'Otras tasas',
        shippingPrice: 'Precio del envío',
        showPreview: 'Preestreno',
        title: 'Crear orden',
        wrapPrice: 'Costes de embalaje '
      },
      details: {
        deliveredAtDate: 'Fecha de entrega',
        deliveryDate: 'Fecha de entrega solicitada',
        discount: 'Descuento',
        displayCurrencyLeft: 'falso',
        estimatedAfter: 'Después de',
        estimatedAnd: 'y',
        estimatedBefore: 'Antes de',
        estimatedBetween: 'Entre',
        missingAddress: 'Dirección desconocida',
        noMappedCarrier: 'Ninguno',
        otherFees: 'Otras tasas',
        price: 'Precio',
        priceDecimalSeparator: '.',
        priceHundredsSeparator: ',',
        priceTTC: 'Impuestos incluidos',
        priceTaxIncluded: 'Impuestos incluidos',
        priceTaxIncludedCents: 'Impuesto incluido (céntimos)',
        productsPrice: 'Productos',
        recompute: {
          and: 'y',
          matchingFields: 'Se utilizará el primer campo que coincida.',
          warning: 'Los elementos del pedido se emparejan por {{list}}, en este orden.'
        },
        shippedAtDate: 'Fecha de envío',
        shippingDate: 'Fecha de envío solicitada',
        shippingPrice: 'Envío',
        totalPrice: 'Total',
        unknownCurrency: 'Moneda desconocida',
        wrapPrice: 'Envolver'
      },
      errors: {
        cannotBeRemovedFromRun: 'Fallo en la eliminación del proceso de preparación',
        failedToRecomputeMappedProducts: 'Fallo al recalcular productos mapeados',
        failedToRegenerateInvoice: 'Error al regenerar una factura'
      },
      fields: {
        allLinesAreMapped: 'Todas las líneas tienen productos',
        billingAddressId: 'Dirección de facturación',
        chosenDeliveryService: 'Código del transportista',
        clientRef: 'Referencia del cliente',
        customState: 'Estado personalizado',
        details: '',
        earliestDeliveryAt: 'Entrega después de',
        earliestShippedAt: 'Envío después de',
        fulfilledByMarketplace: 'Cumplido por MKP',
        id: 'Id',
        invoiceRef: 'Ref. factura',
        latestDeliveryAt: 'Entrega antes de',
        latestShippedAt: 'Envío antes de',
        origin: 'Origen',
        originCreatedAt: 'Fecha del pedido',
        originRef: 'Referencia original',
        packageId: 'Id de paquete',
        preparationRunId: 'Id de ejecución de pedido',
        productCount: 'Número de productos',
        productsOtherRef1: 'Otros ref1 productos',
        productsOtherRef2: 'Otros ref2 productos',
        productsOtherRef3: 'Otros ref3 productos',
        relayRef: 'Referencia del punto de recogida',
        shippedAt: 'Fecha de envío',
        shippingCountry: 'País de destino',
        shippingRequiresCn23: 'Requiere CN23',
        shippingToDomTom: 'Envío a DOM TOM',
        shippingToEu: 'Envío dentro de la UE',
        shippingZip: 'Código postal',
        source: 'Fuente',
        sourceRef: 'Referencia del fuente',
        state: 'Estado',
        tagValues: 'Lista de los tags',
        totalDiscountTaxIncludedCents: 'Total impuesto de descuento incluido (céntimos)',
        totalItemPriceCents: 'Precio total del artículo sin impuesto (céntimos)',
        totalItemPriceTaxIncludedCents: 'Precio total del artículo impuestos incluidos (céntimos)',
        totalItemTaxCents: 'Total impuesto sobre artículos (céntimos)',
        totalMarketplaceFeesCents: 'Total tasas de mercado (céntimos)',
        totalMarketplaceFeesTaxCents: 'Impuesto total del mercado (céntimos)',
        totalMarketplaceFeesTaxIncludedCents: 'Total impuesto de mercado incluido (céntimos)',
        totalOtherFeesCents: 'Total otras tasas (céntimos)',
        totalOtherFeesTaxCents: 'Total otras tasas impuestas (céntimos)',
        totalOtherFeesTaxIncludedCents: 'Total otras tasas impuestos incluidos (céntimos)',
        totalPriceCents: 'Precio total (céntimos)',
        totalPriceCurrency: 'Moneda',
        totalShippingCents: 'Total envío (céntimos)',
        totalShippingTaxCents: 'Total impuestos de envío (céntimos)',
        totalShippingTaxIncludedCents: 'Total impuestos de envío incluidos (céntimos)',
        totalTaxCents: 'Impuesto total (céntimos)',
        totalWeight: 'Peso total (g)',
        totalWithoutTaxCents: 'Total sin impuestos (céntimos)',
        totalWrapCents: 'Total embalaje sin impuestos (céntimos)',
        totalWrapTaxCents: 'Impuesto total de embalaje (céntimos)',
        totalWrapTaxIncludedCents: 'Total impuesto de embalaje incluido (céntimos)'
      },
      recomputeMappedProducts: 'Recalcular productos mapeados',
      regenerateInvoice: 'Regenerar una factura',
      removeFromRun: {
        buttonTitle: 'Retirar de la preparación',
        explanation: 'Este pedido se eliminará definitivamente de esta sesión de preparación.',
        title: 'Eliminar el pedido de la sesión'
      },
      state: {
        atPickupLocation: 'en el punto de recogida',
        backFromClient: 'devuelto por el cliente',
        canceled: 'cancelado',
        closed: 'entregado',
        handedToCarrier: 'entregado al transportista',
        inPreparation: 'en preparación',
        inTrouble: 'en error',
        merged: 'fusionado',
        null: '',
        partiallyShipped: 'parcialmente enviado',
        rejected: 'rechazado',
        sentToLogistics: 'enviado a logística',
        shipped: 'enviado',
        toBePrepared: 'a preparar',
        waitingForPayment: 'a la espera del pago',
        waitingForStock: 'a la espera de stock'
      },
      success: {
        invoiceIsBeingGenerated: 'Se está generando una nueva factura',
        mappedProductsUpdated: 'Productos mapeados actualizados'
      }
    },
    orderAccepter: {
      fields: {
        enabled: 'Activado'
      }
    },
    orderDocument: {
      creator: 'Generación de etiquetas de devolución',
      explanation: 'Editar la dirección de correo electrónico para la etiqueta de devolución',
      fields: {
        email: 'Correo electrónico'
      },
      reports: {
        package: 'Direcciones'
      },
      shipment: {
        danger: 'Error con la notificación de envío',
        success: 'Exito con la notificación de envío'
      },
      types: {
        invoice: 'Factura',
        packingSlip: 'Albarán',
        returnLabel: 'Etiqueta de devolución'
      }
    },
    orderEvent: {
      creator: 'Añadir un mensaje',
      explanation: 'Añadir un mensaje sobre este pedido',
      fields: {
        level: 'Nivel',
        message: 'Mensaje',
        title: 'Título'
      }
    },
    orderFetcher: {
      fields: {
        fetcherEnabled: 'Activado',
        lastUpdatedAtInApiFmt: 'Fecha de inicio (UTC)'
      }
    },
    orderItem: {
      fields: {
        productRef: 'Referencia del vendedor',
        productSource: 'Origen del producto',
        productSourceRef: 'Referencia de la fuente del producto',
        title: 'Título'
      },
      name: 'Línea de pedidos'
    },
    orderItemProductMapping: {
      creator: 'Añadir una asignación',
      explanation: 'Puede crear asignaciones para asignar artículos de los pedidos a sus productos',
      fields: {
        cdiscountPrice: 'Precio en CDiscount (céntimos)',
        destroy: '',
        id: 'Id',
        matchedQuantity: 'Cantidad en el lote',
        orderItemField: 'Campo de línea de pedido',
        orderItemSource: 'Fuente del pedido',
        orderItemValue: 'Referencia adicional',
        packProductId: 'Id del producto del envase',
        productField: 'Campo del producto',
        productValue: 'Valor del campo de producto',
        spartooPartnaireRef: 'Spartoo ref.'
      },
      title: 'Referencias de productos'
    },
    orderState: {
      change: {
        warning: 'ATENCIÓN: Este cambio de estado no se propagará al origen del pedido. ¿Está seguro de que desea continuar?'
      }
    },
    orderTag: {
      creator: 'Añadir un tag',
      explanation: 'Añadir un tag a este pedido para poder filtrarlo',
      fields: {
        value: 'tag'
      },
      name: 'Tag'
    },
    orderToAddressLabelConfigMapping: {
      fields: {
        addressLabelConfigId: 'Cuenta transportador'
      },
      name: 'Transportador calculado'
    },
    orderUpdater: {
      fields: {
        inPreparationEnabled: 'Notificar cuando esté en preparación',
        inTroubleEnabled: 'Avisar en caso de problemas',
        shippedEnabled: 'Notificar al servicio el envío del pedido'
      }
    },
    orders: {
      details: {
        billingAddress: 'Dirección de facturación',
        details: 'Detalles',
        documents: 'Documentos',
        genReturnLabel: 'Generar etiqueta de devolución',
        mappedProducts: 'Productos',
        orderEvents: 'Mensajes',
        printPs: 'Generar albarán',
        sendToClogistique: 'Enviar a Clogistique',
        sendToFba: 'Enviar a FBA',
        sendToLogistics: {
          error: 'Hubo un error al programar el envío',
          success: 'Pedido programado para envío'
        },
        shipping: 'Envío',
        shippingAddress: 'Dirección de envío',
        tags: 'Tags',
        title: 'Pedido {{id}}'
      },
      invoice: {
        printPs: 'Generar factura'
      },
      shipments: {
        addressLabelUrl: 'Etiqueta',
        carrier: 'Transportista',
        generateReturnLabel: 'Generar etiqueta de devolución',
        method: 'Método',
        otherDoc: 'Documento',
        packageId: 'Id paquete',
        parcelStatus: 'Estado del paquete',
        pickingControlValidated: 'Elegir',
        returnLabelGeneration: 'Generar etiqueta de devolución',
        shippedAt: 'Enviado a',
        shippingRef: 'Número de seguimiento.',
        unknownUser: 'Desconocido',
        user: 'Usuario'
      }
    },
    packComponent: {
      creator: 'Añadir producto a su pack',
      explanation: 'Puede añadir productos a su pack',
      fields: {
        componentProductId: 'Id. de producto del componente',
        location: 'ubicación',
        quantity: 'Cantidad por envase',
        title: 'Título',
        userRef: 'Referencia (SKU)'
      },
      title: 'Añadir en el pack'
    },
    package: {
      createShipment: 'Guardar información de seguimiento',
      errors: {
        failedSubpacksCreation: 'Error al crear subpaquetes'
      },
      failureHeader: 'Fallo',
      failureMessage: 'No se ha podido guardar la información de envío.',
      fields: {
        cardboard: 'Referencia de cartón',
        closing: 'Cerrar',
        description: 'Descripción',
        fixing: 'Fijación',
        height: 'Altura (mm)',
        insuranceValueCents: 'Valor asegurado (céntimos)',
        insuranceValueCurrency: 'Moneda de valor asegurado',
        length: 'Longitud (mm)',
        material: 'Material',
        packageCategory: 'Información sobre el paquete',
        packageProducts: 'Líneas de productos',
        partsCount: 'Número de paquetes de este palé',
        pickUpLocationRef: 'Referencia del punto de recogida',
        productsPriceCents: 'Valor declarado (céntimos)',
        productsPriceCurrency: 'Moneda de valor declarado',
        protection: 'Protección interna',
        requestedShippingDate: 'Fecha de envío solicitada',
        shippingPriceCents: 'Valor del precio de envío (céntimos)',
        shippingPriceCurrency: 'Moneda del precio de envío',
        totalQuantity: 'Cantidad total',
        totalWeight: 'Peso total (gramos)',
        width: 'Anchura (mm)',
        wrap: 'Embalaje'
      },
      fillInTracking: 'Rellene un número de seguimiento para el paquete {{id}}',
      generateLabel: 'Generar una etiqueta para el paquete {{id}}',
      searchMethods: 'Buscar un método de envío:',
      seeOrder: 'Ver el pedido correspondiente',
      shippingRef: 'Número de seguimiento',
      subPackage: 'Subpaquete {{count}}',
      successHeader: 'Éxito',
      successMessage: 'La información de envío se ha guardado correctamente.',
      totalPackages: 'Total de paquetes necesarios',
      waitingForItsTurn: 'Paquete a la espera de que se terminen los anteriores'
    },
    packageCategory: {
      fields: {
        importersContact: 'Contacto del importador',
        importersReference: 'Referencia del importador',
        officeOrigin: 'Oficina de origen',
        value: 'Tipo de paquete'
      },
      value: {
        1: 'Regalo',
        2: 'Muestra',
        3: 'Envío comercial',
        4: 'Documento',
        5: 'Otros',
        6: 'Devolución'
      }
    },
    packageCreator: {
      packageLabel: 'Generar etiqueta',
      packageTracking: 'Número de seguimiento'
    },
    packageLabel: {
      fields: {
        carrier: 'Transportista',
        errorMessage: 'Detalles del error',
        errorType: 'Error',
        packageId: 'Id de paquete',
        shippingRef: 'Número de seguimiento',
        state: 'Estado'
      },
      fixMe: 'Modificar el paquete',
      notifyShipment: 'Marcar como enviado',
      orderId: 'Id de pedido',
      ready: 'Generado',
      reschedule: 'Reintentar generar etiqueta',
      waiting: 'En espera',
      withErrors: 'En error'
    },
    packageProduct: {
      creator: 'Añadir una línea de productos a la CN23',
      explanation: 'Aquí puede añadir una línea de productos al documento CN23',
      fields: {
        description: 'Descripción',
        hsCode: 'Código SH',
        originCountry: 'País de origen',
        quantity: 'Cantidad',
        unitValueCents: 'Precio unitario (céntimos)',
        unitValueCurrency: 'Moneda',
        weight: 'Peso unitario (g)'
      }
    },
    packingInfo: {
      creator: 'Añadir información de preparación',
      explanation: 'Se añadirá al producto en el albarán de preparación',
      fields: {
        clientRef: 'Referencia del cliente',
        displayOrder: 'Orden de visualización',
        id: 'id',
        value: 'valor'
      }
    },
    packingSlip: {
      carrier: 'Transportista',
      explainCarrier: 'Al elegir aquí una cuenta de etiquetas, obliga a que cualquier etiqueta para este albarán se genere con esta cuenta. No elija un transportista si desea mantener esta opción abierta.',
      shipPackages: 'Marcar como enviado'
    },
    packingSlipConfig: {
      fields: {
        additionalSkuField: 'Campo sku adicional',
        originRefField: 'Campo de referencia del pedido'
      }
    },
    pickUpLocationDisplay: {
      errors: {
        notImplemented: 'Advertencia. El servicio seleccionado no implementa puntos de recogida.\n A pesar de ello, se ha dado una referencia de punto de recogida ({{ref}}).\n Si imprime una etiqueta con este servicio, se ignorará la referencia.'
      },
      information: {
        noneGiven: 'No se ha dado ninguna referencia de punto de recogida.',
        notImplemented: 'El servicio seleccionado no admite puntos de recogida.'
      }
    },
    pickingControl: {
      checkedQuantity: 'Ya controlado',
      doneWithPackage: 'Productos escaneados correctamente',
      inputLabel: 'SKU o EAN13',
      panelTitle: 'Control picking del subpaquete {{packIndex}}',
      pickingComplete: '',
      productsToPick: 'Productos',
      quantity: 'Cantidad pedida',
      skip: 'Saltar paso de control',
      sku: 'SKU',
      title: 'Título',
      unknownValue: '{{value}} no coincide con ningún producto'
    },
    product: {
      additionalReferences: {
        title: 'Referencias de productos adicionales (50 primeros)'
      },
      creator: 'Nuevo producto',
      details: {
        detailTitle: 'Información sobre el producto',
        packingInfos: 'Información sobre el embalaje',
        title: 'Producto {{id}} {{title}}'
      },
      explanation: 'La referencia es el único campo obligatorio y para el que se impone la unicidad',
      explanationWithSource: 'La fuente identifica el origen de los datos, utilice "Manual" si no tiene una fuente para los productos.<br/>Referencia de fuente es un identificador único entre esta fuente, utilice el SKU por defecto.<br/>',
      name: 'Producto',
      packComponents: {
        notAPack: 'Este producto no es un paquete',
        title: 'Componentes del pack'
      },
      packProduct: {
        title: 'Este producto pertenece a estos paquetes'
      },
      stockVariation: 'Añadir o eliminar stock'
    },
    productFetcher: {
      fields: {
        fetcherEnabled: 'Activado'
      }
    },
    productsPriceEditor: {
      productsPrice: 'Valor declarado'
    },
    providedClient: {
      creation: {
        explanation: 'Rellene los datos del cliente:'
      },
      creator: 'Crear un nuevo cliente',
      details: {
        main: 'Cliente {{ref}} (sbo id: {{id}})'
      },
      fields: {
        city: 'ciudad',
        companyName: 'empresa',
        country: 'país',
        details: '',
        email: 'correo electrónico',
        firstname: 'nombre',
        id: 'sbo id',
        lastname: 'apellido',
        mobilePhone: 'móvil',
        phoneNumber: 'teléfono',
        sourceRef: 'ref',
        state: 'estado',
        street: 'calle',
        zip: 'zip'
      }
    },
    report: {
      creator: 'Nuevo archivo exportado',
      explanation: 'Exportar datos:',
      fields: {
        customEndDate: 'Fin',
        customStartDate: 'Inicio',
        dateBorderedSelected: '',
        requestedShippingDate: 'Fecha de envío solicitada'
      }
    },
    reportConfig: {
      config: {
        encoding: 'Codificación',
        encodingWarning: 'Advertencia sobre la codificación: si la codificación elegida no contiene algunos de los caracteres UTF-8 originales, se sustituirán por un espacio en blanco',
        explanation: 'Configurar las propiedades generales del archivo (nombre, separador csv, codificación)',
        fieldExplanation: 'Puede activar o desactivar las opciones siguientes en el archivo:',
        fieldHeader: 'Configuración de los campos',
        forceQuotes: 'Valores entre comillas',
        header: 'Configuración',
        name: 'Nombre',
        separator: 'Separador',
        showHeader: 'Línea de encabezamiento'
      },
      configure: 'Configure',
      customFields: {
        delete: 'Borrar',
        deleteRcf: {
          error: {
            header: 'Error',
            messages: 'Se ha producido un error desconocido al eliminar la regla personalizada'
          },
          success: {
            header: 'Éxito',
            messages: 'Regla personalizada eliminada correctamente'
          }
        },
        explanation: 'Puede añadir y editar reglas personalizadas para informes.<br>Consisten en que se añaden columnas adicionales en el informe.<br>Puede configurar el encabezado y el valor',
        title: 'Campos personalizados'
      },
      dateBordered: {
        bordered: 'Seleccionar un intervalo de fechas',
        includeAll: 'Incluír todos los elementos desde la última exportación'
      },
      generate: 'Generar un nuevo informe',
      go: 'Informes',
      kuehne: {
        shipmentRequest: {
          generate: 'Solicitar envío'
        }
      },
      noTrigger: 'Este tipo de informe no puede activarse manualmente',
      notYetGenerated: 'Aún no se ha generado el informe',
      options: {
        carrierNameEnabled: 'Nombre de acceso de transportista',
        contractEnabled: 'Mostrar información del contrat',
        fullSourceEnabled: 'Fuente completa',
        labelOfferNameEnabled: 'Nombre de oferta',
        pricesEnabled: 'Precios'
      }
    },
    reportCustomField: {
      creator: 'Crear campo personalizado',
      explanation: 'Añadir campos personalizados a este informe',
      fields: {
        field: 'Cabecera',
        value: 'Valor'
      }
    },
    returnLabelConfig: {
      back: 'Volver a la página anterior',
      fields: {
        name: 'nombre',
        readyToUse: 'Condición de uso aceptada',
        returnLabelCcEmail: 'Correo electrónico en copia'
      },
      linkedConfigNotReady: 'Este transportista no se ha configurado completamente. Póngase en contacto con el servicio de atención al cliente',
      notFullyConfigured: 'Esta configuración está incompleta, por favor rellene todos los campos antes de poder utilizarla.',
      title: 'Configuración de la cuenta de devolución {{name}}'
    },
    returnLabelGenerator: {
      chooseCarrier: {
        configure: 'Configurar',
        create: 'Confirmar',
        modalTitle: 'Compruebe las informaciones del transportista antes de generar la etiqueta',
        title: 'Elija un transportista'
      },
      generateLabel: {
        doIt: 'Generar etiqueta de devolución',
        failure: 'No se ha podido generar la etiqueta de devolución:',
        title: 'Información de devolución'
      }
    },
    returnPackage: {
      fields: {
        buyerEmail: 'Correo electrónico del cliente',
        height: 'Altura (mm)',
        length: 'Longitud (mm)',
        totalWeight: 'Peso (gr)',
        width: 'Anchura (mm)'
      }
    },
    save: {
      errors: 'Fallo al guardar los datos',
      success: 'Éxito de la operación'
    },
    search: {
      additionalSearch: {
        required: 'Debe introducir un valor'
      },
      creator: 'Condición de búsqueda para {{field}}',
      modifiers: {
        blank: 'está vacía:',
        contains: 'contiene',
        // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
        ends_with: 'termina con',
        // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
        eq: '&#61;',
        gt: '&gt;',
        gteq: '&ge;',
        lt: '&lt;',
        lteq: '&le;',
        neq: '!=',
        nil: 'indefinido:',
        // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
        starts_with: 'comienza con',
        // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
        true: 'Verdad:'
      },
      values: {
        false: 'no',
        true: 'sí'
      }
    },
    serviceCredential: {
      check: {
        failure: 'Parece que hay un problema con sus credenciales. Por favor, compruébalas.',
        success: 'Parece que tus credenciales están correctamente configuradas.',
        tryAgain: 'Se ha producido un error al intentar comprobar las credenciales. Por favor, inténtelo de nuevo.'
      },
      checkCredentials: 'Comprobar el acceso al servicio externo',
      config: {
        edit: 'Editar',
        failure: 'Error al guardar. Por favor, compruebe primero sus credenciales.',
        header: 'Sigue nuestro <a href="/tutoriales/{{service}}.pdf" target="_blank">tutorial</a> para saber cómo encontrar sus credenciales.',
        save: 'Guardar',
        success: 'Éxito actualizando su acceso.',
        warnings: {
          ebay: {
            siteid: 'Siteid representa el país de su cuenta eBay, por defecto es ebay.fr (71).',
            token: 'Asegúrese de hacer clic en el botón para renovar su token y poder autorizar a la aplicación a acceder a sus datos.'
          },
          fnac: {
            partnerCreds: 'Tiene que pedir a su gestor de cuenta que le dé las credenciales API vinculadas a Facilecomm para que funcione.'
          },
          prestashop: {
            mappings: 'Hemos configurado un mapeo por defecto para los estados de Prestashop. Si necesita un mapeo personalizado, póngase en contacto con nosotros.'
          },
          priceminister: {
            shipped: 'Por ahora no hay estado de envío para los pedidos en Priceminister. Por lo tanto, todos los pedidos llegarán en estado "a preparar". Asegúrese de filtrar los pedidos que ya haya enviado.'
          },
          wizishop: {
            states: 'Sólo recuperamos pedidos de un único estado de Wizishop. Hay una configuración por defecto para este estado, si necesita cambiarlo, por favor contáctenos. Si cambia los estados en Wizishop, estos cambios no repercutirán en los estados de los pedidos de Shippingbo. Por lo tanto, asegúrese de filtrar los pedidos que ya ha enviado, o los que cancela en Wizishop directamente.'
          }
        }
      },
      creator: 'Añade un enlace a un servicio externo.',
      doesNotImplementCredentialsChecking: 'El acceso a este servicio no puede comprobarse automáticamente.',
      explanation: 'Añade una conexión entre Shippingbo y un mercado o su sitio web.',
      fields: {
        details: '',
        displayType: 'servicio',
        id: 'id',
        name: 'nombre',
        orderAccepter: 'Aceptar pedidos',
        orderDeliverer: 'Enviar pedidos',
        orderFetcher: 'Obtener pedidos',
        orderShipper: 'Enviar pedido',
        productFetcher: 'Obtener productos',
        stockDeliverer: 'Enviar stock',
        stockDelivererShown: 'Enviar stock',
        stockFetcher: 'Obtener stock',
        type: 'servicio'
      },
      relayRef: {
        warning: 'Las opciones para configurar los puntos de recogida están limitadas a los administradores, por favor contacte con el soporte para hacerlo.'
      }
    },
    serviceCredentials: {
      details: {
        config: 'Credenciales API',
        exchangeToken: 'Renovar token',
        orderAccepter: {
          explanation: 'Activar / desactivar la aceptación automática de pedidos.',
          title: 'Aceptación de pedidos'
        },
        orderFetcher: {
          explanation: 'Activar / desactivar la recuperación automática de pedidos. También debe establecer la fecha de inicio de la recuperación de pedidos',
          title: 'Importación de pedidos'
        },
        orderUpdater: {
          explanation: 'Activar / desactivar la repercusión automática de los cambios de estado del pedido en el servicio externo',
          title: 'Actualización del estado de los pedidos'
        },
        productFetcher: {
          explanation: 'Activar / desactivar la recuperación automática de productos',
          title: 'Recuperación de los productos'
        },
        stockDeliverer: {
          explanation: 'Activar / desactivar la actualización de stock en el servicio externo',
          stockEntriesUrl: 'No podemos enviar la información sobre los stock a este servicio. En vez de enviarlos, ellos obtienen regularmente esta información de nosotros. Para ello, debe dar esta url a su gestor de cuenta allí: {{url}}',
          title: 'Actualización de stock'
        }
      }
    },
    shipment: {
      fields: {
        carrierName: 'Nombre del transportista',
        packageId: 'Id de paquete',
        shippingRef: 'Número de seguimiento'
      },
      name: 'Envío'
    },
    stockVariation: {
      creator: 'Añadir o eliminar stock de este producto',
      explanation: 'Puede añadir stock con un número positivo o eliminarlos con un número negativo.',
      fields: {
        variation: 'Valor de la variación de stock (+/-)'
      },
      productKey: 'Campo de referencia para el producto'
    },
    subscription: {
      cancel: 'Cancelar suscripción',
      destroy: {
        failed: 'Algo ha ido mal al intentar cancelar tu suscripción.',
        performed: 'Su suscripción se ha cancelado correctamente.',
        scheduledFor: 'Su suscripción finalizará el'
      },
      destroyConsequences: 'Esta acción cancelará su suscripción.',
      fields: {
        state: 'Estado',
        terminationDate: 'Fin'
      },
      states: {
        active: 'Activo',
        canceled: 'Cancelado',
        failed: 'Pago inicial fallido',
        finishing: 'Anulación solicitada',
        inactive: 'Inactivo',
        pastDue: 'Pago retrasado'
      }
    },
    symbol: {
      close: '×'
    },
    table: {
      currentOrdering: 'Ordenado por',
      page: 'Página:',
      search: 'Buscar en',
      sortOrder: {
        asc: 'creciente.',
        desc: 'decreciente.'
      },
      totalRecords: 'Total: {{total}}'
    },
    tracking: {
      collapsibleStatusTitle: 'Seguimiento del paquete {{id}}',
      comment: 'Comentario del transportista',
      parcelActualWeight: 'Peso real del paquete (g)',
      parcelRef: 'Referencia del paquete',
      parcelStatus: 'Estado',
      status: {
        atCustoms: 'En aduana',
        atPickUpPoint: 'En el punto de recogida',
        beingProcessed: 'Tratamiento en curso',
        delayed: 'Retrasado',
        delivered: 'Entregado',
        deliveryFailed: 'Entrega fallida',
        deliveryInProgress: 'Entrega en curso',
        deliveryScheduled: 'Entrega prevista',
        failedPickUp: 'Recogida fallida',
        handedOver: 'Entregado al transportista',
        inTrouble: 'En error',
        pickedUp: 'Recogido',
        receiverActionRequired: 'Acción requerida de parte del destinatario',
        receiverInformed: 'Destinatario informado',
        receiverNotificationFailed: 'Notificación recibida fallida',
        receiverNotified: 'Recibido notificado',
        rejected: 'Rechazado',
        requestedReceived: 'Solicitud recibida',
        returnInProgress: 'Devolución en curso',
        returned: 'Devuelto',
        scannedIn: 'Escaneado en',
        senderActionRequired: 'Acción requerida del remitente',
        stopped: 'Detenido',
        toBePickedUpAtCarrierStore: 'Para recoger en la tienda del transportista',
        unknown: 'Desconocido',
        unloaded: 'Descargado'
      },
      statusChangedAt: 'Último cambio'
    },
    upload: {
      cdiscount: 'Pago Cdiscount',
      completeOipms: 'producto: referencias adicionales',
      confirm: 'No cierre esta pestaña antes de que finalice la importación o se completará parcialmente.',
      delimiter: 'Separador de valores',
      dpdShipment: 'Seguimiento de DPD',
      errors: 'Errores',
      exampleFile: 'Archivo de ejemplo',
      explanation: 'Puede importar objetos aquí desde un CSV. Debe incluir una linea de encabezado que especifique las columnas.',
      file: 'Archivo a importar',
      fnac: 'Pago Fnac',
      glsShipment: 'Seguimiento GLS',
      inProgress: 'En curso',
      modelName: 'Tipo de archivo',
      orderItemProductMapping: 'Asignación de línea de pedido / producto',
      packComponent: 'Pack',
      packingInfo: 'Información sobre el embalaje',
      payment: 'Pago',
      processRate: 'Importado: {{rate}}',
      product: 'Producto',
      ref: 'Referencia',
      shipment: 'Información de seguimiento',
      stockVariation: 'Variaciones de stock',
      success: 'Éxito',
      successRate: 'Éxito: {{rate}}',
      title: {
        completeOipms: 'producto: referencias adicionales',
        orderItemProductMapping: 'Asignación de línea de pedido / producto',
        packComponent: 'Pack',
        packingInfo: 'Información sobre el embalaje',
        product: 'producto',
        shipment: 'Información de seguimiento',
        stockVariation: 'Variaciones de stock',
        xlsUpload: {
          cdiscount: 'Pagos Cdiscount',
          fnac: 'Pagos FNAC'
        }
      },
      update: 'Actualización',
      updateWarning: 'La primera columna debe ser la clave primaria utilizada para la actualización. Las claves primarias permitidas son: {{keys}}.'
    },
    validator: {
      errors: {
        characterSet: '{{field}} sólo puede contener los siguientes caracteres: {{displayChars}}',
        delegateHasManyValidity: '{{field}}s tienen errores',
        delegateValidity: '{{field}} tiene errores',
        different: '{{value}} no es un valor permitido para el campo {{field}}',
        equal: '{{field}} debe ser igual a {{value}}',
        greaterOrEq: '{{field}} debe ser mayor que (o igual a) {{value}}',
        greaterThanEq: '{{field}} debe ser mayor que (o igual a) {{value}}',
        included: '{{field}} debe tomar un valor dentro de: {{displayableList}}',
        isAnInt: '{{field}} debe ser un valor entero',
        lengthEq: 'la longitud de {{field}} debe ser igual a {{value}}',
        lessThanEq: '{{field}} debe ser menor que (o igual a) {{value}}',
        longerThanEq: 'la longitud de {{field}} debe ser mayor que (o igual a) {{value}}',
        matchesRegExp: '{{field}} no tiene el formato esperado.',
        required: '{{field}} es obligatorio',
        shorterThanEq: 'la longitud de {{field}} debe ser menor que (o igual a) {{value}}',
        startsWith: '{{field}} debe empezar por {{interpolate}}',
        stringOfAlphas: 'El {{field}} sólo puede contener letras alfabéticas',
        stringOfDigits: 'El {{field}} sólo puede contener dígitos'
      },
      specialErrors: {
        configLogistic: {
          defaultPackageDescription: 'El campo: "Descripción utilizada para enviar el paquete en Europa" en logística debe ser completado'
        },
        courrierSuivi: {
          notAFrenchZip: 'El código postal introducido ({{zip}}) no es un código postal francés válido. Por favor, compruébelo.'
        }
      }
    },
    wallet: {
      associatedBankAccount: 'Cuenta bancaria asociada',
      balance: 'Saldo',
      potentialBalance: 'Balance virtual',
      status: 'Estado',
      title: 'Monedero del mercado Shippingbo',
      waitingForDocs: 'A la espera de documentos, o de que los documentos sean validados.',
      waitingForRegistration: 'A la espera del registro de la cartera.'
    },
    walletMoneyIn: {
      cancel: 'Pago cancelado',
      creator: 'Añadir dinero a la cartera',
      error: 'Pago fallido',
      explanation: 'Por favor, seleccione el método de pago y la cantidad a abonar en su monedero.',
      fields: {
        amountCents: 'Importe (céntimos)',
        amountCurrency: 'Moneda',
        amountInUnits: 'Importe',
        type: 'Forma de pago'
      },
      id: 'Referencia de pago de Shippingbo',
      initialized: 'Inicializado',
      pending: 'Pendiente',
      success: 'Pago realizado con éxito',
      types: {
        creditCard: 'Tarjeta de crédito'
      },
      unknownState: 'Estado de pago desconocido'
    },
    walletMoneyOut: {
      buttonKey: 'Quitar',
      creator: 'Quitar dinero del monedero',
      explanation: 'El dinero se quitará del monedero Shippingbo y se transferirá a su cuenta bancaria asociada.',
      fields: {
        amountCents: 'Importe (céntimos)',
        amountCurrency: 'Moneda',
        amountInUnits: 'Importe'
      }
    },
    weightToPriceMapping: {
      maxWeight: 'Peso máximo (g)',
      minWeight: 'Peso mínimo (g)',
      priceCents: 'Precio céntimos (EUR)'
    }
  };
  _exports.default = _default;
});