define("label_provider_app/templates/address-label-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mvIU9YJz",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"panel-body\",null,null,{\"statements\":[[4,\"panel-heading\",null,[[\"title\"],[[27,\"t\",[\"addressLabelSummary.heading\"],[[\"id\"],[[23,[\"model\",\"id\"]]]]]]],{\"statements\":[],\"parameters\":[]},null],[4,\"panel-bordered\",null,null,{\"statements\":[[4,\"panel-heading\",null,[[\"title\"],[[27,\"t\",[\"addressLabelSummary.details\"],null]]],{\"statements\":[],\"parameters\":[]},null],[4,\"panel-body-inner\",null,[[\"jsClass\"],[\"js-address-label-summary-details\"]],{\"statements\":[[4,\"panel-row\",null,null,{\"statements\":[[4,\"each\",[[23,[\"model\",\"detailsFields\"]]],null,{\"statements\":[[4,\"label-and-thing\",null,[[\"label\",\"jsClass\"],[[27,\"t\",[[27,\"concat\",[\"addressLabelSummary.fields.\",[22,1,[\"name\"]]],null]],null],[27,\"concat\",[\"js-summary-full-display-\",[22,1,[\"name\"]]],null]]],{\"statements\":[[0,\"            \"],[1,[27,\"property-editor\",null,[[\"model\",\"field\",\"type\",\"isEditable\",\"jsClass\"],[[23,[\"model\"]],[22,1,[\"name\"]],[22,1,[\"type\"]],false,[27,\"concat\",[\"js-summary-display-\",[22,1,[\"name\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/address-label-summary.hbs"
    }
  });
  _exports.default = _default;
});