define("label_provider_app/components/label-offer-option-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var receiverCountryDefault = 'UNRESTRICTED';
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    modelFields: function () {
      if (this.get('availableProvidedLabelOfferOptions')) {
        return [{
          name: 'providedLabelOfferOptionId',
          type: 'select',
          options: this.get('availableProvidedLabelOfferOptions')
        }];
      }
      var fields = [{
        name: 'service',
        type: 'select',
        options: this.get('serviceOptions')
      }, {
        name: 'receiverCountry',
        type: 'string'
      }];
      if (this.get('labelOffer.carrierSupportsInsurance')) {
        fields.push({
          name: 'insuranceEnabled',
          type: 'boolean'
        });
      }
      if (this.get('labelOffer.carrierSupportsOfferOnZipCodes')) {
        fields.push({
          name: 'onlyUseIfZipCodeAvailable',
          type: 'boolean'
        });
      }
      return fields;
    }.property('availableProvidedLabelOfferOptions', 'serviceOptions', 'labelOffer.carrierSupportsInsurance', 'labelOffer.carrierSupportsOfferOnZipCodes'),
    availableProvidedLabelOfferOptions: function () {
      if (!this.get('labelOffer.providedLabelOfferId')) {
        return;
      }
      return this.get('labelOffer.availableProvidedLabelOfferOptions').map(function (option) {
        return {
          value: option.id,
          label: option.name
        };
      });
    }.property('labelOffer.providedLabelOfferId', 'labelOffer.availableProvidedLabelOfferOptions'),
    serviceOptions: function () {
      var services = this.get('labelOffer.availableServices');
      if (!services) {
        return [];
      }
      return this.get('labelOffer.availableServices').map(function (service) {
        return {
          value: service,
          label: service
        };
      });
    }.property('labelOffer.availableServices'),
    initialData: function () {
      var initialData = {
        labelOffer: this.get('labelOffer'),
        receiverCountry: receiverCountryDefault
      };
      return initialData;
    }.property('labelOffer.id')
  });
  _exports.default = _default;
});