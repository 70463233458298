define("label_provider_app/templates/components/provider-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "owNAWkuz",
    "block": "{\"symbols\":[\"section\"],\"statements\":[[4,\"dropdown-menu\",null,[[\"tagName\",\"jsClass\"],[\"li\",\"cuke-user-drop-down\"]],{\"statements\":[[4,\"if\",[[27,\"is-equal\",[[22,1,[]],\"title\"],null]],null,{\"statements\":[[0,\"    \"],[1,[21,\"topbarDisplayName\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"currentUser\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"cuke-go-to-profile\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"labelProvider\",[23,[\"currentUser\",\"labelProviderId\"]]],null,{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"js-go-to-profile icon wb-user\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"configs.labelProvider\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"li\"],[11,\"class\",\"divider\"],[11,\"role\",\"presentation\"],[9],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"data-method\",\"delete\"],[12,\"href\",[21,\"url\"]],[9],[0,\"\\n        \"],[7,\"i\"],[11,\"class\",\"icon wb-power\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\" \"],[1,[27,\"t\",[\"main.logout\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/provider-dropdown.hbs"
    }
  });
  _exports.default = _default;
});