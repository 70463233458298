define("label_provider_app/adapters/timeout-unauthorized-error", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.AdapterError.extend({
    message: 'Your session expired.'
  });
  _exports.default = _default;
});