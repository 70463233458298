define("label_provider_app/templates/components/dangerous-modal-popin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MXCXikpA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"modal-popin\",null,[[\"title\",\"createAction\",\"cancelAction\",\"renderedAction\",\"createBtnIcon\",\"cancelText\",\"cancelJsClass\",\"createJsClass\",\"performBtn\",\"createButtonText\",\"modalType\",\"createActionDisabled\",\"modalWrapperType\",\"forcedWidth\"],[[27,\"t\",[\"main.areYouSure\"],null],[27,\"action\",[[22,0,[]],\"performDangerousAction\"],null],[27,\"action\",[[22,0,[]],\"performCancel\"],null],[23,[\"renderedAction\"]],[23,[\"destroyIcon\"]],[23,[\"fullCancelDangerousActionText\"]],\"js-cancel-dangerous-action-button\",\"js-do-dangerous-action-button\",\"delete\",[23,[\"fullDestroyButtonText\"]],\"warning\",[23,[\"destroyActionDisabled\"]],\"dangerous\",[23,[\"forcedWidth\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"consequencesKey\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[9],[1,[27,\"t\",[[23,[\"consequencesKey\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/dangerous-modal-popin.hbs"
    }
  });
  _exports.default = _default;
});