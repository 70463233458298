define("label_provider_app/components/classic-modal-popin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    title: null,
    createActionDisabled: null,
    cancelText: null,
    createButtonText: null,
    createAction: null,
    cancelAction: null,
    renderedAction: null,
    forcedWidth: null,
    actions: {
      performAction: function performAction(cb) {
        this.get('createAction')(cb);
      },
      performCancel: function performCancel(cb) {
        if (cb) {
          cb();
        }
        this.get('cancelAction')();
      }
    }
  });
  _exports.default = _default;
});