define("label_provider_app/controllers/provided-client", ["exports", "label_provider_app/controllers/common-controller"], function (_exports, _commonController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _commonController.default.extend({
    currentUser: Ember.inject.service(),
    showImpersonator: function () {
      return this.get('currentUser.clientImpersonatingEnabled');
    }.property('currentUser.clientImpersonatingEnabled')
  });
  _exports.default = _default;
});