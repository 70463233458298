define("label_provider_app/components/dangerous-modal-popin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    title: null,
    destroyActionDisabled: null,
    destroyButtonText: null,
    cancelDangerousActionText: null,
    destroyAction: null,
    cancelDangerousAction: null,
    renderedAction: null,
    forcedWidth: null,
    fullDestroyButtonText: function () {
      // if (this.get('destroyButtonText')) {
      //   return this.get('destroyButtonText');
      // }
      return this.get('i18n').t('main.doDangerousAction');
    }.property('i18n', 'destroyButtonText'),
    fullCancelDangerousActionText: function () {
      // if (this.get('cancelDangerousActionText')) {
      //   return this.get('cancelDangerousActionText');
      // }
      return this.get('i18n').t('main.cancelDangerousAction');
    }.property('i18n', 'cancelDangerousActionText'),
    actions: {
      performDangerousAction: function performDangerousAction(cb) {
        if (this.get('dangerousAction')) {
          this.get('dangerousAction')(cb);
        }
      },
      performCancel: function performCancel(cb) {
        if (cb) {
          cb();
        }
        if (this.get('cancelDangerousAction')) {
          this.get('cancelDangerousAction')();
        }
      }
    }
  });
  _exports.default = _default;
});