define("label_provider_app/templates/components/downloadable-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GDFd1MLX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"thing-of-label\",null,null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[28,[[21,\"filePath\"]]]],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"\\n    \"],[1,[21,\"filePath\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/downloadable-file.hbs"
    }
  });
  _exports.default = _default;
});