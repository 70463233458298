define("label_provider_app/models/label-offer-option", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var editCondition = function editCondition(model) {
    return !model.get('labelOffer.providedLabelOfferId');
  };
  var optionConfigFields = [{
    name: 'service',
    isEditable: true,
    editCondition: editCondition
  }, {
    name: 'receiverCountry',
    isEditable: true,
    editCondition: editCondition
  }, {
    name: 'zipPrefix',
    isEditable: true,
    editCondition: editCondition
  }, {
    name: 'insuranceEnabled',
    type: 'boolean',
    isEditable: true,
    editCondition: editCondition
  }, {
    name: 'onlyUseIfZipCodeAvailable',
    type: 'boolean',
    isEditable: true,
    editCondition: editCondition
  }, {
    name: 'providedLabelOfferOptionId',
    isEditable: false
  }];
  var _default = _emberData.default.Model.extend({
    service: _emberData.default.attr('string'),
    labelOffer: _emberData.default.belongsTo('labelOffer'),
    labelPricingTable: _emberData.default.belongsTo('labelPricingTable'),
    labelPricingTableId: _emberData.default.attr('number'),
    locked: _emberData.default.attr('boolean'),
    archived: _emberData.default.attr('boolean'),
    receiverCountry: _emberData.default.attr('string'),
    zipPrefix: _emberData.default.attr('string'),
    insuranceEnabled: _emberData.default.attr('boolean'),
    onlyUseIfZipCodeAvailable: _emberData.default.attr('boolean'),
    configFields: optionConfigFields,
    providedLabelOfferOptionId: _emberData.default.attr('number')
  });
  _exports.default = _default;
});