define("label_provider_app/models/report-config", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    allowTrigger: _emberData.default.attr('boolean'),
    csv: _emberData.default.attr('boolean'),
    datable: _emberData.default.attr('boolean'),
    dateBordered: _emberData.default.attr('boolean'),
    dateBorderedIncludeAllMessage: _emberData.default.attr('string'),
    separator: _emberData.default.attr('string'),
    encoding: _emberData.default.attr('string'),
    forceQuotes: _emberData.default.attr('boolean'),
    showHeader: _emberData.default.attr('boolean'),
    defaultedOptions: _emberData.default.attr(''),
    reports: _emberData.default.hasMany('report', {
      async: true
    }),
    reportIds: _emberData.default.attr(''),
    // Defaulted options
    carrierNameEnabled: _emberData.default.attr('boolean'),
    contractEnabled: _emberData.default.attr('boolean'),
    fullSourceEnabled: _emberData.default.attr('boolean'),
    pricesEnabled: _emberData.default.attr('boolean'),
    labelOfferNameEnabled: _emberData.default.attr('boolean'),
    // End of defaulted options

    cukeClass: function () {
      var type = this.get('type');
      if (!type) {
        return '';
      }
      return type.dasherize().replace(/::/, '-');
    }.property('type')
  });
  _exports.default = _default;
});