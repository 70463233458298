define("label_provider_app/helpers/to-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.toString = toString;
  function toString(object) {
    if (object == null) {
      return object;
    }
    if (object.toString) {
      return object.toString();
    }
    return object;
  }
  var _default = Ember.Helper.helper(toString);
  _exports.default = _default;
});