define("label_provider_app/controllers/label-provider", ["exports", "label_provider_app/controllers/common-controller"], function (_exports, _commonController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var toField = function toField(fieldName) {
    return {
      name: fieldName,
      isEditable: fieldName !== 'email'
    };
  };
  var necessaryFields = ['email', 'companyName', 'firstname', 'lastname'];
  var _default = _commonController.default.extend({
    model: null,
    necessaryFields: necessaryFields.map(function (fieldName) {
      return toField(fieldName);
    })
  });
  _exports.default = _default;
});