define("label_provider_app/components/check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import $ from 'jquery';

  var insStyle = ['position: absolute;', 'top: 0%;', 'left: 0%;', 'display: block;', 'width: 100%;', 'height: 100%;', 'margin: 0px;', 'padding: 0px;', 'border: 0px;', 'opacity: 0;', 'background: rgb(255, 255, 255);'].join('; ');
  var _default = Ember.Component.extend({
    text: null,
    checked: null,
    disabled: false,
    hidden: false,
    initialHidingDone: false,
    insStyle: insStyle,
    // didRender() {
    //   if (this.get('initialHidingDone')) {
    //     return;
    //   }
    //   if (this.get('jsSelector') && this.get('hidden')) {
    //     this.set('initialHidingDone', true);
    //     this.hide();
    //   }
    // },

    // restartAutoReload: Ember.observer('autoReloadActivated', function() {
    //   if (this.get('autoReloadActivated')) {
    //     this.waitForPsGeneration();
    //   }
    // }),

    // hide() {
    //   $(this.get('jsSelector')).hide();
    //   console.log('Check box hidden.');
    // },

    // jsSelector: function() {
    //   console.log('In jsSelector');
    //   if (!this.get('jsClass')) {
    //     return;
    //   }
    //   return '.' + this.get('jsClass');
    // }.property('jsClass'),

    currentClass: function () {
      if (this.get('checked')) {
        return 'checked';
      }
      return 'not-checked';
    }.property('checked'),
    click: function click() {
      // if (this.get('disabled')) {
      //   return;
      // }
      var changeAction = this.get('action');
      this.set('checked', !this.get('checked'));
      if (changeAction) {
        changeAction(this.get('checked'));
      } else {
        console.log('Looks like no changeAction has been injected into check box.');
      }
    }
  });
  _exports.default = _default;
});