define("label_provider_app/templates/components/label-config-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ay6H997y",
    "block": "{\"symbols\":[\"tpl\"],\"statements\":[[4,\"panel-bordered\",null,[[\"jsClass\"],[\"js-label-config-templates\"]],{\"statements\":[[4,\"panel-heading\",null,[[\"title\"],[[27,\"t\",[\"labelConfigTemplates.title\"],null]]],{\"statements\":[[4,\"panel-actions\",null,null,{\"statements\":[[0,\"      \"],[1,[27,\"object-creator\",null,[[\"table\",\"modelFields\",\"initialData\",\"jsClass\"],[\"labelConfigTemplate\",[23,[\"modelFields\"]],[23,[\"initialData\"]],\"js-create-config-template\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"panel-body-inner\",null,null,{\"statements\":[[4,\"each\",[[23,[\"model\",\"labelConfigTemplates\"]]],null,{\"statements\":[[4,\"if\",[[22,1,[\"id\"]]],null,{\"statements\":[[4,\"label-and-thing\",null,[[\"label\",\"jsClass\"],[[22,1,[\"name\"]],[27,\"concat\",[\"js-label-config-template-\",[22,1,[\"id\"]]],null]]],{\"statements\":[[4,\"thing-of-label\",null,null,{\"statements\":[[4,\"link-to\",[\"labelConfigTemplate\",[22,1,[]]],null,{\"statements\":[[0,\"              \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/label-config-templates.hbs"
    }
  });
  _exports.default = _default;
});