define("label_provider_app/templates/components/check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AREoGjBu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"checkbox-control js-check-box-control \",[21,\"jsClass\"]]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"icheckbox_flat-blue \",[21,\"currentClass\"],\" js-checkbox-toggle\"]]],[11,\"style\",\"position: relative;\"],[9],[0,\"\\n    \"],[7,\"input\"],[11,\"class\",\"icheckbox-primary\"],[11,\"id\",\"inputUnchecked\"],[11,\"name\",\"inputiCheckCheckboxes\"],[11,\"data-plugin\",\"iCheck\"],[11,\"data-checkbox-class\",\"icheckbox_flat-blue\"],[11,\"style\",\"position: absolute; opacity: 0;\"],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n    \"],[7,\"ins\"],[11,\"class\",\"iCheck-helper\"],[12,\"style\",[21,\"insStyle\"]],[9],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"text\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[11,\"for\",\"inputUnchecked\"],[11,\"class\",\"\"],[9],[1,[21,\"text\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/check-box.hbs"
    }
  });
  _exports.default = _default;
});