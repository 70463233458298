define("label_provider_app/controllers/application", ["exports", "label_provider_app/controllers/common-controller"], function (_exports, _commonController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _commonController.default.extend({
    currentUser: Ember.inject.service(),
    copyRightYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    /*--------------------------
          Settings dropdown
    ---------------------------*/

    isSideBarOpen: true,
    isSideBarOpenMobile: true,
    actions: {
      toggleSideBar: function toggleSideBar() {
        this.toggleProperty('isSideBarOpen');
      },
      toggleSideBarMobile: function toggleSideBarMobile() {
        this.toggleProperty('isSideBarOpenMobile');
      }
    },
    /*-----------------------------------
          Settings imagePath - Start
    -----------------------------------*/

    imagePath: '/images',
    logoFile: '/logo',
    extensionPng: '.png',
    extentionJpg: '.jpg',
    extentionGif: '.Gif',
    /*------- Logo -------*/
    iconLogoBig: '/logo-icon-big',
    logoLogin: '/logo_shipping_dark',
    dashboardLogo: '/logo_shipping_light',
    /*--------------------------------------
          Settings FontAwesome - Start
    --------------------------------------*/
    faProduct: 'fas fa-list',
    faOrder: 'fas fa-cubes',
    faUpload: 'fas fa-cloud-upload',
    faSettings: 'fas fa-cog',
    faLogout: 'fas fa-sign-out',
    faDashboard: 'fas fa-home',
    faLeftMenu: 'fas fa-outdent',
    faAngleDown: 'fas fa-angle-down',
    faDownload: 'fas fa-download'
  });
  _exports.default = _default;
});