define("label_provider_app/window-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var windowLocation = {
    // Note that this method is not being tested
    // Change with care
    //
    replace: function replace(url) {
      window.location.replace(url);
    }
  };
  var _default = windowLocation;
  _exports.default = _default;
});