define("label_provider_app/templates/components/pricing-table-mappings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "99yRr0Al",
    "block": "{\"symbols\":[\"mapping\"],\"statements\":[[4,\"panel-bordered\",null,null,{\"statements\":[[4,\"panel-heading\",null,[[\"title\"],[[27,\"t\",[\"labelPricingTable.mappings\"],null]]],{\"statements\":[[0,\"    \"],[1,[23,[\"pricingTable\",\"weightToPriceMappings\",\"length\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"panel-body-inner\",null,[[\"jsClass\"],[\"js-pricing-table-mappings\"]],{\"statements\":[[4,\"panel-row\",null,null,{\"statements\":[[0,\"      \"],[7,\"table\"],[12,\"class\",[21,\"pricingTableClasses\"]],[9],[0,\"\\n        \"],[1,[27,\"pricing-table-header\",null,[[\"fields\"],[[23,[\"fields\"]]]]],false],[0,\"\\n\"],[4,\"each\",[[23,[\"pricingTable\",\"weightToPriceMappings\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"weight-to-price-mapping-editor\",null,[[\"model\",\"fields\"],[[22,1,[]],[23,[\"fields\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/pricing-table-mappings.hbs"
    }
  });
  _exports.default = _default;
});