define("label_provider_app/tests/factories/label-pricing-table", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('labelPricingTable', {
    polymorphic: false,
    default: {
      uploadedFileId: 123,
      labelOfferOption: _emberDataFactoryGuy.default.belongsTo('labelOfferOption')
    }
  });
});