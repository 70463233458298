define("label_provider_app/templates/components/pricing-table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iF+dLHPG",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"each\",[[23,[\"fields\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"pricing-table-header-entry\",null,[[\"field\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/pricing-table-header.hbs"
    }
  });
  _exports.default = _default;
});