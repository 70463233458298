define("label_provider_app/templates/components/link-to-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sl/ehplm",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[[23,[\"detailsRoute\"]],[23,[\"model\"]]],[[\"target\",\"class\"],[\"_blank\",[27,\"concat\",[\"js-link-to-details js-link-to-details-\",[23,[\"model\",\"id\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/link-to-details.hbs"
    }
  });
  _exports.default = _default;
});