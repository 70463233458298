define("label_provider_app/tests/factories/weight-to-price-mapping", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('weightToPriceMapping', {
    polymorphic: false,
    default: {
      minWeight: 1,
      maxWeight: 50,
      priceCents: 99,
      priceCurrency: 'EUR',
      labelPricingTable: _emberDataFactoryGuy.default.belongsTo('labelPricingTable')
    },
    firstWeightToPriceMapping: {
      minWeight: 1,
      maxWeight: 50,
      priceCents: 99,
      priceCurrency: 'EUR',
      labelPricingTable: _emberDataFactoryGuy.default.belongsTo('labelPricingTable')
    },
    secondWeightToPriceMapping: {
      minWeight: 51,
      maxWeight: 199,
      priceCents: 299,
      priceCurrency: 'EUR',
      labelPricingTable: _emberDataFactoryGuy.default.belongsTo('labelPricingTable')
    },
    thirdWeightToPriceMapping: {
      minWeight: 200,
      maxWeight: 999,
      priceCents: 599,
      priceCurrency: 'EUR',
      labelPricingTable: _emberDataFactoryGuy.default.belongsTo('labelPricingTable')
    }
  });
});