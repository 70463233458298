// import Ember from 'ember';

// export default Ember.Component.extend({
//   store: Ember.inject.service(),

//   availableColumns: [],

//   isOpen: false,
//   table: null,

//   allItems: function() {
//     return this.get('store').findAll('userTableConfig');
//   }.property(),

//   items: function() {
//     return this.get('allItems')
//       .filter(item => {
//         if (item.get('table') !== this.get('table')) {
//           return false;
//         }
//         return this.get('availableColumns').any(col => {
//           return col.name === item.get('column');
//         });
//       })
//       .sortBy('position');
//   }.property(
//     'allItems.@each.table',
//     'allItems.@each.position',
//     'allItems.@each.column',
//     'table',
//     'availableColumns'
//   ),

//   actions: {
//     open: function() {
//       this.set('isOpen', true);
//     },
//     cancel: function() {
//       this.set('isOpen', false);
//     },
//   },
// });
define("label_provider_app/components/column-selector", [], function () {
  "use strict";
});