define("label_provider_app/components/search-modifier-chooser", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // See http://momentjs.com/ for docs on the possible formats
  var dateFormat = 'YYYY[-]MM[-]DD[ ]HH[:]mm';
  var _default = Ember.Component.extend({
    formatDate: Ember.inject.service(),
    i18n: Ember.inject.service(),
    possibleValueOptions: null,
    options: null,
    selectedMod: null,
    value: null,
    didRender: function didRender() {
      if (this.get('isCheckbox') && !this.get('value')) {
        this.set('value', false);
      }
    },
    // booleans: function() {
    //   return [true, false].map(boolean => {
    //     return {
    //       value: boolean,
    //       label: this.get('i18n').t('main.' + boolean),
    //     };
    //   });
    // }.property('i18n'),

    isCheckbox: function () {
      return _lodash.default.includes(['blank', 'nil'], this.get('selectedMod'));
    }.property('selectedMod'),
    actions: {
      updateDate: function updateDate(rawValue) {
        var goodValue = this.get('formatDate').format(rawValue, dateFormat);
        this.set('value', goodValue);
      },
      updateMod: function updateMod(mod) {
        this.attrs.selectedMod.update(mod);
      }
    }
  });
  _exports.default = _default;
});