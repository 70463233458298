define("label_provider_app/templates/components/token-field-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "boGLSc/l",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"tokenfield form-control \",[21,\"jsClass\"],\" js-tokenfield\"]]],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/token-field-list.hbs"
    }
  });
  _exports.default = _default;
});