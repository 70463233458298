define("label_provider_app/components/pricing-table-header-entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    field: null,
    classNames: ['style-pricing-table-entry', 'style-pricing-table-header-entry'],
    tagName: 'th',
    text: function () {
      return this.get('i18n').t('weightToPriceMapping.' + this.get('field'));
    }.property('i18n', 'field')
  });
  _exports.default = _default;
});