define("label_provider_app/controllers/report-config/exports", ["exports", "lodash", "label_provider_app/open-window", "label_provider_app/config/environment"], function (_exports, _lodash, _openWindow, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    dateBorderedSelected: 'includeAll',
    model: null,
    cukeClass: function () {
      var type = this.get('model.type');
      if (!type) {
        return '';
      }
      return 'cuke-' + type.dasherize().replace(/::/, '-') + '-page';
    }.property('model.type'),
    orderedReports: function () {
      return _lodash.default.sortBy(this.get('model').get('reports').filter(function (r) {
        return r.get('id');
      }), function (r) {
        return -r.get('id');
      });
    }.property('model.reportIds', 'model.reports.@each.id'),
    reportFields: function () {
      var result = [];
      if (this.get('model.dateBordered')) {
        result = result.concat([{
          name: 'dateBorderedSelected',
          type: 'radio',
          options: this.get('dateBorderedSelectedOptions'),
          optionSelected: this.get('dateBorderedSelected')
        }]);
        if (this.get('dateBorderedSelected') === 'bordered') {
          result = result.concat([{
            name: 'customStartDate',
            type: 'date'
          }, {
            name: 'customEndDate',
            type: 'date'
          }]);
        }
      }
      return result;
    }.property('model.dateBordered', 'dateBorderedSelected', 'dateBorderedSelectedOptions'),
    dateBorderedSelectedOptions: function () {
      return [{
        value: 'includeAll',
        label: this.get('model.dateBorderedIncludeAllMessage') || this.get('i18n').t('reportConfig.dateBordered.includeAll')
      }, {
        value: 'bordered',
        label: this.get('i18n').t('reportConfig.dateBordered.bordered')
      }];
    }.property('i18n', 'model.dateBorderedIncludeAllMessage'),
    reportInitialData: function () {
      return {
        reportConfig: this.get('model')
      };
    }.property('model'),
    actions: {
      download: function download(report) {
        _openWindow.default.open("".concat(_environment.default.api.oms, "/reports/") + report.get('id') + '/file');
      },
      refreshReport: function refreshReport(report) {
        report.reload();
      },
      onReportCreate: function onReportCreate(report, cb) {
        cb();
        this.set('dateBorderedSelected', 'includeAll');
      },
      updateFieldsOnFieldChanged: function updateFieldsOnFieldChanged(field, value, model) {
        if (field === 'dateBorderedSelected') {
          this.set('dateBorderedSelected', value);
          if (value !== 'bordered') {
            model.set('customStartDate', undefined);
            model.set('customEndDate', undefined);
          }
        }
      }
    }
  });
  _exports.default = _default;
});