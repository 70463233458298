define("label_provider_app/mixins/carrier-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import _ from 'lodash';
  var _default = Ember.Mixin.create({
    // displayName: function() {
    //   if (!this.get('baseType')) {
    //     return '';
    //   }
    //   return [this.get('baseType'), this.get('name')].compact().join(' - ');
    // }.property('baseType', 'name'),

    baseType: function () {
      if (this.get('type')) {
        return this.get('type').split('::').get('lastObject').capitalize();
      }
      return;
    }.property('type')

    // logo: function() {
    //   return (
    //     '/images/transporteurs/' + this.get('baseType').underscore() + '.png'
    //   );
    // }.property('baseType'),

    // kebabType: function() {
    //   if (!this.get('baseType')) {
    //     return;
    //   }
    //   return _.kebabCase(this.get('baseType'));
    // }.property('baseType'),
  });
  _exports.default = _default;
});