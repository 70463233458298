define("label_provider_app/models/label-pricing-table", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var errorStatuses = ['parsing failed', 'processing failed'];
  var _default = _emberData.default.Model.extend({
    labelOfferOption: _emberData.default.belongsTo('labelOfferOption'),
    parsingErrorKlass: _emberData.default.attr('string'),
    parsingErrorMessage: _emberData.default.attr('string'),
    processingErrorKlass: _emberData.default.attr('string'),
    processingErrorMessage: _emberData.default.attr('string'),
    processingStatus: _emberData.default.attr('string'),
    uploadedFileId: _emberData.default.attr('number'),
    weightToPriceMappings: _emberData.default.hasMany('weightToPriceMapping', {
      async: true
    }),
    potentiallyDestroyable: function () {
      return this.get('processed') || this.get('inError');
    }.property('processed', 'inError'),
    processed: function () {
      return this.get('processingStatus') === 'processed';
    }.property('processingStatus'),
    inError: function () {
      var _this = this;
      return errorStatuses.any(function (status) {
        return status === _this.get('processingStatus');
      });
    }.property('processingStatus')
  });
  _exports.default = _default;
});