define("label_provider_app/templates/components/label-offer-option-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fuscRMEf",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"object-creator\",null,[[\"table\",\"modelFields\",\"initialData\",\"jsClass\"],[\"labelOfferOption\",[23,[\"modelFields\"]],[23,[\"initialData\"]],\"js-create-label-offer-option\"]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/label-offer-option-creator.hbs"
    }
  });
  _exports.default = _default;
});