define("label_provider_app/controllers/address-label-summary", ["exports", "label_provider_app/controllers/common-controller"], function (_exports, _commonController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _commonController.default.extend({
    i18n: Ember.inject.service()
  });
  _exports.default = _default;
});