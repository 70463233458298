define("label_provider_app/templates/components/wallet-money-out-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vkr/PmJc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"object-creator\",null,[[\"table\",\"modelFields\",\"initialData\",\"jsClass\",\"action\",\"btnType\",\"addButtonKey\"],[\"walletMoneyOut\",[23,[\"modelFields\"]],[23,[\"initialData\"]],\"js-create-wallet-money-out\",[27,\"action\",[[22,0,[]],\"afterCreate\"],null],\"download\",\"walletMoneyOut.buttonKey\"]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/wallet-money-out-creator.hbs"
    }
  });
  _exports.default = _default;
});