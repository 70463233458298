define("label_provider_app/controllers/report-configs", ["exports", "lodash", "label_provider_app/controllers/common-controller"], function (_exports, _lodash, _commonController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _commonController.default.extend({
    groupedReports: function () {
      var res = {};
      this.get('model').forEach(function (e) {
        var type = e.get('type');
        res[type] = res[type] || [];
        res[type].push(e);
      });
      return _lodash.default.values(res);
    }.property('model.@each.type')
  });
  _exports.default = _default;
});