define("label_provider_app/templates/components/additional-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vrPeFuFZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"classic-modal-popin\",null,[[\"title\",\"createAction\",\"cancelAction\"],[[23,[\"title\"]],[27,\"action\",[[22,0,[]],\"addAction\"],null],[27,\"action\",[[22,0,[]],\"close\"],null]]],{\"statements\":[[4,\"if\",[[23,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"message-callout\",null,[[\"messageType\",\"messages\",\"jsClass\"],[\"danger\",[27,\"t\",[\"search.additionalSearch.required\"],null],\"js-additional-search-error\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[27,\"search-modifier-chooser\",null,[[\"options\",\"possibleValueOptions\",\"selectedMod\",\"value\",\"type\",\"field\"],[[23,[\"options\"]],[23,[\"possibleValueOptions\"]],[27,\"mut\",[[23,[\"selectedMod\"]]],null],[27,\"mut\",[[23,[\"value\"]]],null],[23,[\"type\"]],[23,[\"field\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/additional-search.hbs"
    }
  });
  _exports.default = _default;
});