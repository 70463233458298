define("label_provider_app/app", ["exports", "label_provider_app/resolver", "ember-load-initializers", "label_provider_app/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  Ember.$.ajaxSetup({
    beforeSend: function beforeSend(xhr, options) {
      options.url = _environment.default.api.oms + options.url;
    },
    xhrFields: {
      withCredentials: true
    },
    crossDomain: true,
    headers: {
      'X-ActiveModelSerializers': '1'
    }
  });
  Ember.deprecate = function () {};
  var _default = App;
  _exports.default = _default;
});