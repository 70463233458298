define("label_provider_app/tests/factories/label-credentials", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  var chronoServices = ['Chrono Relais', 'Chrono 18', 'Chrono 10', 'Chrono 13', 'Chrono Classic International', 'Chrono Express International', 'Chrono Premium International', 'Chrono Relais Europe'];
  var chronoAvailableProvidedLabelOffers = [{
    id: '456',
    name: 'offerChrono1'
  }, {
    id: '789',
    name: 'offerChrono2'
  }];
  _emberDataFactoryGuy.default.define('labelCredential', {
    polymorphic: false,
    default: {
      type: 'LabelCredential::GlsByApi',
      name: 'factory credential',
      config: {
        ouaf: 'wouf',
        tra: 'lala'
      }
    },
    chronoCredential: {
      type: 'LabelCredential::Chronopost',
      name: 'chronopost credential',
      config: {
        account_number: '1234',
        password: null
      },
      labelOfferAvailableServices: chronoServices
    },
    providedChronoCredential: {
      type: 'LabelCredential::Chronopost',
      name: 'provided chronopost credential',
      providedLabelCredentialId: '123456',
      providedLabelCredentialName: 'credential-providing-you',
      config: {
        account_number: '1234',
        password: null
      },
      availableProvidedLabelOffers: chronoAvailableProvidedLabelOffers,
      labelOfferAvailableServices: chronoServices
    },
    laposteCredential: {
      type: 'LabelCredential::Laposte',
      name: 'laposte credential',
      config: {
        ouaf: 'wouf',
        tra: 'lala'
      }
    },
    courrierSuiviCredential: {
      type: 'LabelCredential::CourrierSuivi',
      name: 'courrier suivi credential',
      config: {
        ouaf: 'wouf',
        tra: 'lala'
      }
    },
    mondialRelayCredential: {
      type: 'LabelCredential::MondialRelay',
      name: 'mondial relay credential',
      config: {
        ouaf: 'wouf',
        tra: 'lala'
      }
    },
    colisPriveCredential: {
      type: 'LabelCredential::ColisPrive',
      name: 'colis prive credential',
      config: {
        ouaf: 'wouf',
        tra: 'lala'
      }
    },
    traits: {
      chrono: {
        type: 'LabelCredential::Chronopost',
        name: 'chronopost credential',
        config: {
          account_number: '1234',
          password: null
        },
        labelOfferAvailableServices: chronoServices
      },
      courrierSuivi: {
        type: 'LabelCredential::CourrierSuivi',
        name: 'courrier suivi credential',
        config: {
          ouaf: 'wouf',
          tra: 'lala'
        }
      },
      gls: {
        type: 'LabelCredential::GlsByApi',
        name: 'factory credential',
        config: {
          ouaf: 'wouf',
          tra: 'lala'
        }
      },
      laposte: {
        type: 'LabelCredential::Laposte',
        name: 'laposte credential',
        config: {
          ouaf: 'wouf',
          tra: 'lala'
        }
      },
      mondialRelay: {
        type: 'LabelCredential::MondialRelay',
        name: 'mondial relay credential',
        config: {
          ouaf: 'wouf',
          tra: 'lala'
        }
      },
      colisPrive: {
        type: 'LabelCredential::ColisPrive',
        name: 'colis prive credential',
        config: {
          ouaf: 'wouf',
          tra: 'lala'
        }
      }
    }
  });
});