define("label_provider_app/controllers/label-credentials", ["exports", "label_provider_app/controllers/pagination-base", "label_provider_app/mixins/model-fields", "lodash", "label_provider_app/constants"], function (_exports, _paginationBase, _modelFields, _lodash, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var carriers = _constants.default.carriers;
  var labelCredentalTypes = carriers.map(function (carrier) {
    return {
      label: carrier,
      value: "LabelCredential::".concat(carrier)
    };
  });
  var nullTypeOption = {
    value: null,
    label: ''
  };
  var tableFields = [{
    name: 'details',
    notSearchable: true,
    notSortable: true,
    component: 'link-to-details',
    sticky: true,
    narrow: true
  }, {
    name: 'id',
    type: 'number',
    sticky: true
  }, {
    name: 'type',
    type: 'select',
    options: labelCredentalTypes,
    possibleValueOptions: [nullTypeOption].concat(labelCredentalTypes),
    isEditable: true,
    preventEditionFromTable: true,
    necessary: true,
    sticky: true
  }, {
    name: 'name',
    type: 'string',
    isEditable: true,
    preventEditionFromTable: true,
    necessary: true,
    sticky: true
  }, {
    name: 'providedLabelCredentialId',
    type: 'number',
    isEditable: true,
    sticky: false
  }, {
    name: 'providedLabelCredentialName',
    type: 'string',
    notSearchable: true,
    notSortable: true,
    isEditable: false,
    sticky: true
  }, {
    name: 'createdAt',
    type: 'date',
    isEditable: false,
    notSearchable: true,
    notSortable: true,
    sticky: true
  }, {
    name: 'ready',
    type: 'boolean',
    isEditable: false,
    notSearchable: true,
    notSortable: true,
    sticky: true
  }];
  var _default = _paginationBase.default.extend(_modelFields.default, {
    currentUser: Ember.inject.service(),
    i18n: Ember.inject.service(),
    tableFields: tableFields,
    tableFieldsReady: function () {
      return !_lodash.default.isEmpty(this.get('tableFields'));
    }.property('tableFields.[]'),
    creatorExplanation: function () {
      var key = 'creation.explanation';
      return this.get('i18n').t('labelCredential.' + key);
    }.property('i18n')
  });
  _exports.default = _default;
});