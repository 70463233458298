define("label_provider_app/templates/components/weight-to-price-mapping-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7p3Itba7",
    "block": "{\"symbols\":[\"field\"],\"statements\":[[4,\"each\",[[23,[\"fields\"]]],null,{\"statements\":[[0,\"  \"],[7,\"td\"],[11,\"class\",\"style-pricing-table-entry\"],[9],[0,\"\\n    \"],[1,[27,\"property-editor\",null,[[\"model\",\"field\",\"isEditable\"],[[23,[\"model\"]],[22,1,[]],false]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/weight-to-price-mapping-editor.hbs"
    }
  });
  _exports.default = _default;
});