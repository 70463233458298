define("label_provider_app/components/confirm-dangerous-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    // If null, it defaults to button-icon delete-type default icon
    // which is at the moment is a trash can
    // a good alternative option for a non-destructive action
    // is 'warning' for a warning sign
    destroyIcon: null,
    onCancel: null,
    onConfirm: null,
    isOpen: false,
    actions: {
      open: function open() {
        this.set('isOpen', true);
      },
      cancelDangerousAction: function cancelDangerousAction(cb) {
        if (cb) {
          cb();
        }
        this.set('isOpen', false);
        // if (this.get('onCancel')) {
        //   this.get('onCancel')();
        // }
      },

      performDangerousAction: function performDangerousAction(cb) {
        if (cb) {
          cb();
        }
        this.set('isOpen', false);
        if (this.get('onConfirm')) {
          this.get('onConfirm')();
        }
      }
    }
  });
  _exports.default = _default;
});