define("label_provider_app/components/dropdown-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['dropdown'],
    classNameBindings: ['isOpen:open'],
    title: '(default)',
    isOpen: false,
    __onMouseUp: null,
    didInsertElement: function didInsertElement() {
      var _this = this;
      var root = this.get('element');
      var link = root.getElementsByTagName('a')[0];
      var callback = function callback(e) {
        var target = e.target;
        if (target === link) {
          return;
        }
        _this.doClose();
      };
      this.get('__onMouseUp', callback);
      document.body.addEventListener('mouseup', callback);
    },
    willDestroyElement: function willDestroyElement() {
      document.body.removeEventListener('mouseup', this.get('__onMouseUp'));
    },
    _performToggle: function _performToggle() {
      if (this.isAlive()) {
        this.toggleProperty('isOpen');
      }
    },
    doClose: function doClose() {
      if (this.isAlive()) {
        this.set('isOpen', false);
      }
    },
    actions: {
      toggle: function toggle() {
        this._performToggle();
      }
    }
  });
  _exports.default = _default;
});