define("label_provider_app/templates/components/lemon-bank-account-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Dd9/F409",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"object-creator\",null,[[\"table\",\"modelFields\",\"initialData\",\"jsClass\",\"action\"],[\"lemonBankAccount\",[23,[\"modelFields\"]],[23,[\"initialData\"]],\"js-create-lemon-bank-account\",[27,\"action\",[[22,0,[]],\"onBankAccountCreation\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/lemon-bank-account-creator.hbs"
    }
  });
  _exports.default = _default;
});