define("label_provider_app/components/destroy-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    model: null,
    didRender: function didRender() {
      var _this = this;
      var model = this.get('model');
      if (!model) {
        this.set('actualObject', null);
        return;
      }
      if (model.then) {
        model.then(function (object) {
          _this.set('actualObject', object);
        });
      } else {
        this.set('actualObject', model);
      }
    },
    actions: {
      destroyObject: function destroyObject() {
        var _this2 = this;
        var actualObject = this.get('actualObject');
        if (actualObject) {
          actualObject.destroyRecord().then(function (destroyedRecord) {
            console.log("Record ".concat(destroyedRecord.id, " was destroyed."));
            var onDestroy = _this2.get('onDestroy');
            if (onDestroy) {
              onDestroy();
            }
          });
        }
      }
    }
  });
  _exports.default = _default;
});