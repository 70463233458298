define("label_provider_app/models/label-offer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var offerConfigFields = [{
    name: 'displayableType',
    isEditable: false
  }, {
    name: 'senderCountry',
    isEditable: true,
    editCondition: function editCondition(model) {
      return !model.get('providedLabelOfferId');
    }
  }, {
    name: 'providedLabelOfferName',
    isEditable: false
  }];
  var _default = _emberData.default.Model.extend({
    i18n: Ember.inject.service(),
    type: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    labelCredential: _emberData.default.belongsTo('labelCredential'),
    labelCredentialId: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('moment'),
    senderCountry: _emberData.default.attr('string'),
    labelOfferOptions: _emberData.default.hasMany('labelOfferOptions', {
      async: true
    }),
    labelConfigTemplates: _emberData.default.hasMany('labelConfigTemplates', {
      async: true
    }),
    labelPricing: _emberData.default.attr('boolean'),
    carrierSupportsInsurance: _emberData.default.attr('boolean'),
    carrierSupportsOfferOnZipCodes: _emberData.default.attr('boolean'),
    providedLabelOfferId: _emberData.default.attr('number'),
    providedLabelOfferName: _emberData.default.attr('string'),
    availableProvidedLabelOfferOptions: _emberData.default.attr(''),
    active: _emberData.default.attr('boolean'),
    offerConfigFields: function () {
      var _this = this;
      return offerConfigFields.filter(function (field) {
        return !(field.name === 'providedLabelOfferName') || _this.get('providedLabelOfferName');
      });
    }.property('providedLabelOfferName'),
    availableServices: function () {
      return this.get('labelCredential.labelOfferAvailableServices');
    }.property('labelCredential.labelOfferAvailableServices'),
    labelCredentialName: function () {
      var _this2 = this;
      var credName = null;
      this.get('labelCredential').then(function (cred) {
        credName = cred.get('name');
        _this2.set('labelCredentialName', credName);
      });
      return credName;
    }.property('labelCredential.name'),
    displayableType: function () {
      if (!this.get('type')) {
        return null;
      }
      return this.get('i18n').t("labelOffer.types.".concat(this.get('baseType').camelize()));
    }.property('i18n', 'baseType'),
    baseType: function () {
      return this.get('type').split('::')[1];
    }.property('type'),
    isProvided: function () {
      return !!this.get('labelCredential.providedLabelCredentialId');
    }.property('labelCredential.providedLabelCredentialId')
  });
  _exports.default = _default;
});