define("label_provider_app/templates/components/label-and-thing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ggzHLZC9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"form\"],[11,\"onsubmit\",\"return false\"],[12,\"class\",[28,[\"form-horizontal \",[21,\"jsClass\"],\" js-label-and-thing-form\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n    \"],[7,\"label\"],[12,\"class\",[28,[[21,\"labelColClass\"],\" control-label\"]]],[9],[1,[21,\"label\"],false],[0,\":\"],[10],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[[21,\"thingColClass\"]]]],[9],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/label-and-thing.hbs"
    }
  });
  _exports.default = _default;
});