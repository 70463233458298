define("label_provider_app/templates/components/config-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cZnYBwYy",
    "block": "{\"symbols\":[\"key\"],\"statements\":[[4,\"each\",[[23,[\"model\",\"configKeys\"]]],null,{\"statements\":[[4,\"label-and-thing\",null,[[\"jsClass\",\"label\"],[[27,\"concat\",[[23,[\"jsClass\"]],\" \",[23,[\"jsClass\"]],\"-\",[22,1,[]]],null],[22,1,[]]]],{\"statements\":[[4,\"if\",[[27,\"is-included\",[[23,[\"model\",\"publicBooleanFields\"]],[22,1,[]]],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"check-box\",null,[[\"checked\",\"action\"],[[27,\"get\",[[23,[\"model\",\"config\"]],[22,1,[]]],null],[27,\"action\",[[22,0,[]],[27,\"mut\",[[27,\"get\",[[23,[\"model\",\"config\"]],[22,1,[]]],null]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"input\",null,[[\"class\",\"value\"],[\"form-control\",[27,\"mut\",[[27,\"get\",[[23,[\"model\",\"config\"]],[22,1,[]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/config-editor.hbs"
    }
  });
  _exports.default = _default;
});