define("label_provider_app/components/additional-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    options: null,
    addAction: null,
    selectedMod: null,
    value: null,
    error: null,
    skipCb: false,
    reset: function reset() {
      this.set('selectedMod', null);
      this.set('value', null);
      this.set('error', null);
    },
    required: function required() {
      this.set('error', true);
    },
    actions: {
      addAction: function addAction(cb) {
        var mod = this.get('selectedMod');
        var value = this.get('value');
        if (value === null) {
          this.required();
        } else {
          this.get('addAction')(mod, value);
          this.reset();
          if (!this.get('skipCb') && cb) {
            console.log('Executing callack.');
            cb();
          } else {
            console.log('Done adding action. No callback to execute. Except this log.');
          }
        }
      },
      close: function close() {
        this.get('cancelAction')();
      }
    }
  });
  _exports.default = _default;
});