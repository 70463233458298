define("label_provider_app/router", ["exports", "label_provider_app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // ################ D ###################
    this.route('addressLabelSummaries');
    this.route('addressLabelSummary', {
      path: 'addressLabelSummary/:address_label_summary_id'
    });
    // ################ D ###################
    this.route('dashboard');
    // ################ L ###################
    this.route('labelCredential', {
      path: 'labelCredential/:label_credential_id'
    });
    this.route('labelCredentials');
    this.route('labelConfigTemplate', {
      path: 'labelConfigTemplate/:label_config_template_id'
    });
    this.route('labelOffer', {
      path: 'labelOffer/:label_offer_id'
    });
    this.route('labelOffers');
    this.route('labelProvider', {
      path: 'labelProvider/:label_provider_id'
    });
    // ################ P ###################
    this.route('providedClient', {
      path: 'providedClient/:provided_client_id'
    });
    this.route('providedClients');
    // ################ R ###################
    this.route('reportConfig', {
      path: 'reportConfig/:report_config_id'
    }, function () {
      this.route('config');
      this.route('exports');
    });
    this.route('reportConfigs');
    // ################ W ###################
    this.route('wallet', {
      path: 'wallet/:wallet_id'
    });
  });
  var _default = Router;
  _exports.default = _default;
});