define("label_provider_app/components/message-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    messageType: null,
    messageHeader: null,
    messages: null,
    messageArray: function () {
      if (!this.get('messages')) {
        return [];
      }
      if (typeof this.get('messages') === 'string') {
        return [this.get('messages')];
      }
      if (this.get('messages').string) {
        return [this.get('messages')];
      }
      return this.get('messages');
    }.property('messages'),
    hasMessages: function () {
      return this.get('messageArray').length > 0;
    }.property('messageArray'),
    somethingToShow: function () {
      if (!this.get('messageType')) {
        return false;
      }
      return this.get('hasMessages') || this.get('messageHeader');
    }.property('hasMessages', 'messageType', 'messageHeader')
  });
  _exports.default = _default;
});