define("label_provider_app/routes/pagination-base", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Adapted from
  // https://webcloud.info/blog/2014/11/01/building-a-real-world-pagination-with-ember-js-the-right-way/
  // but with the parameters corresponding to what we have in the backend
  var _default = Ember.Route.extend({
    init: function init(domain, extraSearchParams) {
      this._super();
      this.set('domain', domain);
      this.set('extraSearchParams', extraSearchParams || {});
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('domain', this.get('domain'));
      controller.setupSearchVariables();
      controller.set('route', this);
    },
    // Binding the query params linked to sorting and pagination
    queryParams: {
      sortOrder: {
        refreshModel: true
      },
      sortField: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var offset = 0;
      var limit = 50;
      if (params.page) {
        offset = (params.page - 1) * limit;
      }
      var fullParams = _lodash.default.assignIn({
        offset: offset,
        limit: limit
      }, this.get('extraSearchParams'));
      var sort = {
        id: 'desc'
      };
      // Filtering out non-legit sortFields gracefully
      if (params.sortField && params.sortField.toString().length > 1) {
        sort = {};
        sort[params.sortField.underscore()] = params.sortOrder;
      }
      _lodash.default.forEach(sort, function (val, key) {
        fullParams['sort[' + key + ']'] = val;
      });
      if (params.search) {
        _lodash.default.forEach(JSON.parse(params.search), function (val, key) {
          fullParams['search[' + key.underscore() + ']'] = val;
        });
      }
      return this.store.query(this.get('domain'), fullParams);
    }
  });
  _exports.default = _default;
});