define("label_provider_app/tests/factories/user-table-configs", ["ember-data-factory-guy"], function (_emberDataFactoryGuy) {
  "use strict";

  _emberDataFactoryGuy.default.define('userTableConfig', {
    default: {
      enabled: true
    },
    traits: {
      order: {
        table: 'order'
      },
      product: {
        table: 'product'
      },
      id: {
        column: 'id'
      },
      state: {
        column: 'state'
      },
      userRef: {
        column: 'userRef'
      },
      clientRef: {
        column: 'clientRef'
      },
      stock: {
        column: 'stock'
      },
      fulfilledByMarketplace: {
        column: 'fulfilledByMarketplace'
      },
      latestDeliveryAt: {
        column: 'latestDeliveryAt'
      },
      originCreatedAt: {
        column: 'originCreatedAt'
      },
      preparationRunId: {
        column: 'preparationRunId'
      }
    }
  });
});