define("label_provider_app/templates/address-label-summaries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BWXYnkSg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"cuke-address-label-summaries-page\"],[9],[0,\"\\n\"],[4,\"collection-table\",null,[[\"originRoute\",\"domain\",\"model\",\"tableFields\",\"sortOrder\",\"sortField\",\"sortAscending\",\"searchObject\",\"page\",\"transition\"],[\"addressLabelSummaries\",[23,[\"domain\"]],[23,[\"model\"]],[23,[\"tableFields\"]],[27,\"mut\",[[23,[\"sortOrder\"]]],null],[27,\"mut\",[[23,[\"sortField\"]]],null],[27,\"mut\",[[23,[\"sortAscending\"]]],null],[23,[\"searchObject\"]],[27,\"mut\",[[23,[\"page\"]]],null],[27,\"action\",[[22,0,[]],\"updateRoute\"],null]]],{\"statements\":[],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/address-label-summaries.hbs"
    }
  });
  _exports.default = _default;
});