define("label_provider_app/models/label-provider", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    clientCreating: _emberData.default.attr('boolean'),
    clientProviding: _emberData.default.attr('boolean'),
    companyName: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string')
  });
  _exports.default = _default;
});