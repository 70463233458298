define("label_provider_app/templates/components/lemon-bank-account-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0/9vvnvR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"panel-bordered\",null,null,{\"statements\":[[4,\"panel-heading\",null,[[\"title\"],[[27,\"t\",[\"wallet.associatedBankAccount\"],null]]],{\"statements\":[[4,\"panel-actions\",null,null,{\"statements\":[[4,\"unless\",[[23,[\"wallet\",\"lemonBankAccount\",\"id\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"lemon-bank-account-creator\",null,[[\"wallet\"],[[23,[\"wallet\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[23,[\"wallet\",\"lemonBankAccount\",\"id\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"lemon-bank-account-editor\",null,[[\"model\"],[[23,[\"wallet\",\"lemonBankAccount\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/lemon-bank-account-display.hbs"
    }
  });
  _exports.default = _default;
});