define("label_provider_app/components/property-editor", ["exports", "label_provider_app/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    model: null,
    field: null,
    type: null,
    isEditable: false,
    editCondition: function editCondition() {
      return true;
    },
    isEditing: false,
    options: null,
    displayComponent: null,
    jsClass: null,
    checkbox: null,
    oldVal: null,
    isLoading: false,
    saveStatus: null,
    userValue: null,
    skipSaveModel: false,
    afterUpdate: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var watchedProperty = 'model.' + this.get('field');
      Ember.defineProperty(this, 'modelFieldValue', Ember.computed('model', 'field', watchedProperty, function () {
        return _this.get(watchedProperty);
      }));
    },
    isCheckbox: Ember.computed('type', 'checkbox', function () {
      return this.checkbox || this.type === 'boolean';
    }),
    value: Ember.computed('userValue', 'model.isLoaded', 'field', 'modelFieldValue', {
      get: function get() {
        if (!this.get('model').get('isLoaded')) {
          return;
        }
        if (this.get('userValue') === null) {
          return this.get('modelFieldValue');
        }
        return this.get('userValue');
      },
      set: function set(key, val) {
        this.set('userValue', val);
        return val;
      }
    }),
    canBeEdited: function () {
      return this.get('isEditable') && (this.get('editCondition') || function () {
        return true;
      })(this.get('model'));
    }.property('model', 'editCondition', 'isEditable'),
    presentValue: function () {
      if (this.get('value')) {
        return true;
      }
      return false;
    }.property('value'),
    myClasses: function () {
      var baseClasses = ['js-property-editor', 'js-' + this.get('field') + '-editor'];
      if (this.get('jsClass')) {
        baseClasses.push(this.get('jsClass'));
      }
      return baseClasses.join(' ');
    }.property('field', 'jsClass'),
    keyUp: function keyUp(e) {
      var keyCode = this._extractKeyCode(e);
      if (keyCode === 27) {
        this.whenEscapePressed();
      }
      return true;
    },
    whenEscapePressed: function whenEscapePressed() {
      if (this.get('isEditing') && !this.get('isLoading')) {
        this.set('isEditing', false);
        this.set('userValue', null);
      }
    },
    resetField: function resetField() {
      this.set('isLoading', false);
      this.set('saveStatus', null);
    },
    showSuccess: function () {
      return this.get('saveStatus') === true;
    }.property('saveStatus'),
    showFailure: function () {
      return this.get('saveStatus') === false;
    }.property('saveStatus'),
    startLoading: function startLoading() {
      if (this.isAlive()) {
        this.set('isLoading', true);
      }
    },
    stopLoading: function stopLoading() {
      if (this.isAlive()) {
        this.set('isLoading', false);
      }
    },
    success: function success() {
      this._cleanUpOnSuccess();
      this.cleanStatusAfterTimeout();
    },
    _cleanUpOnSuccess: function _cleanUpOnSuccess() {
      if (this.isAlive()) {
        this.set('userValue', null);
        this.set('saveStatus', true);
      }
    },
    failure: function failure() {
      this._restoreOnFailure();
      this.cleanStatusAfterTimeout();
    },
    _restoreOnFailure: function _restoreOnFailure() {
      if (this.isAlive()) {
        this.set('userValue', null);
        this.set('saveStatus', false);
      }
      this.get('model').set(this.get('field'), this.get('oldVal'));
    },
    cleanStatusAfterTimeout: function cleanStatusAfterTimeout() {
      var _this2 = this;
      Ember.run.debounce(this, function () {
        _this2._resetSaveStatus();
      }, _constants.default.propertyEditor.statusTimeout);
    },
    _resetSaveStatus: function _resetSaveStatus() {
      if (this.isAlive()) {
        this.set('saveStatus', null);
      }
    },
    focusInputField: function focusInputField() {
      var inputInDOM = this._inputInDOM();
      if (inputInDOM) {
        inputInDOM.focus();
      }
    },
    _inputInDOM: function _inputInDOM() {
      return this.$('input');
    },
    updateValue: function updateValue(newVal, formatFct) {
      formatFct = formatFct || function (a) {
        return a;
      };
      this.set('isEditing', false);
      newVal = formatFct(newVal);
      this.startLoading();
      this.set('oldVal', this.get('modelFieldValue'));
      this.saveModel(newVal);
    },
    saveModel: function saveModel(newVal) {
      var _this3 = this;
      var field = this.get('field');
      var model = this.get('model');
      model.updateUnique(field, newVal).then(function () {
        _this3.stopLoading();
        _this3.success();
      }).catch(function (reason) {
        _this3.stopLoading();
        _this3.failure(reason);
      });
    },
    _extractKeyCode: function _extractKeyCode(event) {
      return event.keyCode || event.which;
    },
    actions: {
      startEditing: function startEditing() {
        // Can this really happen?
        //
        // if (this.get('isLoading') || !this.get('isEditable')) {
        //   return;
        // }
        this.resetField();
        this.set('isEditing', true);
        Ember.run.scheduleOnce('afterRender', this, this.focusInputField);
      },
      // FCV-3004
      //
      // Prevent Tab from focusing out of the input.
      // Ember does not trigger keyDown for Ember.TextField, so we must listen for
      // keyUp instead, but by the time of keyUp, the input would already be
      // focused out by pressing tab, and wouldn't receive a keyUp for the tab press.
      //
      // https://emberjs.com/api/ember/2.14.1/classes/Ember.Templates.helpers/methods/input?anchor=input
      //
      updateValue: function updateValue(newVal) {
        this.updateValue(newVal);
      },
      preventTabDefault: function preventTabDefault(_newVal, event) {
        var keyCode = this._extractKeyCode(event);
        if (keyCode === 9) {
          event.preventDefault();
          return false;
        }
        return true;
      },
      handleKeyPress: function handleKeyPress(newVal, event) {
        var keyCode = this._extractKeyCode(event);
        if (keyCode === 13 || keyCode === 9) {
          this.updateValue(newVal);
        }
        return true;
      },
      escapePressed: function escapePressed() {
        this.whenEscapePressed();
      }
    }
  });
  _exports.default = _default;
});