define("label_provider_app/components/label-config-templates", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    model: null,
    modelFields: [{
      name: 'name',
      type: 'text'
    }, {
      name: 'service',
      type: 'text'
    }],
    initialData: function () {
      return {
        labelOffer: this.get('model')
      };
    }.property('model')
  });
  _exports.default = _default;
});