define("label_provider_app/models/address-label-summary", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var detailsFields = [{
    name: 'carrierName',
    type: 'string',
    isEditable: false
  }, {
    name: 'merchantCountry',
    type: 'string',
    isEditable: false
  }, {
    name: 'country',
    type: 'string',
    isEditable: false
  }, {
    name: 'zip',
    type: 'string',
    isEditable: false
  }, {
    name: 'totalWeight',
    type: 'number',
    isEditable: false
  }, {
    name: 'shippingRef',
    type: 'string',
    isEditable: false
  }, {
    name: 'shippedAt',
    type: 'date',
    isEditable: false
  }, {
    name: 'createdAt',
    type: 'date',
    isEditable: false
  }];
  var _default = _emberData.default.Model.extend({
    i18n: Ember.inject.service(),
    detailsFields: detailsFields,
    carrierName: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('moment'),
    shippedAt: _emberData.default.attr('moment'),
    totalWeight: _emberData.default.attr('number'),
    shippingRef: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    merchantCountry: _emberData.default.attr('string')
  });
  _exports.default = _default;
});