define("label_provider_app/templates/components/search-display-for-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "21+MPOIC",
    "block": "{\"symbols\":[\"search\"],\"statements\":[[4,\"if\",[[23,[\"appliedSearches\",\"length\"]]],null,{\"statements\":[[4,\"token-field-list\",null,null,{\"statements\":[[4,\"each\",[[23,[\"appliedSearches\"]]],null,{\"statements\":[[4,\"token-field\",null,[[\"jsClass\",\"removeAction\"],[[27,\"concat\",[\"js-token-field-\",[23,[\"field\"]]],null],[27,\"action\",[[22,0,[]],\"remove\",[22,1,[\"mod\"]],[22,1,[\"value\"]]],null]]],{\"statements\":[[0,\"        \"],[1,[22,1,[\"displayMod\"]],false],[0,\" \"],[1,[22,1,[\"displayValue\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/search-display-for-field.hbs"
    }
  });
  _exports.default = _default;
});