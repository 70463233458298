define("label_provider_app/models/label-config-template", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    service: _emberData.default.attr('string'),
    labelOffer: _emberData.default.belongsTo('labelOffer'),
    config: _emberData.default.attr(''),
    publicBooleanFields: _emberData.default.attr(''),
    configKeys: function () {
      return _lodash.default.keys(this.get('config'));
    }.property('config')
  });
  _exports.default = _default;
});