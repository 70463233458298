define("label_provider_app/components/token-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNames: ['token'],
    value: null,
    removeAction: null,
    actions: {
      close: function close() {
        this.get('removeAction')();
      }
    }
  });
  _exports.default = _default;
});