define("label_provider_app/templates/components/date-time-picker-metal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Miw3efua",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"input-group datepicker-group \",[21,\"jsClass\"]]]],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"datepicker-addon-group\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"input-group date js-date-time-picker\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"input-group-addon js-calendar-toggle\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"glyphicon glyphicon-calendar\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"],[0,\"      \"],[7,\"input\"],[11,\"class\",\"js-date-time-picker-metal-input form-control hidden datepickerinput\"],[12,\"value\",[21,\"currentlySelectedDate\"]],[11,\"name\",\"js-name-date-time-picker-metal-input\"],[11,\"type\",\"text\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"date-display\",null,[[\"date\",\"inputLike\",\"jsClass\"],[[23,[\"currentlySelectedDate\"]],true,\"js-date-time-picker-metal-display\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "label_provider_app/templates/components/date-time-picker-metal.hbs"
    }
  });
  _exports.default = _default;
});