define("label_provider_app/components/link-to-details", ["exports", "label_provider_app/components/collection-table-cell"], function (_exports, _collectionTableCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _collectionTableCell.default.extend({
    model: null,
    detailsRoute: function () {
      return this.get('model').get('constructor.modelName').camelize();
    }.property('model')
  });
  _exports.default = _default;
});