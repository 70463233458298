define("label_provider_app/components/label-and-thing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    labelColClass: 'col-sm-3',
    thingColClass: 'col-md-8'
  });
  _exports.default = _default;
});