define("label_provider_app/components/button-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    tagName: 'span',
    btnPosition: null,
    // Right or left
    btnSize: 'small',
    btnType: 'default',
    disabled: null,
    btnColor: function () {
      return {
        default: 'primary',
        activate: 'info',
        add: 'success',
        cancel: 'default',
        config: 'primary',
        deactivate: 'warning',
        delete: 'danger',
        download: 'info',
        edit: 'info',
        impersonate: 'primary',
        monitor: 'default',
        prepare: 'info',
        print: 'success',
        reschedule: 'warning',
        reset: 'info',
        refresh: 'info',
        save: 'success',
        ship: 'primary',
        skip: 'warning'
      }[this.get('btnType')];
    }.property('btnType'),
    btnZizer: function () {
      return {
        large: 'lg',
        medium: 'md',
        small: 'sm'
      }[this.get('btnSize')];
    }.property('btnSize'),
    actualFaIcon: function () {
      // if (this.get('faIcon')) {
      //   return this.get('faIcon');
      // }
      return {
        default: null,
        activate: 'toggle-off',
        add: 'plus',
        cancel: 'times',
        config: 'cog',
        deactivate: 'toggle-on',
        delete: 'trash',
        download: 'download',
        edit: 'pencil',
        impersonate: 'laptop',
        prepare: 'inbox',
        print: 'print',
        refresh: 'refresh',
        reschedule: 'refresh',
        reset: 'fast-backward',
        save: 'check',
        ship: 'plane',
        skip: 'fast-forward'
      }[this.get('btnType')];
    }.property('btnType', 'faIcon'),
    actualTitle: function () {
      if (this.get('title')) {
        return this.get('title');
      }
      return this.get('i18n').t('main.' + this.get('btnType'));
    }.property('i18n', 'btnType', 'title'),
    disabledStatus: function () {
      if (!this.get('disabled')) {
        return '';
      }
      return 'disabled';
    }.property('disabled'),
    actions: {
      btnAction: function btnAction() {
        this.sendAction();
      }
    }
  });
  _exports.default = _default;
});