define("label_provider_app/components/lemon-bank-account-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var modelFields = [{
    name: 'holderFullname',
    type: 'string',
    necessary: true
  }, {
    name: 'country',
    type: 'string',
    necessary: true
  }, {
    name: 'bic',
    type: 'string',
    necessary: true
  }, {
    name: 'iban',
    type: 'string',
    necessary: true
  }, {
    name: 'bankName',
    type: 'string'
  }, {
    name: 'bankBranchCity',
    type: 'string'
  }, {
    name: 'bankBranchStreet',
    type: 'string'
  }];
  var _default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    modelFields: modelFields,
    initialData: function () {
      var initialData = {
        wallet: this.get('wallet')
      };
      return initialData;
    }.property('wallet'),
    actions: {
      onBankAccountCreation: function onBankAccountCreation(_bankAccount, cb) {
        this.get('wallet').reload();
        if (cb) {
          cb();
        }
      }
    }
  });
  _exports.default = _default;
});