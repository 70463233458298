define("label_provider_app/open-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var openWindow = {
    // Note that this method is not being tested
    // Change with care
    //
    open: function open(url) {
      window.open(url);
    }
  };
  var _default = openWindow;
  _exports.default = _default;
});