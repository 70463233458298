define("label_provider_app/models/report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    ready: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('moment'),
    errorMessage: _emberData.default.attr('string'),
    // For creation only
    customStartDate: _emberData.default.attr('moment'),
    customEndDate: _emberData.default.attr('moment'),
    requestedShippingDate: _emberData.default.attr('moment'),
    reportConfig: _emberData.default.belongsTo('report_config')
  });
  _exports.default = _default;
});